import { EVENT_LABEL } from 'features/analytics/analytics.constants';

export const EXTRACTS = {
  acai: {
    title: 'Acai',
    subTitle:
      'Loaded with antioxidants, vitamins, and minerals that nourish the skin.',
    ingredients: 'Euterpe Oleracea Fruit Extract',
  },
  algae: {
    title: 'Algae',
    subTitle: {
      'Hair Care': 'Contains hydrating properties to maintain healthy hair.',
      default:
        'A microalgae that is a good source of omega-3 fatty acids to keep the skin moisturized.',
    },
    ingredients: 'Nannochloropsis Oculata Extract',
  },
  aloeVera: {
    title: 'Aloe Vera',
    subTitle: {
      'Hair Care': 'Enriched to help strengthen and hydrate hair.',
      default:
        'Contains calming and hydrating properties to soothe reactive skin.',
    },
    ingredients: 'Aloe Barbadensis Extract',
  },
  chamomile: {
    title: 'Chamomile',
    subTitle: {
      'Hair Care': 'Provides nourishment to hair and scalp.',
      default: 'Botanical extract that soothes sensitive and reactive skin.',
    },
    ingredients: 'Chamomilla Recutita (Matricaria) Flower Extract',
  },
  coffee: {
    title: 'Coffee',
    subTitle: {
      'Body Care':
        'Rich in antioxidants to help the skin appear firmer and toned.',
      default: 'Can help stimulate the scalp to promote healthy hair.',
    },
    ingredients: 'Coffea Arabica (Coffee) Leaf/Seed Extract',
  },
  greenTea: {
    title: 'Green Tea',
    subTitle: {
      'Body Care':
        'Contains powerful antioxidants to help counter the effects of free radicals.',
      default: 'Contains powerful antioxidants that soothes the skin.',
    },
    ingredients: 'Camellia Oleifera Leaf Extract',
  },
  irishMoss: {
    title: 'Irish Moss',
    subTitle:
      'A red seaweed rich in nutrients for the skin to hydrate and protect from environmental stressors.',
    ingredients: 'Chondrus Crispus (Carrageenan) Extract',
  },
  lavender: {
    title: 'Lavender',
    subTitle: 'Soothing properties that calms common skin conditions.',
    ingredients: 'Lavandula Angustifolia (Lavender) Flower Extract',
  },
  lemonPeel: {
    title: 'Lemon Peel',
    subTitle: 'Contains natural acids to help brighten the skin.',
    ingredients: 'Citrus Limon (Lemon) Peel Extract',
  },
  moringa: {
    title: 'Moringa',
    subTitle: 'Can help to protect the hair from environmental stresses.',
    ingredients: 'Moringa Oleifera Seed Extract',
  },
  oat: {
    title: 'Oat',
    subTitle: 'Soothing properties that calm common skin conditions.',
    ingredients: 'Avena Sativa (Oat) Kernel Extract',
  },
  raspberry: {
    title: 'Raspberry',
    subTitle:
      'Broad mix of antioxidants and vitamins with properties that can be effective in promoting skin hydration.',
    ingredients: 'Rubus Idaeus (Raspberry) Fruit Extract',
  },
  tigerGrass: {
    title: 'Tiger Grass',
    subTitle:
      'Also known as Centella Asiatica, has antioxidant properties to protect moisture in the skin.',
    ingredients: 'Centella Asiatica Extract',
  },
};

export const FOOTER = {
  PHONE_NUMBER: '(305) 625-4929',
  COPYRIGHT: `© ${new Date().getFullYear()} • Prime Matter Labs`,
  EMAIL: 'rtl@primematterlabs.com',
  LINKS: [
    {
      url: 'https://app.primematterlabs.com/terms-of-use',
      text: 'Terms of Use',
      analyticsEvent: EVENT_LABEL.termsOfUse,
    },
    {
      url: 'https://app.primematterlabs.com/privacy-policy',
      text: 'Privacy Policy',
      analyticsEvent: EVENT_LABEL.privacyPolicy,
    },
    {
      url: 'https://app.primematterlabs.com/rtl-return-policy',
      text: 'Return Policy',
      analyticsEvent: EVENT_LABEL.returnPolicy,
    },
  ],
};

export const INPUT_NAMES = {
  productBase: 'productBase',
  itemQuantity: 'itemQuantity',
  extras: 'extras',
  goals: 'goals',
  name: 'name',
};

export const SAMPLE_PRICE = 100;

export const SPECIFICATIONS = {
  parabens: {
    title: 'parabens',
    copy: 'No Parabens, Paraffins, Sulfates or Silicones',
  },
  vegan: {
    title: 'vegan',
    copy: 'Vegan & Cruelty-free',
  },
  fragrance: {
    title: 'fragrance',
    copy: 'Fragrance-free',
  },
  sephora: {
    title: 'sephora',
    copy: 'Sephora Clean',
  },
};

export const STEP_NUMBER = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
};

export const INITIAL_FORM_VALUES = {
  productBase: '',
  extras: [],
  goals: [],
  name: '',
  itemQuantity: 1,
};

export const SUMMARY_TABLE_TEXT = {
  buttonText: 'Add to Cart',
  certifications: 'CLEAN AT SEPHORA',
  extrasTitle: 'EXTRAS',
  ingredientsTitle: 'BASE INGREDIENTS',
  addlIngredientsTitle: 'ADDITIONAL INGREDIENTS',
  twoOunceSample: '2 oz sample',
  sampleCost: `$${SAMPLE_PRICE}`,
  standards: 'PARABENS, SULFATE, PHTHALATE, CRUELTY, FRAGRANCE FREE',
};

export const WAITLIST_TAG_NAMES = {
  hairCareHairSerum: 'Hair Care - Hair Serum',
  bodyCareBodyWash: 'Body Care - Body Wash',
  sunCareMineralSunscreen: 'Sun Care - Mineral Sunscreen',
};
