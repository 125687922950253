// Libraries
import React, { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
// Components
import { BaseInput, Spinner } from '.';

enum keyCodes {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
  Space = 'Space',
}

interface SelectProps {
  helperText?: string;
  options: Option[];
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  width?: string;
  [x: string]: any;
}

interface OptionMenuProps {
  inputHasHelperText?: boolean;
  isLoading?: boolean;
  options: Option[];
  width?: string;
  onClose: () => void;
  [x: string]: any;
}

export const Menu: React.FC<OptionMenuProps> = ({
  inputHasHelperText,
  isLoading,
  width = 'w-full',
  options,
  onClose,
  ...rest
}) => {
  // Ref to detect click outside the dropdown menu
  const ref = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(-1);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    // Scroll to selected option when selectedOptionIndex changes
    if (
      selectedOptionIndex !== null &&
      optionRefs.current[selectedOptionIndex]
    ) {
      optionRefs.current[selectedOptionIndex]?.scrollIntoView({
        behavior: 'auto',
      });
    }
  }, [selectedOptionIndex]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isLoading || options.length === 0) return;

    switch (event.code) {
      case keyCodes.ArrowUp:
        event.preventDefault();
        if (selectedOptionIndex === -1) {
          setSelectedOptionIndex(options.length - 1);
        } else {
          setSelectedOptionIndex((prevIndex) =>
            prevIndex === 0 ? options.length - 1 : prevIndex - 1
          );
        }
        break;
      case keyCodes.ArrowDown:
        event.preventDefault();
        if (selectedOptionIndex === -1) {
          setSelectedOptionIndex(0);
        } else {
          setSelectedOptionIndex((prevIndex) =>
            prevIndex === options.length - 1 ? 0 : prevIndex + 1
          );
        }
        break;
      case keyCodes.Enter:
      case keyCodes.Space:
        event.preventDefault();
        if (selectedOptionIndex !== -1 && options[selectedOptionIndex]) {
          rest.onChange(options[selectedOptionIndex]);
          onClose && onClose();
        }
        break;
      default:
        const lowerCaseKey = event.key.toLowerCase();

        // Find all options that start with the key pressed
        const matchingOptions = options.filter((option) =>
          option.label.toLowerCase().startsWith(lowerCaseKey)
        );

        // If there are no matching options, return
        if (matchingOptions.length === 0) return;

        // Select the first matching option and if the key is pressed again, select the next matching option
        if (
          selectedOptionIndex === -1 ||
          matchingOptions.indexOf(options[selectedOptionIndex]) === -1
        ) {
          setSelectedOptionIndex(
            options.findIndex((option) => option === matchingOptions[0])
          );
        } else {
          // Find the index of the currently selected option in the matching options
          const currentIndex = options.findIndex(
            (option) => option === options[selectedOptionIndex]
          );

          // If the currently selected option is the last matching option, select the first matching option
          // Otherwise, select the next matching option
          setSelectedOptionIndex(
            options[selectedOptionIndex] ===
              matchingOptions[matchingOptions.length - 1]
              ? options.findIndex((option) => option === matchingOptions[0])
              : currentIndex + 1
          );
        }

        break;
    }
  };

  const handleOptionClick = (option: Option) => {
    rest.onChange(option);
    onClose && onClose();
  };

  const renderOptions = () => {
    if (isLoading)
      return (
        <div className="flex justify-center p-4 pr-12 bg-white font-inter text-sm">
          <Spinner />
        </div>
      );
    if (options.length === 0)
      return (
        <div className="flex justify-center p-4 pr-12 bg-white font-inter text-sm">
          No results found
        </div>
      );

    return options.map((option, index: number) => {
      return (
        <div
          key={index}
          onClick={() => handleOptionClick(option)}
          ref={(ref) => (optionRefs.current[index] = ref)}
          className={`flex p-4 pr-12 bg-white font-inter text-sm hover:bg-blue-95 cursor-pointer ${
            selectedOptionIndex === index ? 'bg-blue-95' : ''
          }`}
        >
          {option.leadingIcon} {option.label} {option.trailingIcon}
        </div>
      );
    });
  };

  return (
    <div
      ref={ref}
      className={`absolute ${
        inputHasHelperText ? 'top-10' : 'top-[68px]'
      } flex flex-col shadow-md z-10 max-h-[16rem] overflow-auto ${width}`}
      onKeyDown={handleKeyDown}
      tabIndex={0} // Make the div focusable to capture key events
    >
      {renderOptions()}
    </div>
  );
};

export const Select: React.FC<SelectProps> = ({
  options,
  helperText,
  leadingIcon,
  trailingIcon,
  width,
  ...rest
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <div
      onKeyDown={(e) => {
        if (e.code === keyCodes.Enter || e.code === keyCodes.Space) {
          e.preventDefault();
          !rest.disabled && setIsMenuOpen(!isMenuOpen);
        }
      }}
      className={`relative ${rest.disabled ? '' : 'cursor-pointer'}`}
    >
      <BaseInput
        additionalClasses={'cursor-pointer'}
        leadingIcon={leadingIcon}
        onClick={() => {
          !rest.disabled && setIsMenuOpen(!isMenuOpen);
        }}
        helperText={helperText}
        trailingIcon={
          trailingIcon ? (
            trailingIcon
          ) : (
            <ChevronDownIcon
              className="text-royal-50 cursor-pointer"
              height="20"
              width="20"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            />
          )
        }
        readOnly
        width={width}
        {...rest}
        value={
          // The value prop is passed down by Formik as an object with a shape of { label: string, value: string }
          // So in order to find the display value, we need to find the option with the same value
          // Hence value.value
          options.find(
            (option) =>
              option.value === rest.value?.value || option.value === rest.value
          )?.label || ''
        }
      />
      {isMenuOpen && (
        <Menu
          width={width}
          onClose={closeMenu}
          options={options}
          {...rest}
          inputHasHelperText={Boolean(helperText)}
        />
      )}
    </div>
  );
};
