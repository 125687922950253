// Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import {
  Page,
  PageHeader,
  DontSeeYourProduct,
} from 'features/ready-to-launch/ui';
// Assets
import sampleOne from 'features/ready-to-launch/images/sampleOne.svg';
// Utils
import { useAnalytics, useMobile } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { ProductCategoryCard } from './product-category-card.component';
import products from 'features/ready-to-launch/products/products.util';
import { Product } from 'features/ready-to-launch/types';
import { ROUTES } from 'features/navigation';

const PRODUCT_CATEGORY_HEADER_TEXT = 'Choose your product category';
const PRODUCT_CATEGORY_SUBHEADER_TEXT =
  'Products are from our collection of trusted and tested formulas.';

export const ProductCategory: React.FC = () => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const { isMobile } = useMobile();
  const goToWizard = (productCategory: string) => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCChooseCategory,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: productCategory,
      nonInteraction: true,
    });
    navigate(ROUTES.MASS_CUSTOMIZATION.wizard.route, {
      state: { productCategory },
    });
  };

  const renderDesktopProductCategoryCards = () => {
    const productValues = Object.values(products);

    return productValues.map((product: Product, index: number) => {
      if (index % 2 === 0) {
        const currentProduct = product;
        const nextProduct = productValues[index + 1];
        const isLastRow =
          nextProduct === undefined || index + 1 === productValues.length - 1;
        return (
          <div
            key={index}
            className={`flex justify-evenly mx-28 ${isLastRow ? '' : 'mb-5'}`}
          >
            <ProductCategoryCard
              product={currentProduct}
              goToWizard={goToWizard}
            />
            {nextProduct && (
              <ProductCategoryCard
                product={nextProduct}
                goToWizard={goToWizard}
              />
            )}
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderMobileCategoryCards = () => {
    return Object.values(products).map((product, index) => (
      <ProductCategoryCard
        key={index}
        product={product}
        goToWizard={goToWizard}
      />
    ));
  };

  return (
    <Page>
      <div className="px-10">
        <PageHeader
          header={PRODUCT_CATEGORY_HEADER_TEXT}
          subheader={PRODUCT_CATEGORY_SUBHEADER_TEXT}
        >
          <img
            width={isMobile ? 52 : 96}
            className="sm:self-start"
            src={sampleOne}
            alt="sample-one"
          />
        </PageHeader>
      </div>
      <div className="bg-lightestBlue pb-20">
        <div className="flex flex-col items-center pt-10 sm:items-stretch mb-5">
          {isMobile
            ? renderMobileCategoryCards()
            : renderDesktopProductCategoryCards()}
        </div>
        <div className="sm:mx-32 mx-2">
          <DontSeeYourProduct />
        </div>
      </div>
    </Page>
  );
};
