// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import { Card, Divider, Select } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { UserContext } from 'context';
import { useConfirmation } from 'hooks';
// Constants
import { ROLES } from 'features/rbac';
import { BRIEF_FORM_KEYS, RESTART_CONFIRM_STATE } from '../brief.constants';

import { ProductTypeCards } from './product-type-cards.component';
import { PRODUCT_OPTIONS } from './form-module.constants';

interface ProductBasicsProps {
  projectIsRtl?: boolean;
}

export const ProductBasics: React.FC<ProductBasicsProps> = ({
  projectIsRtl,
}) => {
  const {
    getFieldProps,
    setFieldValue,
    values,
    validateField,
  } = useFormikContext<InternalBriefFormValues>();

  const { confirm } = useConfirmation();

  const { roles } = useContext(UserContext)!;

  const isEmployee = roles.includes(ROLES.EMPLOYEE);

  const renderInputs = () => {
    return (
      <div className="grid grid-col-1 md:grid-cols-2 md:gap-x-6">
        <Select
          {...getFieldProps(BRIEF_FORM_KEYS.PRODUCT_CATEGORY)}
          label="Product Category"
          onChange={(option: Option) =>
            setFieldValue(BRIEF_FORM_KEYS.PRODUCT_CATEGORY, option)
          }
          options={PRODUCT_OPTIONS.map((option) => {
            return { label: option.label, value: option.value };
          })}
          placeholder="Select"
        />
        <Select
          disabled={!values.productCategory.value}
          {...getFieldProps(BRIEF_FORM_KEYS.PRODUCT_TYPE)}
          helperText="Select a category to see types"
          label="Product Type"
          onChange={(option: Option) => {
            setFieldValue(BRIEF_FORM_KEYS.PRODUCT_TYPE, option);
          }}
          options={
            PRODUCT_OPTIONS.find(
              (option) => option.value === values.productCategory.value
            )?.productTypes.map((type) => ({
              label: type.label,
              value: type.value,
            })) || []
          }
          placeholder="Select"
        />
      </div>
    );
  };

  const renderCards = () => {
    return (
      <div className="flex flex-col sm:flex-row flex-wrap sm:col-span-6 gap-6">
        {PRODUCT_OPTIONS.map((option) => {
          const contents = option.productTypes.map((type) => {
            return type.label;
          });
          return (
            <Card
              data-testid={`product-category-${option.value}`}
              key={option.value}
              title={option.label}
              orientation="horizontal"
              content={contents}
              image={option.image}
              className="w-full sm:w-[303px] h-[200px]"
              action={async () => {
                // If we already have a category or anything after that
                // then we should confirm before changing
                if (values.productCategory.value && values.productType.value) {
                  const shouldProceed = await confirm(RESTART_CONFIRM_STATE);

                  if (!shouldProceed) return;
                }

                // Calling validate here as the name field can be easily overlooked
                validateField(BRIEF_FORM_KEYS.PROJECT_NAME);

                setFieldValue(BRIEF_FORM_KEYS.PRODUCT_CATEGORY, option);
              }}
              selected={
                values[
                  BRIEF_FORM_KEYS.PRODUCT_CATEGORY as keyof InternalBriefFormValues
                ].value === option.value
              }
            />
          );
        })}
      </div>
    );
  };

  const renderProductCategories = () => {
    if (isEmployee) {
      return renderInputs();
    }
    return renderCards();
  };

  return (
    <div className={isEmployee ? '' : 'sm:grid sm:grid-cols-9 sm:col-span-9'}>
      <div className="col-span-9">
        <p id="product-basics" className="font-inter text-lg text-grey-20 mb-6">
          {isEmployee ? 'Product Basics' : 'Product Category'}
        </p>
      </div>
      {renderProductCategories()}
      <Divider className="col-span-6 mt-12" />
      {!isEmployee && values.productCategory.value && (
        <ProductTypeCards projectIsRtl={projectIsRtl} />
      )}
    </div>
  );
};
