// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
import { BriefTextField, BriefSelect } from '../layout';
// Components
import { FormLayout } from '../layout';
// Utils
import { BriefFormValues } from '../../types';
// Constants
import {
  BRIEF_FORM_INPUTS,
  COST_PER_UNIT_RANGES_OPTIONS,
  COST_PER_UNIT_RANGE_LABEL,
  COST_PER_UNIT_SPECIFIC_LABEL,
} from '../../constants';

const TITLE = 'What is your target retail price?';
const SUB_TITLE =
  'This is the desired price of the product for consumers in the retail market.';

export const TargetRetailPrice: React.FC = () => {
  const { errors, values } = useFormikContext<BriefFormValues>();
  const { msrpPerUnitSpecific, msrpPerUnitRange } = BRIEF_FORM_INPUTS;
  const hasErrors = msrpPerUnitRange in errors || msrpPerUnitSpecific in errors;

  return (
    <FormLayout
      isNextButtonDisabled={hasErrors}
      title={TITLE}
      subTitle={SUB_TITLE}
    >
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6">
        <div className="sm:col-start-2 col-span-2 sm:col-span-8 sm:flex">
          <div className="sm:w-1/2">
            <BriefTextField
              label={COST_PER_UNIT_SPECIFIC_LABEL}
              formikKey={msrpPerUnitSpecific}
              formikKeyToNullify={msrpPerUnitRange}
              prefix={'$'}
            />
          </div>
          <div className="sm:relative sm:top-[3.2rem] font-mono text-xs font-semibold sm:self-start my-5 sm:my-0 sm:mx-5">
            OR
          </div>
          <div className="sm:w-1/2">
            <BriefSelect
              label={COST_PER_UNIT_RANGE_LABEL}
              options={COST_PER_UNIT_RANGES_OPTIONS}
              formikKey={msrpPerUnitRange}
              formikKeyToNullify={msrpPerUnitSpecific}
              value={values.msrpPerUnitRange}
            />
          </div>
        </div>
      </div>
    </FormLayout>
  );
};
