// Libraries
import React from 'react';
// Components
import { FormSelectList } from 'features/ui';
import { FormCTA } from './form-CTA.component';
// Utils
import { IMassCustomizationScreen } from 'features/ready-to-launch/types';
// Constants
import { INPUT_NAMES } from 'features/ready-to-launch/constants';

export const GoalsStep: React.FC<IMassCustomizationScreen> = ({ product }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3">
      <div className="mx-5 sm:mx-24 sm:col-span-3 mb-8 mt-10">
        <FormCTA
          stepName={INPUT_NAMES.goals}
          title={product.goalScreenProperties.title}
          description={product.goalScreenProperties.description}
        />
      </div>
      <div className="grid-cols-1 mx-5 sm:col-span-3 sm:mx-24 bg-white">
        <FormSelectList
          analyticsPrefix="MC"
          limit={product.goalScreenProperties.goalLimit}
          inputName={INPUT_NAMES.goals}
          items={product.goals}
          type="checkbox"
        />
      </div>
    </div>
  );
};
