// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { FormLayout } from '../layout';
import { BriefTextField } from '../layout';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';

const TITLE = 'Let’s get the basics of the project';
const SUB_TITLE =
  'Describe one product at a time. You will have the opportunity to submit more project briefs at the end.';

const PROJECT_DESCRIPTION_LABEL = (
  <label className="font-mono text-xs font-semibold mb-3">
    PROJECT DESCRIPTION <span className="text-gray">(OPTIONAL)</span>
  </label>
);

const PROJECT_NAME_LABEL = 'PROJECT NAME *';

export const BasicInfo: React.FC = () => {
  const { errors, getFieldProps } = useFormikContext();
  const { projectName, projectDescription } = BRIEF_FORM_INPUTS;

  return (
    <FormLayout
      subTitle={SUB_TITLE}
      title={TITLE}
      isNextButtonDisabled={projectName in errors}
    >
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6 sm:gap-y-6">
        <div className="col-span-2 sm:col-start-2 sm:col-span-4 sm:mb-0 mb-6">
          <BriefTextField formikKey={projectName} label={PROJECT_NAME_LABEL} />
        </div>
        <div className="col-span-2 sm:col-start-2 sm:col-span-8 flex flex-col">
          {PROJECT_DESCRIPTION_LABEL}
          <textarea
            rows={6}
            className="col-span-2 sm:col-start-5 sm:col-span-4 border border-black font-display text-lg p-6"
            {...getFieldProps(projectDescription)}
          />
        </div>
      </div>
    </FormLayout>
  );
};
