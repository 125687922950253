// Libaries
import React from 'react';
import { LightBulbIcon } from '@heroicons/react/16/solid';
// Components
import { Note } from 'design-system';

interface PrimeTipProps {
  additionalClasses?: string;
  contents: string[];
  url?: string;
}

export const PrimeTip: React.FC<PrimeTipProps> = ({
  additionalClasses,
  contents,
  url,
}) => {
  return (
    <Note
      additionalClasses={additionalClasses}
      header="Prime Tip"
      contents={contents}
      icon={<LightBulbIcon />}
      iconColor={'text-teal-50'}
      noteColor="bg-teal-95"
      textColor="text-teal-20"
      url={url}
    />
  );
};
