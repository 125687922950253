// Libraries
import React from 'react';
// Components
import { InterstitialLayout } from '../layout';
// Assets
import interstitialImageThree from '../../assets/interstitialImageThree.png';
import interstitialImageThreeMobile from '../../assets/interstitialImageThreeMobile.png';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const STEPS = [
  {
    text: 'Project basics',
    isComplete: true,
  },
  {
    text: 'Market strategy',
    isComplete: true,
  },
  {
    text: 'Project requirements',
    isComplete: false,
  },
];

const IMAGE = {
  src: interstitialImageThree,
  alt: 'cream',
};

const MOBILE_IMAGE = {
  src: interstitialImageThreeMobile,
  alt: 'cream',
};

const TITLE = 'Final details to bring your product idea to life';

export const FinalDetailsInterstitial: React.FC = () => {
  return (
    <InterstitialLayout
      eventCategory={
        AnalyticConstants.EVENT_CATEGORIES.CBDividerProjectRequirements
      }
      image={IMAGE}
      mobileImage={MOBILE_IMAGE}
      steps={STEPS}
      title={TITLE}
    />
  );
};
