// Libraries
import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import CompleteIcon from '@material-ui/icons/CheckCircle';
import { ErrorOutline } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Components
import { IngredientListTrackerFile } from './ingredient-list-tracker-file.component';
// Assets
import { ReactComponent as WaitingIcon } from '../../images/waiting.svg';
import { ReactComponent as PendingIcon } from '../../images/pending.svg';
import { ReactComponent as ProjectBriefIcon } from '../../images/ProjectBrief.svg';
import { ReactComponent as ProcurementIcon } from '../../images/Procurement.svg';
import { ReactComponent as ProductionIcon } from '../../images/Production.svg';
import { ReactComponent as RAndDFormulationIcon } from '../../images/RAndDFormulation.svg';
import { ReactComponent as TestingIcon } from '../../images/Testing.svg';
import { ReactComponent as ReadyToLaunchIcon } from '../../../../../img/ready-to-launch.svg';
// Utils
import {
  ProjectType,
  SectionStatus,
  Task,
  TaskOwner,
  TaskStatus,
} from '../../types';
import { dateFormatter } from '../../utils';
import { useAnalytics } from 'hooks';
import { IApiData, ProjectAttributes } from 'api';
// Styles
import { THEME_PALETTE } from 'styles/mui/themeV2';
// Constants
import {
  MILESTONE_STATUSES,
  MILESTONE_TITLES,
  FILE_CATEGORIES,
  TASK_TITLES,
} from '../../constants';
import { AnalyticsConstants } from 'features/analytics';
import { TrackerFiles } from './tracker-files.component';
const { COMPLETED, IN_PROGRESS, NOT_STARTED } = MILESTONE_STATUSES;
const START_OF_FORMULATION_STEPS = 2;
const PRIME_MATTER_LABS = 'Prime Matter Labs';
const ACTION_NEEDED = 'Action Needed';

// helpers
const defaultIconStyles = {
  customIcon: {
    width: 32,
    height: 32,
  },
  completedSectionIcon: {
    width: 44,
    height: 44,
    color: THEME_PALETTE.green.dark,
  },
  completedTaskIcon: {
    color: THEME_PALETTE.green.dark,
  },
  waitingSectionIcon: {
    width: 44,
    height: 44,
  },
};

const getInProgressIcon = (title: Maybe<string>) => {
  switch (title) {
    case MILESTONE_TITLES.PROJECT_BRIEF:
      return <ProjectBriefIcon style={defaultIconStyles.customIcon} />;
    case MILESTONE_TITLES.PROCUREMENT:
      return <ProcurementIcon style={defaultIconStyles.customIcon} />;
    case MILESTONE_TITLES.PRODUCTION:
      return <ProductionIcon style={defaultIconStyles.customIcon} />;
    case MILESTONE_TITLES.R_AND_D_FORMULATION:
      return <RAndDFormulationIcon style={defaultIconStyles.customIcon} />;
    case MILESTONE_TITLES.TESTING:
      return <TestingIcon style={defaultIconStyles.customIcon} />;
    default:
      break;
  }
};

const getIconFromStatus = (
  status: Maybe<TaskStatus | SectionStatus | string>,
  title?: string
): Maybe<JSX.Element> => {
  if (!status) return <WaitingIcon />;

  switch (status) {
    case SectionStatus.completed:
      return <CompleteIcon style={defaultIconStyles.completedSectionIcon} />;
    case TaskStatus.done:
    case TaskStatus.waived:
      return <CompleteIcon style={defaultIconStyles.completedTaskIcon} />;
    case TaskStatus.inProgress:
    case TaskStatus.pending:
    case TaskStatus.workingOnIt:
      return <PendingIcon />;
    case TaskStatus.notStarted:
      return <WaitingIcon />;
    case SectionStatus.notStarted:
      return <WaitingIcon style={defaultIconStyles.waitingSectionIcon} />;
    case SectionStatus.inProgress:
      return (
        <div className="bg-darkPeach rounded-full p-[6px]">
          {getInProgressIcon(title)}
        </div>
      );
    default:
      return;
  }
};

const getThemeFromStatus = (status: SectionStatus) => {
  switch (status) {
    case COMPLETED:
      return {
        backgroundColor: THEME_PALETTE.green.light,
        borderColor: THEME_PALETTE.green.dark,
        textColor: THEME_PALETTE.green.dark,
      };
    case IN_PROGRESS:
      return {
        backgroundColor: THEME_PALETTE.tan.light,
        borderColor: THEME_PALETTE.tan.main,
        textColor: THEME_PALETTE.gray.main,
      };
    case NOT_STARTED:
      return {
        backgroundColor: THEME_PALETTE.gray.lighter,
        borderColor: THEME_PALETTE.gray.light,
        textColor: THEME_PALETTE.gray.main,
      };
    default:
      return {};
  }
};

interface CollapsibleSectionProps {
  companyName: string;
  files: any[];
  formulationRound: Maybe<number>;
  handleDelete: (e: any) => void;
  handleDownload: (e: any) => void;
  handleUpload: (e: any) => void;
  handleViewILFeedback: (file: any) => void;
  handleViewSampleFeedbackHistory: () => void;
  Icon?: React.ReactElement | JSX.Element;
  isDeleting: boolean;
  project: IApiData<ProjectAttributes>;
  sampleTrackingNumber: Maybe<string>;
  status: SectionStatus;
  tasks: Task[];
  title: string;
  handleModalClick: (file: any) => void;
  setFiles: (files: any) => void;
  setProject: React.Dispatch<React.SetStateAction<IApiData<ProjectAttributes>>>;
}

export const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  companyName,
  files,
  formulationRound,
  handleDelete,
  handleDownload,
  handleUpload,
  handleViewILFeedback,
  handleViewSampleFeedbackHistory,
  Icon,
  isDeleting,
  project,
  status,
  tasks,
  title,
  handleModalClick,
  setFiles,
  setProject,
}) => {
  const analytics = useAnalytics();
  const [isExpanded, setIsExpanded] = useState<boolean>(
    status === MILESTONE_STATUSES.IN_PROGRESS
  );

  const handleClick = (event: any) => {
    event.stopPropagation();
    analytics?.trackEvent({
      eventCategory: AnalyticsConstants.EVENT_CATEGORIES.CPProjectTracker,
      eventAction: AnalyticsConstants.EVENT_ACTIONS.click,
      eventLabel: `${isExpanded ? 'Collapse' : 'Expand'} ${title} ${
        AnalyticsConstants.EVENT_LABEL.collapsibleSection
      }`,
    });
    setIsExpanded(!isExpanded);
  };

  const renderChips = (
    owner: string,
    status?: string,
    isActionable?: boolean,
    tag?: string
  ) => {
    const ownerIsPML = owner === TaskOwner.prime;
    const actionNeeded = () => {
      const iLFiles = files.filter(
        (file: any) =>
          file.attributes.category === FILE_CATEGORIES.approveIngredientList
      );

      if (
        tag &&
        tag === FILE_CATEGORIES.approveIngredientList &&
        iLFiles.length > 0
      ) {
        // if there is at least one IL file with no feedback, then it is actionable
        const hasActionableILFiles = iLFiles.some(
          (file: any) => !file.parsedIncluded.feedback
        );
        return (
          hasActionableILFiles &&
          isActionable &&
          owner === TaskOwner.customer &&
          (status === TaskStatus.inProgress || status === TaskStatus.pending)
        );
      }

      return (
        isActionable &&
        owner === TaskOwner.customer &&
        (status === TaskStatus.inProgress || status === TaskStatus.pending)
      );
    };

    return (
      <div className="flex flex-wrap mb-4 sm:mb-0">
        <div
          className={`flex items-center py-[6px] px-3 sm:ml-3 text-xs font-medium font-mono uppercase rounded-full ${
            ownerIsPML ? 'bg-legacyPeach' : 'bg-lightBlue'
          }`}
        >
          {ownerIsPML ? PRIME_MATTER_LABS : companyName}
        </div>
        {actionNeeded() && (
          <div className="flex gap-1 py-[5px] px-3 mt-3 sm:mt-0 sm:ml-3 items-center text-xs font-medium font-mono uppercase rounded-full text-red-40 bg-alertLightRed">
            {ACTION_NEEDED}
            <ErrorOutline fontSize="small" />
          </div>
        )}
        {status === TaskStatus.waived && (
          <div
            className={
              'flex items-center py-[6px] px-3 sm:ml-3 text-xs font-medium font-mono uppercase rounded-full bg-peach-95 text-peach-50 space-x-1'
            }
          >
            <div>WAIVED</div>
            {project.attributes.projectType === ProjectType.rtl && (
              <div>
                <ReadyToLaunchIcon />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderTask = ({
    actions,
    title,
    status,
    isActionable,
    owner,
    updatedAt,
    isLast,
    waivedTitle,
    tag,
  }: Task) => {
    const isWaived = status === TaskStatus.waived;
    const isWaivedOrDone =
      status === TaskStatus.waived || status === TaskStatus.done;
    const isSampleFeedbackTask = title === TASK_TITLES.GIVE_FEEDBACK;

    return (
      <div
        key={title}
        className={`pt-3 border-b border-lighterGray last:border-b-0 ${
          isLast ? 'border-b-0' : ''
        }`}
      >
        <div className="w-full flex  sm:items-center items-start my-3">
          {getIconFromStatus(status)}
          <div className="w-full flex flex-col items-start ml-3 sm:ml-0 sm:items-center gap-2 sm:gap-0 sm:flex-row">
            <div
              className={`sm:pl-2 text-lg text-left font-inter ${
                isWaivedOrDone && 'text-lightGray'
              }`}
            >
              {isWaived && waivedTitle ? waivedTitle : title}
            </div>
            {renderChips(owner, status, isActionable, tag)}
            <div
              className={`
              ${
                isSampleFeedbackTask ? 'flex' : 'sm:flex'
              } sm:ml-auto sm:items-center text-xs font-inter text-lightGray my-2
              `}
            >
              {actions}

              {isWaivedOrDone && updatedAt && (
                <div className="sm:ml-3">
                  Completed: {dateFormatter(updatedAt)}
                </div>
              )}
            </div>
          </div>
        </div>

        {files.length > 0 && (
          <div className="mb-3 mt-4">{renderFiles(tag!)}</div>
        )}
      </div>
    );
  };

  const renderTaskList = (tasks: Task[], sectionTitle: string) => {
    return sectionTitle !== MILESTONE_TITLES.R_AND_D_FORMULATION ? (
      tasks.map(renderTask)
    ) : (
      <>
        {tasks.slice(0, START_OF_FORMULATION_STEPS).map(renderTask)}
        <div
          className="flex flex-col px-5 py-4 bg-white rounded text-left"
          style={{
            position: 'relative',
            width: 'calc(100% + 2 * 20px)',
            left: '-20px',
          }}
        >
          <div className="flex justify-between flex-wrap">
            <h3 className="font-inter text-lg text-darkGray">
              Sample Feedback
            </h3>
          </div>
          <div className="mr-5">
            {tasks.slice(START_OF_FORMULATION_STEPS).map(renderTask)}
          </div>
        </div>
      </>
    );
  };

  const SectionIcon = () =>
    getIconFromStatus(status, title) || Icon || <PendingIcon />;

  const { backgroundColor, borderColor, textColor } = getThemeFromStatus(
    status
  );

  const renderFiles = (tag: string) => {
    switch (tag) {
      case FILE_CATEGORIES.approveIngredientList:
        return (
          <IngredientListTrackerFile
            files={files}
            isDeleting={isDeleting}
            handleDelete={handleDelete}
            handleDownload={handleDownload}
            handleModalClick={handleModalClick}
            setFiles={setFiles}
            setProject={setProject}
            handleViewFeedback={handleViewILFeedback}
          />
        );
      case FILE_CATEGORIES.sendArtwork:
      case FILE_CATEGORIES.billsOfMaterials:
        return (
          <TrackerFiles
            files={files}
            isDeleting={isDeleting}
            tag={tag}
            handleDelete={handleDelete}
            handleDownload={handleDownload}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="flex w-full mb-3">
      <Accordion
        data-testid="accordion"
        style={{
          backgroundColor: backgroundColor,
          border: `1px solid ${borderColor}`,
          boxShadow: 'none',
        }}
        expanded={isExpanded}
        onChange={(event) => handleClick(event)}
        className="w-full px-6 py-[22px] sm:py-[28px]"
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              role="button"
              fontSize="large"
              className="text-darkGray"
            />
          }
          aria-label="Expand"
          aria-controls="additional-actions1-content"
        >
          <div className="w-full flex justify-between">
            <div className="w-full flex gap-2 items-center">
              <SectionIcon />
              <div className="grow flex sm:flex-row flex-col justify-between">
                <h1 className="text-left font-inter text-[1.5rem]">{title}</h1>
                <div className="flex items-center">
                  <h3
                    className={`font-mono uppercase whitespace-nowrap text-[13px]`}
                    style={{ color: textColor }}
                  >
                    {status}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full pl-2 sm:pl-3">
            {renderTaskList(tasks, title)}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
