// Utils
import { Product } from '../types';

export const determineCongratsScreenTitle = (
  product: Product,
  productBase: string
) => {
  return (
    product.productBases.find((base) => productBase === base.title)
      ?.congratsScreenTitle || productBase?.toLowerCase()
  );
};
