// Libraries
import React from 'react';

interface ICharacterLimit {
  contentLength: number;
  characterLimit: number;
}

export const CharacterLimit: React.FC<ICharacterLimit> = ({
  contentLength,
  characterLimit,
}) => (
  <div className="font-mono text-xs text-gray mt-3">
    {contentLength}/{characterLimit} CHARACTERS
  </div>
);
