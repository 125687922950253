// Libraries
import React from 'react';
import { useFormikContext, FieldArray } from 'formik';
import _ from 'lodash';
// Components
import { Checkbox } from 'features/ui';
import { BriefTextField, FormLayout } from '../layout';
// Utils
import { Benchmark, BriefFormValues } from '../../types';
// Assets
import add from '../../assets/add.svg';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';

const BENCHMARK_LIMIT = 5;
const TITLE =
  'What existing products would you like to reference for benchmarks?';
const SUB_TITLE =
  'Identify current products in the market to benchmark your product against.';

const CHECKBOXES = ['appearance', 'color', 'texture', 'viscosity', 'fragrance'];

export const Benchmarks: React.FC = () => {
  const { errors, setFieldValue, values } = useFormikContext<BriefFormValues>();
  const benchmarkErrors = errors[
    BRIEF_FORM_INPUTS.benchmarks as keyof BriefFormValues
  ] as Benchmark[] | undefined;

  const renderCheckboxes = (idx: number) => {
    return CHECKBOXES.map((checkbox) => {
      return (
        <div
          key={checkbox}
          onClick={() =>
            setFieldValue(
              `benchmarks[${idx}].${checkbox}`,
              !values.benchmarks[idx][checkbox]
            )
          }
          className="flex items-center mr-6 mb-6"
        >
          <Checkbox
            title=""
            inputName="benchmarks"
            checked={values.benchmarks[idx][checkbox]}
          />
          <label className="text-lg font-display ml-3">
            {_.capitalize(checkbox)}
          </label>
        </div>
      );
    });
  };

  return (
    <FormLayout
      isNextButtonDisabled={Boolean(benchmarkErrors && benchmarkErrors.length)}
      subTitle={SUB_TITLE}
      title={TITLE}
    >
      <div className="mx-5 grid grid-cols-2 gap-x-6 sm:mx-0 sm:grid-cols-12">
        <FieldArray
          name={BRIEF_FORM_INPUTS.benchmarks}
          render={(arrayHelpers) => (
            <>
              {values.benchmarks.map((_bm, idx) => (
                <div
                  key={idx}
                  className="col-span-2 mb-6 sm:col-start-2 sm:col-span-8 sm:mb-0"
                >
                  <div className="flex flex-col">
                    <BriefTextField
                      label={'PRODUCT LINK'}
                      formikKey={`benchmarks[${idx}].url`}
                    />
                    <div className="mt-1 text-xs font-display text-gray">
                      http://www.example.com
                    </div>
                    {benchmarkErrors && benchmarkErrors?.[idx]?.url && (
                      <div className="mt-1 text-xs text-red-40 font-display">
                        {benchmarkErrors[idx].url}
                      </div>
                    )}
                    <div className="sm:flex mt-3">{renderCheckboxes(idx)}</div>
                  </div>
                </div>
              ))}
              {values.benchmarks.length < BENCHMARK_LIMIT && (
                <div className="sm:col-start-2">
                  <button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        url: '',
                        appearance: false,
                        color: false,
                        texture: false,
                        viscosity: false,
                        fragrance: false,
                      })
                    }
                  >
                    <div className="flex items-center col-span-2 whitespace-nowrap sm:col-start-2 sm:col-span-1">
                      <img src={add} alt="add" />
                      <p className="font-mono text-xs font-semibold ml-2">
                        ADD ANOTHER
                      </p>
                    </div>
                  </button>
                </div>
              )}
            </>
          )}
        />
      </div>
    </FormLayout>
  );
};
