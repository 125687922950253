// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { BriefTextField, FormLayout } from '../layout';
import { FormSelectList, Checkbox } from 'features/ui';
// Utils
import { BriefFormValues } from '../../types';
import { BRIEF_FORM_INPUTS } from '../../constants';

const FRAGRANCE_BENCHMARK_LABEL = 'PRODUCT LINK (OPTIONAL)';
const FRAGRANCE_LABEL = (
  <div className="font-mono text-xs">
    <span style={{ color: '#333333' }}>SELECT ALL RELEVANT FRAGRANCES</span>{' '}
    <span style={{ color: '#777777' }}>(OPTIONAL)</span>
  </div>
);
const TITLE = 'What will be the fragrance of your product?';
const SUB_TITLE =
  'Prime Matter Labs does not develop fragrances in house, but we can connect you to a fragrance house for fragrance selection or development.';

const SPECIFIC_FRAGRANCES = ['Floral', 'Fresh', 'Spicy', 'Woodsy'];

export const Fragrance: React.FC = () => {
  const { errors, values, setFieldValue } = useFormikContext<BriefFormValues>();

  const { fragranceComment, specificFragrances } = BRIEF_FORM_INPUTS;

  const renderSpecificFragrances = () => {
    return SPECIFIC_FRAGRANCES.map((fragrance) => {
      return (
        <div
          key={fragrance}
          onClick={() =>
            setFieldValue(
              BRIEF_FORM_INPUTS.specificFragrances,
              values.specificFragrances.includes(fragrance)
                ? [...values.specificFragrances.filter((f) => f !== fragrance)]
                : [...values.specificFragrances, fragrance]
            )
          }
          className="flex items-center mb-6"
        >
          <Checkbox
            inputName={BRIEF_FORM_INPUTS.specificFragrances}
            title={fragrance}
          />

          <div className="ml-3 font-display text-lg mr-6">{fragrance}</div>
        </div>
      );
    });
  };
  return (
    <FormLayout
      isNextButtonDisabled={BRIEF_FORM_INPUTS.fragranceType in errors}
      subTitle={SUB_TITLE}
      title={TITLE}
    >
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6 sm:gap-y-6">
        <div className="col-span-2 sm:col-start-2 sm:col-span-8 sm:mt-6 bg-white">
          <FormSelectList
            inputName={BRIEF_FORM_INPUTS.fragranceType}
            items={[
              {
                title: 'Fragrance-free',
                value: 'free',
                fieldsToClear: [fragranceComment, specificFragrances],
              },
              {
                title: 'I have my own',
                value: 'own',
                fieldsToClear: [fragranceComment, specificFragrances],
              },
              {
                title: 'I have a benchmark fragrance',
                value: 'bmark',
                fieldsToClear: [specificFragrances],
                expandedContent: (
                  <div className="w-full pl-20 pr-6 pb-6">
                    <div className="flex flex-col">
                      <BriefTextField
                        label={FRAGRANCE_BENCHMARK_LABEL}
                        formikKey={BRIEF_FORM_INPUTS.fragranceComment}
                      />
                    </div>
                  </div>
                ),
              },
              {
                expandedContent: (
                  <div className="w-full pl-20 pb-6">
                    <div className="flex flex-col">
                      {FRAGRANCE_LABEL}
                      <div className="sm:flex mt-3">
                        {renderSpecificFragrances()}
                      </div>
                    </div>
                  </div>
                ),
                title: 'I want to develop a fragrance',
                value: 'custom',
                fieldsToClear: [fragranceComment],
              },
            ]}
            type="radio"
          />
        </div>
      </div>
    </FormLayout>
  );
};
