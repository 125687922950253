// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { Formik, FormikHelpers } from 'formik';
// Components
import { BenchmarksSection } from './pages/benchmarks-section.component';
import { BriefSubmissionConfirmation } from './pages/brief-submission-confirmation.component';
import { ProjectInfoPane } from './project-info-pane.component';
import { Formula } from './pages/formula.component';
import { AdditionalInfo } from './pages/additional-info.component';
import { Notes } from './pages/notes.component';
import { ProgressTracker } from './progress-tracker.component';
import { RtlConfirmation } from './pages/rtl-confirmation.component';
import { RtlDetector } from '../rtl-logic';
import { RtlLogicEngine } from '../rtl-logic/';
import { FormNavigation } from './form-navigation.component';
import { Alert } from 'design-system';
// Utils
import { initialCustomerBriefFormValues } from '../internal-brief/form.config';
import { ConfirmationContextProvider } from 'context/confirmation-context/confirmation-context-provider.component';
import { MobileContext, RtlIconMapContextProvider } from 'context';
import { UserContext } from 'context/user-context';
import { useApi } from 'api';
import {
  allCustomDevelopmentValidationsSchema,
  allRTLValidationsSchema,
  customDevelopmentAdditionalInfoValidationSchema,
  formulaSectionValidationSchema,
  rtlAdditionalInfoValidationSchema,
} from './validations';

export const CustomerBriefV2 = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rtlFormulas, setRtlFormulas] = useState<any[]>([]);
  const [filteredRtlFormulas, setFilteredRtlFormulas] = useState<any[]>([]);
  const [allRtlModulesHaveValues, setAllRtlModulesHaveValues] = useState<
    boolean
  >(false);
  const [projectIsRtl, setProjectIsRtl] = useState<boolean>(true);
  const [rtlFormulaUuid, setRtlFormulaUuid] = useState<string | null>(null);
  const [projectUuid, setProjectUuid] = useState<string | null>(null);

  const { userSession } = useContext(UserContext)!;
  const { customerMetadata } = userSession || {};
  const { companyUuid } = customerMetadata || {};

  const { isMobile } = useContext(MobileContext);

  const PAGES = [
    {
      component: (
        <Formula
          allRtlModulesHaveValues={allRtlModulesHaveValues}
          projectIsRtl={projectIsRtl}
        />
      ),
      title: 'Formula',
      validationSchema: formulaSectionValidationSchema,
    },
    {
      component: <AdditionalInfo projectIsRtl={projectIsRtl} />,
      title: 'Additional Info',
      validationSchema: customDevelopmentAdditionalInfoValidationSchema,
    },
    { component: <BenchmarksSection />, title: 'Benchmarks' },
    { component: <Notes />, title: 'Notes' },
    { component: <BriefSubmissionConfirmation />, title: 'Confirmation' },
  ];

  const RTL_PAGES = [
    {
      component: (
        <Formula
          allRtlModulesHaveValues={allRtlModulesHaveValues}
          projectIsRtl={projectIsRtl}
        />
      ),
      title: 'Formula',
      validationSchema: formulaSectionValidationSchema,
    },
    {
      component: <AdditionalInfo projectIsRtl={projectIsRtl} />,
      title: 'Additional Info',
      validationSchema: rtlAdditionalInfoValidationSchema,
    },
    { component: <Notes />, title: 'Notes' },
    {
      component: (
        <RtlConfirmation
          projectUuid={projectUuid}
          rtlFormulaUuid={rtlFormulaUuid}
        />
      ),
      title: 'RTL Confirmation',
    },
  ];

  const { postProjectV2 } = useApi();
  const currentPageIsRtlConfirmation =
    projectIsRtl && RTL_PAGES[currentPage].title === 'RTL Confirmation';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const submitForm = (values: any, { setSubmitting }: FormikHelpers<any>) => {
    const {
      antiFrizz,
      benchmarks,
      brightening,
      closure,
      colorNote,
      conditioning,
      container,
      countries,
      countryComplianceNote,
      curlDefining,
      deepConditioning,
      detangle,
      eyesReduceAppearanceOfDarkCircles,
      eyesReduceAppearanceOfLineCrowsFeet,
      eyesReduceAppearanceOfPuffiness,
      firming,
      hairSmoothing,
      hardHold,
      hydration,
      initialDateOfBrief,
      lightHold,
      material,
      mattifying,
      minimumOrderQuantity,
      moisture,
      moisturization,
      mustExcludeIngredients,
      mustHaveIngredients,
      otc,
      otcNote,
      previouslyBroughtToMarket,
      previouslyBroughtToMarketNote,
      productCategory,
      productDescription,
      productType,
      projectName,
      radianceGlow,
      retailerStandards,
      sampleQuantity,
      selfTanner,
      shine,
      size,
      skinSmoothing,
      soothing,
      spf,
      strengthening,
      structure,
      targetCustomer,
      targetCost,
      targetDate,
      tightening,
      tint,
      unit,
    } = values;

    const parsedMinimumOrderQuantity =
      typeof minimumOrderQuantity === 'number'
        ? minimumOrderQuantity
        : parseInt(minimumOrderQuantity.replace(/,/g, ''));

    postProjectV2({
      data: {
        project: {
          antiFrizz,
          brightening,
          category: productCategory.value,
          colorNote,
          companyUuid,
          conditioning,
          countryComplianceNote,
          curlDefining,
          deepConditioning,
          description: productDescription,
          detangle,
          eyesReduceAppearanceOfDarkCircles,
          eyesReduceAppearanceOfLineCrowsFeet,
          eyesReduceAppearanceOfPuffiness,
          firming,
          formulaUuid: rtlFormulaUuid,
          hairSmoothing,
          hardHold,
          hydration,
          initialDateOfBrief: new Date(initialDateOfBrief),
          lightHold,
          mattifying,
          moisture,
          moisturization,
          name: projectName,
          otc,
          otcNote,
          previouslyBroughtToMarket,
          previouslyBroughtToMarketNote,
          productType: productType.value,
          radianceGlow,
          retailerStandards,
          sampleQuantity: sampleQuantity.value,
          selfTanner,
          shine,
          skinSmoothing,
          soothing,
          spf,
          strengthening,
          tightening,
          tint,
          briefAttributes: {
            bMarksAttributes: benchmarks,
            briefSupplementAttributes: {
              closure: closure.value,
              container,
              material: material.value,
            },
            countries,
            minimumOrderQuantity: parsedMinimumOrderQuantity,
            mustExcludeIngredients,
            mustHaveIngredients,
            size,
            structure,
            targetCost,
            targetCustomer,
            targetDate: new Date(targetDate),
            unit,
          },
        },
      },
      handleSuccess: (data) => {
        setProjectUuid(data.id);
        setCurrentPage(projectIsRtl ? RTL_PAGES.length - 1 : PAGES.length - 1);
      },
      handleFinally: () => {
        setSubmitting(false);
      },
    });
  };

  const percentComplete = (currentPage / (PAGES.length - 1)) * 100;

  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState<boolean>(false);

  const getValidationSchema = () => {
    if (projectIsRtl) {
      if (currentPage === RTL_PAGES.length - 2) {
        return allRTLValidationsSchema;
      } else {
        return RTL_PAGES[currentPage].validationSchema;
      }
    } else {
      if (currentPage === PAGES.length - 2) {
        return allCustomDevelopmentValidationsSchema;
      } else {
        return PAGES[currentPage].validationSchema;
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialCustomerBriefFormValues}
      onSubmit={submitForm as any}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={getValidationSchema()}
    >
      <RtlIconMapContextProvider>
        <ConfirmationContextProvider>
          {isErrorAlertOpen && (
            <Alert
              content="Please correct form errors."
              severity="error"
              onClose={() => setIsErrorAlertOpen(false)}
            />
          )}
          <RtlDetector
            filteredRtlFormulas={filteredRtlFormulas}
            setProjectIsRtl={setProjectIsRtl}
          />
          <RtlLogicEngine
            rtlFormulas={rtlFormulas}
            setAllRtlModulesHaveValues={setAllRtlModulesHaveValues}
            setRtlFormulas={setRtlFormulas}
            setRtlFormulaUuid={setRtlFormulaUuid}
            setFilteredRtlFormulas={setFilteredRtlFormulas}
          />
          <ProgressTracker percentage={percentComplete} />
          <div
            className={`flex flex-col sm:grid sm:grid-cols-12 gap-6 mx-6 sm:mx-[60px] max-w-[1440px] ${
              currentPageIsRtlConfirmation ? '' : 'pb-[144px]'
            }`}
          >
            {!isMobile && !currentPageIsRtlConfirmation && (
              <div className="-ml-[60px] col-start-1 col-span-3">
                <ProjectInfoPane isRtl={projectIsRtl} />
              </div>
            )}
            <div
              className={
                currentPageIsRtlConfirmation
                  ? `col-start-1 col-span-12`
                  : `col-start-4 col-span-9`
              }
            >
              {projectIsRtl
                ? RTL_PAGES[currentPage].component
                : PAGES[currentPage].component}
            </div>
            {!currentPageIsRtlConfirmation && (
              <div className="col-start-4 col-span-6 mt-9">
                <FormNavigation
                  currentPage={currentPage}
                  pages={projectIsRtl ? RTL_PAGES : PAGES}
                  setCurrentPage={setCurrentPage}
                  setIsErrorAlertOpen={setIsErrorAlertOpen}
                  allRtlModulesHaveValues={allRtlModulesHaveValues}
                />
              </div>
            )}
          </div>
        </ConfirmationContextProvider>
      </RtlIconMapContextProvider>
    </Formik>
  );
};
