// Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import { Button, Page } from 'features/ready-to-launch/ui';
// Assets
import products3 from './images/icons/products3.svg';
// Utils
import { useAnalytics } from 'hooks';
import { clearLocalStorage } from './utils';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { FOOTER } from './constants';
import { ROUTES } from 'features/navigation';

const BUTTON_TEXT = 'Back to Product Builder';

type PageType = 'confirmation' | 'cancellation';

const MESSAGE_CONTENT = {
  confirmation: {
    header: 'Order placed!',
    message: [
      'You will receive an email receipt. Orders typically take 15 business days to process.',
      <>
        <br />
        <br />
      </>,
      'We’ll send you a shipping notification via email as soon as your sample ships.',
    ],
  },
  cancellation: {
    header: 'Order canceled',
    message: 'Continue to shop around and checkout when you’re ready',
  },
};

export const ConfirmationPage: React.FC = () => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const [pageType, setPageType] = useState<PageType>('confirmation');

  const buildOrderRequest = async (
    type: 'success' | 'cancel',
    method: 'PATCH' | 'DELETE',
    sessionId: string,
    orderId: string
  ) => {
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_R_AND_D_API_BASE_URL}/api/v1/orders/${type}?session_id=${sessionId}&order_id=${orderId}`,
        {
          method,
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        }
      );

      if (!apiResponse.ok) {
        const parsedResponse = await apiResponse.json();

        throw new Error(
          parsedResponse.exception ||
            parsedResponse.errorMessage ||
            'An unexpected error has occurred'
        );
      }
    } catch (error) {
      Rollbar.error(error);
    }
  };

  useEffect(() => {
    const createOrder = (sessionId: string, orderId: string) =>
      buildOrderRequest('success', 'PATCH', sessionId, orderId);

    const cancelOrder = (sessionId: string, orderId: string) =>
      buildOrderRequest('cancel', 'DELETE', sessionId, orderId);

    const redirectToLandingPage = () =>
      navigate(ROUTES.MASS_CUSTOMIZATION.root.route);

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    const canceled = query.get('canceled');
    const success = query.get('success');
    const sessionId = query.get('session_id');
    const orderId = query.get('order_id');

    if (success) {
      setPageType('confirmation');
      clearLocalStorage();
      sessionId && orderId
        ? createOrder(sessionId, orderId)
        : redirectToLandingPage();
    } else if (canceled) {
      setPageType('cancellation');
      sessionId && orderId
        ? cancelOrder(sessionId, orderId)
        : redirectToLandingPage();
    } else {
      redirectToLandingPage();
    }
  }, [navigate]);

  const renderEmailUs = () => {
    return (
      <div className="font-display mt-6">
        Questions?{'   '}
        <a
          className="underline"
          href={`mailto:${FOOTER.EMAIL}?subject=Help with Prime Matter Labs Ready To Launch`}
        >
          Email Us
        </a>
      </div>
    );
  };

  const handleButtonClick = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCConfirmation,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.backToProductBuilder,
      nonInteraction: true,
    });
    navigate(ROUTES.MASS_CUSTOMIZATION.root.route);
  };

  return (
    <Page>
      <div className="flex flex-col mt-16 mb-14 items-center">
        <img height="72" width="72" src={products3} alt="products" />
        <h3 className="font-headline text-5xl mt-6 mb-3">
          {MESSAGE_CONTENT[pageType].header}
        </h3>
        <h6 className="font-display text-xl">
          {MESSAGE_CONTENT[pageType].message}
        </h6>
      </div>
      <div className="h-screen flex flex-col items-center bg-lightBlue">
        <div className="-mt-6">
          <Button variant="md" handleAction={handleButtonClick}>
            {BUTTON_TEXT}
          </Button>
        </div>
        {renderEmailUs()}
      </div>
    </Page>
  );
};
