// Libraries
import React, { useState } from 'react';
// Components
import { Modal, Button, IncrementDecrement } from 'features/ready-to-launch/ui';
import products from 'features/ready-to-launch/products/products.util';
// Assets
import removeCircle from 'features/ready-to-launch/images/icons/removeCircle.svg';
// Utils
import { useAnalytics, useMobile } from 'hooks';
import { CartItem } from 'features/ready-to-launch/types';
import { removeItemFromLocalStorage } from 'features/ready-to-launch/utils/local-storage.util';
import { determineCongratsScreenTitle } from 'features/ready-to-launch/utils/utils';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { SAMPLE_PRICE } from 'features/ready-to-launch/constants';

const MODAL = {
  buttonText: 'No, keep sample',
  confirmationText: 'Yes, delete sample',
  title: 'Delete your sample?',
  text: 'This action will permanently delete this sample’s information.',
};
interface ShoppingCartItemProps {
  item: CartItem;
  idx: number;
}

export const ShoppingCartItem: React.FC<ShoppingCartItemProps> = ({
  item: { name, productBase, extras, goals, itemQuantity, productCategory },
  idx,
}) => {
  const analytics = useAnalytics();
  const { isMobile } = useMobile();
  const [isSpeedBumpOpen, setIsSpeedBumpOpen] = useState(false);
  const numCustomizations = extras.length + goals.length;
  const handleRemoveItem = () => {
    removeItemFromLocalStorage(idx);
    setIsSpeedBumpOpen(false);
  };

  const product = products[productCategory];
  const itemImage = product.productBases.find(
    (prodBase: any) => prodBase.title === productBase
  ).imageSrc;

  const renderModal = () => {
    return (
      <Modal
        isSpeedbump
        hasShadow={false}
        title={MODAL.title}
        onClose={() => setIsSpeedBumpOpen(false)}
      >
        <p className="text-left text-xl">{MODAL.text}</p>
        <div className="flex sm:flex-row flex-col justify-end mt-10">
          <span
            role="button"
            className="self-center flex sm:text-base text-xl sm:mt-0 mt-5 sm:order-first order-last underline items-center sm:mr-8 cursor-pointer"
            onClick={handleRemoveItem}
          >
            {MODAL.confirmationText}
          </span>
          <Button
            handleAction={() => setIsSpeedBumpOpen(false)}
            bgColor="black"
            textColor="white"
            variant="md"
          >
            {MODAL.buttonText}
          </Button>
        </div>
      </Modal>
    );
  };

  const handleRemoveCircleClick = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCShoppingCart,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.removeCircle,
      nonInteraction: true,
    });
    setIsSpeedBumpOpen(true);
  };

  if (isMobile) {
    return (
      <>
        {isSpeedBumpOpen && renderModal()}
        <div className="flex flex-col relative border mb-5 font-display">
          <div className="flex justify-between">
            <div className="bg-white flex flex-grow px-5">
              <img
                className="self-center h-16 w-16"
                src={itemImage}
                alt="product placeholder"
              />
              <div className="self-center ml-4 text-left text-xl">
                {name} {determineCongratsScreenTitle(product, productBase)}
              </div>
            </div>
            <div className="flex flex-col px-5 py-4 justify-center bg-lightestGreen">
              <div className="text-2xl bg-lightestGreen">{`$${SAMPLE_PRICE}`}</div>
              <div className="font-mono font-medium text-sm text-gray text-left">
                EA.
              </div>
            </div>
          </div>

          <div className="flex bg-black justify-between text-white p-5">
            <div className="flex flex-col justify-between">
              <div className="text-base underline">
                {`${numCustomizations} customizations`}
              </div>
              <div className="text-base text-left">2 oz sample</div>
            </div>
            <div className="bg-white">
              <IncrementDecrement idx={idx} initialValue={itemQuantity} />
            </div>
          </div>
          <img
            style={{ top: '-12px', right: '-12px' }}
            className="absolute cursor-pointer z-20"
            src={removeCircle}
            alt="remove circle"
            onClick={handleRemoveCircleClick}
          />
        </div>
      </>
    );
  }

  return (
    <>
      {isSpeedBumpOpen && renderModal()}
      <div className="flex relative justify-between bg-white border mb-5 font-display">
        <div className="flex pl-4 bg-white w-full">
          <img
            className="self-center h-16 w-16"
            src={itemImage}
            alt="product placeholder"
          />
          <div className="flex flex-col ml-4 justify-center text-left">
            <div className="text-xl">
              {name} {determineCongratsScreenTitle(product, productBase)}
            </div>
            <div className="flex">
              <div className="text-base text-gray underline">
                {`${numCustomizations} customizations`}
              </div>
              <span className="ml-4 text-base text-gray">2 oz sample</span>
            </div>
          </div>
          <div className="my-7 ml-auto pr-5">
            <IncrementDecrement idx={idx} initialValue={itemQuantity} />
          </div>
        </div>
        <div className="flex flex-col px-5 py-4 justify-center bg-lightestGreen">
          <div className="text-3xl bg-lightestGreen">{`$${SAMPLE_PRICE}`}</div>
          <div className="font-mono font-medium text-sm text-gray text-left">
            EA.
          </div>
        </div>
        <img
          style={{ top: '-12px', right: '-12px' }}
          className="absolute cursor-pointer z-20"
          src={removeCircle}
          alt="remove circle"
          onClick={handleRemoveCircleClick}
        />
      </div>
    </>
  );
};
