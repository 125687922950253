// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton } from 'features/ui';

interface IBriefRadioButton {
  inputName: string;
  title: string;
}

export const BriefRadioButton: React.FC<IBriefRadioButton> = ({
  inputName,
  title,
}) => {
  const { setFieldValue } = useFormikContext();
  return (
    <div
      className="flex items-center sm:p-7 p-5 bg-white border cursor-pointer min-w-max"
      onClick={() => setFieldValue(inputName, title)}
    >
      <RadioButton inputName={inputName} title={title} />
      <div className="ml-6">{title}</div>
    </div>
  );
};
