import {
  BRIEF_FORM_KEYS,
  PRODUCT_TYPES,
  SPF_VALUES,
  STRUCTURES,
  YES_NO_CHOICE,
} from '../../brief.constants';

export const SUN_CARE = {
  [BRIEF_FORM_KEYS.PRODUCT_TYPE]: {
    MINERAL_SUNSCREEN: PRODUCT_TYPES.MINERAL_SUNSCREEN,
    CHEMICAL_SUNSCREEN: PRODUCT_TYPES.CHEMICAL_SUNSCREEN,
    HYBRID_SUNSCREEN: PRODUCT_TYPES.HYBRID_SUNSCREEN,
  },
  [BRIEF_FORM_KEYS.STRUCTURE]: {
    AEROSOL: STRUCTURES.AEROSOL,
    CONTINUOUS_SPRAY: STRUCTURES.CONTINUOUS_SPRAY,
    CONTINUOUS_SPRAY_BOV: STRUCTURES.CONTINUOUS_SPRAY_BOV,
    CREAM: STRUCTURES.CREAM,
    GEL_CREAM: STRUCTURES.GEL_CREAM,
    GEL: STRUCTURES.GEL_CREAM,
    LIQUID: STRUCTURES.LIQUID,
    LOTION: STRUCTURES.LOTION,
    MOUSSE_FOAM: STRUCTURES.MOUSSE_FOAM,
    OIL: STRUCTURES.OIL,
    SERUM: STRUCTURES.SERUM,
    STICK: STRUCTURES.STICK,
  },
  [BRIEF_FORM_KEYS.SPF]: {
    SPF_30: SPF_VALUES.SPF_30,
    SPF_45: SPF_VALUES.SPF_45,
    SPF_50: SPF_VALUES.SPF_50,
    SPF_60: SPF_VALUES.SPF_60,
    SPF_85: SPF_VALUES.SPF_85,
  },
  [BRIEF_FORM_KEYS.TINT]: {
    YES: YES_NO_CHOICE.YES,
    NO: YES_NO_CHOICE.NO,
  },
};
