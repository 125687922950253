// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Assets
import checkMarkIcon from 'img/checkMark.svg';

interface CheckboxProps {
  inputName: string;
  checked?: boolean;
  title: string;
  // Sometimes the title is enough to be a value
  // when we need more than just the title (i.e. display vs actual value)
  // we can use value
  value?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  inputName,
  checked,
  title,
  value,
}) => {
  const { values } = useFormikContext<any>();
  const isChecked =
    values[inputName].includes(title) ||
    values[inputName].includes(value) ||
    checked;

  return (
    <div
      className={
        'h-8 w-8 border flex justify-items-center align-center ' +
        (isChecked ? 'bg-legacyGreen border-legacyGreen' : 'border-gray')
      }
    >
      {isChecked && <img src={checkMarkIcon} alt="checkmark" />}
    </div>
  );
};
