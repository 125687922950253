// Libraries
import React from 'react';
// Components
import { BriefTextField, FormLayout } from '../layout';
import { TipBox } from '../layout';
// Utils
import { BRIEF_FORM_INPUTS } from '../../constants';
// Constants
const TITLE = 'What are your ingredient requirements?';
const SUB_TITLE =
  'Share the ingredients you’d like us to include or exclude to support your product claims or benefits.';
const TIP_TEXT =
  'We will try our best to accommodate your needs and will let you know which ingredients we can or can’t support.';

const MUST_HAVE_INGREDIENTS_LABEL = 'MUST HAVE INGREDIENTS';
const MUST_EXCLUDE_INGREDIENTS_LABEL = 'MUST EXCLUDE INGREDIENTS';

export const IngredientRequirements: React.FC = () => {
  return (
    <FormLayout
      subTitle={SUB_TITLE}
      tip={<TipBox tipText={TIP_TEXT} />}
      title={TITLE}
    >
      <div className="mx-5 sm:ml-0 mr-5 grid grid-cols-2 sm:grid-cols-9 gap-x-6">
        <div className="col-span-2 sm:col-start-2 sm:col-span-9 flex flex-col">
          <div className="w-full mb-6">
            <BriefTextField
              optional
              label={MUST_HAVE_INGREDIENTS_LABEL}
              formikKey={BRIEF_FORM_INPUTS.mustHaveIngredients}
            />
          </div>
          <div className="w-full">
            <BriefTextField
              optional
              label={MUST_EXCLUDE_INGREDIENTS_LABEL}
              formikKey={BRIEF_FORM_INPUTS.mustExcludeIngredients}
            />
          </div>
        </div>
      </div>
    </FormLayout>
  );
};
