// Libraries
import React from 'react';
// Assets
import spinner from '../images/icons/spinner.svg';

interface IButton {
  autoFocus?: boolean;
  border?: boolean;
  children: string;
  disabled?: boolean;
  fullWidth?: boolean;
  handleAction: (...args: any[]) => any;
  imageSrc?: string;
  isLoading?: boolean;
  textColor?: 'black' | 'gray' | 'white';
  bgColor?: 'black' | 'blue' | 'gray' | 'white';
  variant: 'lg' | 'md';
}

export const Button: React.FC<IButton> = ({
  autoFocus,
  border,
  bgColor,
  children,
  disabled,
  fullWidth,
  handleAction,
  imageSrc,
  isLoading,
  textColor,
  variant,
}) => {
  const BG_COLOR_MAP = {
    black: 'bg-black',
    blue: 'bg-blue-60',
    gray: 'bg-gray',
    white: 'bg-white',
  };

  const TEXT_COLOR_MAP = {
    black: 'text-black',
    gray: 'text-gray',
    white: 'text-white',
  };

  const VARIANT_PADDING_MAP = {
    lg: 'px-5 py-4',
    md: 'px-5 py-3',
  };

  const renderImageOrSpinner = () => {
    if (imageSrc) {
      return (
        <>
          <img
            className={`ml-3 animate-spin ${isLoading ? '' : 'hidden'}`}
            src={spinner}
            alt="button-icon"
          />
          <img
            className={`ml-3 ${isLoading ? 'hidden' : ''}`}
            src={imageSrc}
            alt="button-icon"
          />
        </>
      );
    }

    return null;
  };
  return (
    <button
      autoFocus={autoFocus}
      className={`${bgColor ? BG_COLOR_MAP[bgColor] : 'bg-blue-60'} ${
        textColor ? TEXT_COLOR_MAP[textColor] : 'text-white'
      } ${border ? 'border' : ''} font-display text-lg cursor-pointer ${
        VARIANT_PADDING_MAP[variant]
      } ${
        fullWidth ? 'w-full' : ''
      } flex justify-center sm:justify-between items-center disabled:bg-gray sm:w-max hover:bg-darkGray hover:text-white
    `}
      disabled={disabled}
      onClick={handleAction}
    >
      {children} {renderImageOrSpinner()}
    </button>
  );
};

// Example usage
//  <Button
//    bgColor="black"
//    disabled
//    handleAction={() => console.log('hello world!')}
//    variant="lg"
//  >Next</Button>;
