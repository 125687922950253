// Libraries
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// Assets
import customDevelopment from '../../assets/customDevelopment.png';
import customDevelopmentMobile from '../../assets/customDevelopmentMobile.png';
// Utils
import { useAnalytics, useMobile } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

export const CustomDevelopment: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const analytics = useAnalytics()!;
  const { isMobile } = useMobile();

  const handleGetStartedClick = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBSplash,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.getStarted,
      nonInteraction: true,
    });
    loginWithRedirect({ screen_hint: 'signup' });
  };

  return (
    <div className="bg-blue-60 h-full sm:min-h-screen sm:h-auto pb-14 sm:pb-0 text-white text-left">
      <div className="flex flex-col sm:max-w-[1440px] sm:flex-row sm:py-0">
        <img
          className="h-[200px] w-[375px]  sm:h-full sm:w-1/3 object-cover sm:order-2"
          src={isMobile ? customDevelopmentMobile : customDevelopment}
          alt="vials"
        />
        <div className="mx-5 mt-14 sm:mt-[200px] sm:ml-20 sm:mr-[264px] sm:order-1">
          <p className="font-headline text-xl sm:text-[3rem] leading-9 sm:leading-[48px] mb-3">
            Prime Matter Labs Custom Development
          </p>
          <p className="font-display text-[1.75rem] leading-8 sm:text-[28px] mb-8">
            Work with our team to create a custom beauty or personal care
            product with unique goals.
          </p>
          <p className="font-display text-lg">
            Start by creating an account. Then tell us your product vision and
            needs. We will align these to our capabilities and expertise to
            develop a plan that meets your timeline and budget.
          </p>
          <button
            onClick={handleGetStartedClick}
            className="my-10 sm:mt-10 sm:mb-6 px-5 py-4 border border-white"
          >
            Get started
          </button>
          <p>
            Already have an account?{' '}
            <button onClick={() => loginWithRedirect()}>
              <span className="underline">Login</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};
