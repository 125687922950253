// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { FormLayout } from '../layout';
import { FormSelectList } from 'features/ui';
// Utils
import { BriefFormValues } from '../../types';
// Constants
import { BRIEF_FORM_INPUTS, COUNTRIES } from '../../constants';

const TITLE = 'What are the intended markets for this project?';
const LABEL = 'SELECT ALL THAT APPLY';

export const IntendedMarkets: React.FC = () => {
  const { errors } = useFormikContext<BriefFormValues>();

  return (
    <FormLayout
      title={TITLE}
      isNextButtonDisabled={BRIEF_FORM_INPUTS.intendedMarkets in errors}
    >
      <div className="grid mx-5 grid-cols-2 sm:mx-0 sm:grid-cols-12 gap-x-6">
        <label className="col-span-2 sm:col-start-2 sm:col-span-12 font-mono text-xs font-semibold mb-3">
          {LABEL.toLocaleUpperCase()}
        </label>
      </div>
      <div className="grid mx-5 grid-cols-2 sm:mx-0 sm:grid-cols-12">
        <div
          className="sm:h-[750px] col-span-2 sm:col-start-2 sm:col-span-8
          bg-white overflow-y-scroll"
        >
          <FormSelectList
            type="checkbox"
            inputName={BRIEF_FORM_INPUTS.intendedMarkets}
            items={COUNTRIES}
          />
        </div>
      </div>
    </FormLayout>
  );
};
