// Libraries
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
// Utils
import { IApiData, ProjectAttributes } from 'api';
import { useAnalytics, useMobile } from 'hooks';
import { TaskStatus } from '../types';
// Constants
import { MILESTONES, MILESTONE_TITLES } from '../constants';
import { AnalyticsConstants } from 'features/analytics';

interface IProjectTitleAndTags {
  isDisabled?: boolean;
  large?: boolean;
  project: IApiData<ProjectAttributes>;
}

export const ProjectTitleAndTags: React.FC<IProjectTitleAndTags> = ({
  isDisabled,
  large,
  project,
}) => {
  const analytics = useAnalytics!();
  const { isMobile } = useMobile();

  const renderTags = (project: IApiData<ProjectAttributes>) => {
    const tasks = project?.parsedIncluded?.tasks;
    if (!tasks) return null;
    let tagToRender = null;

    if (tasks) {
      for (const milestone of MILESTONES) {
        const isDoneOrInProgress = milestone.tasks.some((task) => {
          const correspondingTask = tasks.find(
            (t: IApiData<any>) => t.attributes.mondayColumnId === task.columnId
          );
          if (!correspondingTask) return false;
          return (
            (correspondingTask &&
              correspondingTask.attributes.status === TaskStatus.done) ||
            correspondingTask.attributes.status === TaskStatus.inProgress
          );
        });
        if (isDoneOrInProgress) {
          tagToRender = milestone;
        }
      }
    }

    return (
      <div className="flex">
        {isDisabled && (
          <div className="font-medium bg-lightestGray mr-2 uppercase px-3 py-[6px] rounded-full font-mono text-xs">
            ON HOLD
          </div>
        )}
        {!isDisabled && (
          <div
            style={{ backgroundColor: tagToRender?.tagColor }}
            className={`font-medium mr-2 uppercase px-3 py-[6px] rounded-full font-mono text-xs ${
              tagToRender?.title === MILESTONE_TITLES.PRODUCTION
                ? 'text-legacyGreen'
                : 'text-darkGray'
            }`}
          >
            {tagToRender?.title}
          </div>
        )}
        {project.parsedIncluded.brief.attributes.size &&
          project.parsedIncluded.brief.attributes.unit && (
            <div
              id="size"
              className={`font-medium uppercase px-3 py-[6px] rounded-full font-mono text-xs ${
                isDisabled
                  ? 'bg-lighterGray text-lightGray'
                  : 'bg-legacyPeach text-darkGray'
              }`}
            >
              {project.parsedIncluded.brief.attributes.size}{' '}
              {project.parsedIncluded.brief.attributes.unit}
            </div>
          )}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start mr-6">
      <div
        className={`font-inter text-lightGray ${large ? 'text-sm' : 'text-xs'}`}
      >
        {`Formula #:  ${project.attributes.latestFormulaNumber || 'N/A'} `}
      </div>
      <div className="my-2 flex items-center w-full">
        <h3
          className={`mr-2 font-inter text-left sm:whitespace-nowrap sm:overflow-hidden sm:text-ellipsis ${
            large ? 'text-[28px]' : 'text-lg'
          } ${isDisabled ? 'text-lightGray' : 'text-darkGray'}`}
        >
          {project.attributes.name}
        </h3>
        {!isMobile && (
          <Tooltip
            onMouseEnter={() => {
              analytics?.trackEvent({
                eventCategory: AnalyticsConstants.EVENT_CATEGORIES.CBDashboard,
                eventAction: AnalyticsConstants.EVENT_ACTIONS.hover,
                eventLabel: `Project Title Tooltip`,
              });
            }}
            title={
              <div>
                <p>
                  Target date:{' '}
                  {new Date(
                    project.parsedIncluded.brief.attributes.targetDate
                  ).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </p>
                <p>
                  Created:{' '}
                  {new Date(
                    project.attributes.createdAt as string
                  ).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                  })}
                </p>
              </div>
            }
          >
            <InfoOutlined />
          </Tooltip>
        )}
      </div>
      {renderTags(project)}
    </div>
  );
};
