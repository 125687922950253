// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Utils
import { BriefFormValues } from 'features/brief/customer-brief/types';

interface IBriefTextField {
  id?: string;
  formikKey: string;
  formikKeyToNullify?: string;
  helperText?: string;
  label: string;
  prefix?: string;
  optional?: boolean;
}

export const BriefTextField: React.FC<IBriefTextField> = ({
  id,
  formikKey,
  formikKeyToNullify,
  helperText,
  label,
  prefix,
  optional,
}) => {
  const { errors, getFieldProps, setFieldValue, touched } = useFormikContext<
    BriefFormValues
  >();
  const hasErrors = formikKey in errors;
  const isTouched = formikKey in touched;

  return (
    <div className="flex flex-col">
      <label className="font-mono text-xs font-semibold mb-3" htmlFor={id}>
        {label.toLocaleUpperCase()}{' '}
        {optional ? <span className="text-gray">(OPTIONAL)</span> : null}
      </label>
      {prefix ? (
        <div className="flex items-center pl-4 border border-black bg-white focus-within:border-2 focus-within:border-blue-60 focus-within:rounded-sm">
          <span className="text-gray text-lg">{prefix}</span>
          <input
            {...getFieldProps(formikKey)}
            id={id}
            className="border-none ml-2 font-display text-lg py-4 focus:outline-none w-full"
            onChange={(e) => {
              if (formikKeyToNullify) {
                setFieldValue(formikKeyToNullify, '');
                setFieldValue(formikKey, e.target.value);
              } else {
                getFieldProps(formikKey).onChange(e);
              }
            }}
          />
        </div>
      ) : (
        <input
          className="pl-4 pr-6 border border-black font-display text-lg py-4"
          id={id}
          {...getFieldProps(formikKey)}
        />
      )}
      {helperText && (
        <div className="text-xs font-display text-gray mt-1">{helperText}</div>
      )}
      {isTouched && hasErrors && (
        <div className="text-red-40 text-xs font-display mt-1">
          {errors[formikKey as keyof BriefFormValues] as string}
        </div>
      )}
    </div>
  );
};
