// Libraries
import React, { useRef, useState } from 'react';
// Assets
import { ReactComponent as Close } from 'features/ready-to-launch/images/icons/close.svg';
// Utils
import { useMobile } from 'hooks';

// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { WAITLIST_TAG_NAMES } from 'features/ready-to-launch/constants';
import { ROUTES } from 'features/navigation';

const { PRIVACY_POLICY } = ROUTES;

interface WaitListModalProps {
  isOpen: boolean;
  handleClose: () => void;
  waitlistTag?: string;
}

export const WaitListModal: React.FC<WaitListModalProps> = ({
  handleClose,
  isOpen,
  waitlistTag,
}) => {
  const { isMobile } = useMobile();
  const formRef = useRef(null);
  const [email, setEmail] = useState<string>('');

  if (!isOpen) {
    return null;
  }

  const handleOutsideModalClick = (event: any) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  const handleLinkClick = (route: string) => {
    const win = window.open(route, '_blank');
    win?.focus();
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    gtag_report_conversion(
      AnalyticConstants.GOOGLE_ADS_EVENT_IDS.RTL_WAITLIST_SIGNUP_EVENT
    );
    formRef.current && (formRef.current as any).submit();
    handleClose();
  };

  return (
    <div
      onClick={handleOutsideModalClick}
      className="overscroll-none flex items-center justify-center fixed z-40 bg-blue-60/60 w-full h-full top-0 left-0"
    >
      <div
        className="relative bg-white sm:p-6 p-4 h-screen sm:h-auto"
        style={{ maxWidth: isMobile ? '' : '45%' }}
      >
        <Close
          onClick={handleClose}
          className="absolute sm:top-6 sm:right-6 top-2 right-2 cursor-pointer"
        />
        <div>
          <h4 className="font-headline text-blue-60" style={{ fontSize: 42 }}>
            Join the waitlist
          </h4>
          <p className="font-display text-gray text-lg">
            We will let you know once the product you’ve chosen is available.
          </p>
        </div>
        {/* Begin Mailchimp Signup Form */}
        <div id="mc_embed_signup">
          <form
            ref={formRef}
            action="https://primematterlabs.us5.list-manage.com/subscribe/post?u=343edd5d4fa3cb7522efdb468&amp;id=d0ba85d839"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div className="mc-field-group hidden">
              <label htmlFor="mce-group[382977]">RTL Product Wait List </label>
              <select
                name="group[382977]"
                className="REQ_CSS"
                id="mce-group[382977]"
              >
                <option value=""></option>
                <option
                  value="256"
                  selected={
                    waitlistTag === WAITLIST_TAG_NAMES.sunCareMineralSunscreen
                  }
                >
                  {WAITLIST_TAG_NAMES.sunCareMineralSunscreen}
                </option>
                <option
                  value="512"
                  selected={
                    waitlistTag === WAITLIST_TAG_NAMES.hairCareHairSerum
                  }
                >
                  {WAITLIST_TAG_NAMES.hairCareHairSerum}
                </option>
                <option
                  value="1024"
                  selected={waitlistTag === WAITLIST_TAG_NAMES.bodyCareBodyWash}
                >
                  {WAITLIST_TAG_NAMES.bodyCareBodyWash}
                </option>
              </select>
            </div>
            <div id="mc_embed_signup_scroll">
              <div className="relative sm:mb-8 mb-4 mt-6">
                <label
                  htmlFor="mce-EMAIL"
                  className="text-gray font-mono text-sm absolute top-2 left-1"
                >
                  EMAIL
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  value={email}
                  onChange={handleEmailChange}
                  className="w-full pt-7 pb-3 pl-1 font-display border-b border-gray"
                  id="mce-EMAIL"
                />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: 'none' }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: 'none' }}
                ></div>
              </div>
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_343edd5d4fa3cb7522efdb468_d0ba85d839"
                  tabIndex={-1}
                  value=""
                />
              </div>
              <div className="flex flex-col-reverse sm:flex-row justify-between items-center sm:mt-0 mt-10">
                <p className="text-gray font-display text-sm sm:mt-0 mt-5">
                  By submitting your email, you agree to receive Beauty Brief,
                  our monthly newsletter with the latest updates from Prime
                  Matter Labs. You may opt-out anytime. To learn more, read our{' '}
                  <button
                    className="underline"
                    onClick={() => handleLinkClick(PRIVACY_POLICY.route)}
                  >
                    {PRIVACY_POLICY.text}
                  </button>
                  {'.'}
                </p>
                <button
                  disabled={!email}
                  onClick={handleFormSubmit}
                  className="bg-blue-60 text-white py-4 px-11 font-mono font-semibold text-sm disabled:bg-gray"
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* End mc_embed_signup */}
      </div>
    </div>
  );
};
