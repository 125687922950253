// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { FormLayout } from '../layout';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';

const TITLE = 'How would you describe your target audience?';

export const TargetAudience: React.FC = () => {
  const { getFieldProps } = useFormikContext<any>();
  const { targetAudience } = BRIEF_FORM_INPUTS;

  return (
    <FormLayout title={TITLE}>
      <div className="grid grid-cols-2 mx-5 sm:mx-0 sm:grid-cols-12 gap-x-6">
        <textarea
          rows={6}
          className="col-span-2 sm:col-start-2 sm:col-span-8 border border-black font-display text-lg p-6"
          {...getFieldProps(targetAudience)}
        />
      </div>
    </FormLayout>
  );
};
