// Libraries
import React, { useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import { Formik } from 'formik';
// Components
import products from './products/products.util';
import { ConfirmationPage } from './confirmation-page.component';
import { LandingPage } from './landing-page';
import { NotFound } from './not-found.component';
import { ProductCategory } from './product-category';
import { Wizard } from './wizard.component';
// Utils
import { ProductCategoryType } from './types';
import {
  ScrollToTop,
  saveToLocalStorage,
  checkCartVersionIntegrity,
} from './utils';
// Constants
import { ROUTES } from 'features/navigation';

export const ReadyToLaunchRoutes = () => {
  const location = useLocation();
  const productCategory = (location?.state as {
    productCategory?: ProductCategoryType;
  })?.productCategory;
  const product = productCategory ? products[productCategory] : undefined;

  useEffect(() => {
    // If user returns to site with an outdated cart
    checkCartVersionIntegrity();
  }, []);

  return (
    // We need a default set of initial values in order for Formik to render properly
    <Formik
      initialValues={
        product?.initialFormValues || products.skinCare.initialFormValues
      }
      validationSchema={product?.validationSchema}
      onSubmit={saveToLocalStorage}
      enableReinitialize
    >
      <>
        <ScrollToTop />
        <Routes>
          <Route
            path={ROUTES.MASS_CUSTOMIZATION.productCategory.path}
            element={<ProductCategory />}
          />
          <Route
            path={ROUTES.MASS_CUSTOMIZATION.confirmation.path}
            element={<ConfirmationPage />}
          />
          <Route
            path={ROUTES.MASS_CUSTOMIZATION.wizard.path}
            element={<Wizard productCategory={productCategory} />}
          />
          <Route
            path={ROUTES.MASS_CUSTOMIZATION.root.path}
            element={<LandingPage />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    </Formik>
  );
};
