// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Constants
import { INPUT_NAMES } from '../constants';
import { handleKeyDown } from 'features/constants';
const COMING_SOON = 'This option is coming soon!';

interface IBigChoiceCard {
  field: any;
  details: string;
  highlightText?: string;
  imageSrc: string;
  isWaitlisted?: boolean;
  handleClick: () => void;
  title: string;
  titleDetail?: string;
}

const BigChoiceCard: React.FC<IBigChoiceCard> = ({
  details,
  highlightText,
  imageSrc,
  isWaitlisted,
  handleClick,
  title,
  titleDetail,
}) => {
  const { setFieldValue } = useFormikContext();
  const cardClickHandler = () => {
    handleClick();
    setFieldValue(INPUT_NAMES.productBase, title);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={({ key }) => handleKeyDown(key, cardClickHandler)}
      onClick={isWaitlisted ? () => {} : cardClickHandler}
      className={`relative flex flex-col h-64 border border-solid shadow-choiceCard ${
        isWaitlisted
          ? 'hover:border-opacity:1 cursor-auto bg-lightestGray border-gray'
          : 'hover:border-opacity-0 hover:shadow-choiceCardHover bg-white hover:bg-legacyGreen cursor-pointer border-black'
      }`}
    >
      {highlightText && (
        <div className="absolute flex items-center top-0 right-0 p-4 bg-lightestGreen h-10">
          <h6 className="text-legacyGreen font-mono text-sm font-semibold">
            {highlightText}
          </h6>
        </div>
      )}
      <div className="flex justify-between px-7 pt-6">
        <div className="flex flex-col">
          <h5
            className={`text-[1.875rem] font-headline mb-2 whitespace-pre-wrap pr-8 ${
              isWaitlisted && 'text-gray'
            }`}
          >
            {title}
          </h5>
          {titleDetail && (
            <span className="font-display text-sm text-gray">
              {titleDetail}
            </span>
          )}
        </div>
        <div className="flex-shrink-0">
          <img height="76" width="76" src={imageSrc} alt={title} />
        </div>
      </div>
      <div className="mt-4 px-7">
        <span
          className={`font-display text-base ${isWaitlisted && 'text-gray'}`}
        >
          {isWaitlisted ? COMING_SOON : details}
        </span>
      </div>
      {isWaitlisted && (
        <div
          onClick={cardClickHandler}
          className="flex justify-between items-center mt-auto w-fit-content px-4 py-2 mb-7 ml-7 font-display text-white bg-blue-60 cursor-pointer"
        >
          <span>Join waitlist</span>
        </div>
      )}
    </div>
  );
};

export default BigChoiceCard;
