// Libraries
import React, { useContext } from 'react';
import _pick from 'lodash/pick';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
// Components
import { MobileContext } from 'context';
import { Select } from './select.component';

type BaseItemProps = {
  label: string;
  href: string;
  value: string;
};

type NavItemProps = BaseItemProps & {
  active?: boolean;
  leadingIcon?: React.ReactNode;
  subItems?: SubItemProps[];
  trailingIcon?: React.ReactNode;
};

type SubItemProps = BaseItemProps;

interface PageNavProps {
  activeItem: NavItemProps;
  currentPage: number;
  navItems: NavItemProps[];
  onChange: (item: NavItemProps) => void;
  onClick: (item: NavItemProps) => void;
}

const NavItem: React.FC<NavItemProps> = ({
  active,
  label,
  leadingIcon,
  trailingIcon,
}) => {
  return (
    <div
      className={`flex items-center gap-2 p-2 rounded-[2px] hover:bg-blue-95 font-inter text-xs cursor-pointer ${
        active ? 'bg-blue-95' : ''
      }`}
    >
      {leadingIcon && <div className="w-5 h-5">{leadingIcon}</div>}
      <span className="flex-1 font-semibold">{label}</span>
      <div className="self-end w-5 h-5">{trailingIcon}</div>
    </div>
  );
};

export const PageNav: React.FC<PageNavProps> = ({
  activeItem,
  navItems,
  onChange,
  onClick,
}) => {
  const { isMobile } = useContext(MobileContext);
  const renderSubItems = (
    subItems: {
      label: string;
      href: string;
      value: string;
    }[]
  ) => {
    return subItems.map((subItem, index) => {
      return (
        <div
          onClick={(e) => e.stopPropagation()}
          key={`sub-item-${index}`}
          className="flex items-center font-inter text-xs cursor-pointer"
        >
          <a href={subItem.href}>
            <span className="flex-1 font-semibold">{subItem.label}</span>
          </a>
        </div>
      );
    });
  };

  const renderNavItems = (
    activeItem: NavItemProps,
    navItems: NavItemProps[]
  ) => {
    return navItems.map((navItem, index) => {
      const isActive = activeItem.label === navItem.label;
      return (
        <div onClick={() => onClick(navItem)} key={`nav-item-${index}`}>
          <NavItem
            active={isActive}
            href={navItem.href}
            leadingIcon={navItem.leadingIcon}
            label={navItem.label}
            trailingIcon={navItem.trailingIcon}
            value={navItem.value}
          />
          {isActive && navItem?.subItems && (
            <div className="flex flex-col gap-6 py-3 ml-12">
              {renderSubItems(navItem.subItems)}
            </div>
          )}
        </div>
      );
    });
  };
  return isMobile ? (
    <Select
      backgroundColor="bg-blue-90"
      height="h-11"
      options={navItems.map((navItem, index) => ({
        label: navItem.label || '',
        value: navItem.value,
      }))}
      onChange={onChange}
      trailingIcon={
        <ChevronDownIcon
          className="text-royal-50 cursor-pointer"
          height="20"
          width="20"
        />
      }
      value={_pick(activeItem, ['label', 'value'])}
    />
  ) : (
    <div className="w-[338px] sticky top-0 h-auto p-9">
      <div className="flex flex-col gap-2 text-grey-50">
        {renderNavItems(activeItem, navItems)}
      </div>
    </div>
  );
};
