// Libraries
import React from 'react';
// Components
import { FormSelectList } from 'features/ui';
import { FormCTA } from './form-CTA.component';
// Utils
import {
  FormSelectListItem,
  IMassCustomizationScreen,
} from 'features/ready-to-launch/types';
// Constants
import { INPUT_NAMES } from 'features/ready-to-launch/constants';

const CTA_TEXT = {
  title: 'Select as many as needed (Optional)',
  description:
    'Boost your product with additional extracts. These extracts carry no fragrance.',
};

export const ExtrasStep: React.FC<IMassCustomizationScreen> = ({ product }) => {
  const alphabetically = (
    { title: aTitle }: FormSelectListItem,
    { title: bTitle }: FormSelectListItem
  ): number => {
    const [a, b] = [aTitle.toLowerCase(), bTitle.toLowerCase()];

    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  /* This function finds the correct 'extra' description,
  if the same extract has mutliple descriptions, per product base */
  const parseExtras = () => {
    return product.extras.map((extra) => {
      if (typeof extra.subTitle === 'string') return extra;
      if (Object.keys(extra.subTitle).includes(product.title)) {
        return { ...extra, subTitle: extra.subTitle[product.title] };
      }
      return { ...extra, subTitle: extra.subTitle.default };
    });
  };

  const parsedExtras = parseExtras() as FormSelectListItem[];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3">
      <div className="mx-5 sm:mx-24 sm:col-span-3 mb-8 mt-10">
        <FormCTA
          stepName={INPUT_NAMES.extras}
          title={CTA_TEXT.title}
          description={CTA_TEXT.description}
        />
      </div>
      <div className="grid-cols-1 mx-5 sm:col-span-3 sm:mx-24 bg-white">
        <FormSelectList
          analyticsPrefix="MC"
          inputName={INPUT_NAMES.extras}
          items={parsedExtras.sort(alphabetically)}
          type="checkbox"
        />
      </div>
    </div>
  );
};
