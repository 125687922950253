// Libraries
import React from 'react';

interface IMobileProgressIndicator {
  currentStep: number;
  totalSteps: number;
  stepName: string;
}

export const MobileProgressIndicator: React.FC<IMobileProgressIndicator> = ({
  currentStep,
  totalSteps,
  stepName,
}) => {
  return (
    <div className="bg-blue-60 text-white font-mono font-medium text-sm pl-5 py-3">
      STEP {currentStep}/{totalSteps}: {stepName}
    </div>
  );
};
