// Libraries
import * as Yup from 'yup';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export enum cogsType {
  Range = 'Range',
  Specific = 'Specific',
}

const isEmpty = (arr: Array<Maybe<string | Option>>) => {
  return arr.every((val) => {
    if (typeof val === 'object') {
      return val.value === undefined || val.value === '';
    }

    return val === '' || val === undefined;
  });
};

export type InternalBriefFormValues = {
  [x in keyof typeof BRIEF_FORM_KEYS]?: any;
} & {
  [BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK_NOTE]: string;
  [BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK]: boolean;
  [BRIEF_FORM_KEYS.ANTI_FRIZZ]: boolean;
  [BRIEF_FORM_KEYS.ANTI_REDNESS]: boolean;
  [BRIEF_FORM_KEYS.APPROVAL_DATE]: string;
  [BRIEF_FORM_KEYS.BENCHMARKS]: Benchmark[];
  [BRIEF_FORM_KEYS.BLUE_LIGHT_PROTECTION]: boolean;
  [BRIEF_FORM_KEYS.BRIGHTENING]: boolean;
  [BRIEF_FORM_KEYS.BROAD_SPECTRUM_TEST]: boolean;
  [BRIEF_FORM_KEYS.CLOSURE]: Option;
  [BRIEF_FORM_KEYS.COA]: boolean;
  [BRIEF_FORM_KEYS.COGS_RANGE]: Option;
  [BRIEF_FORM_KEYS.COGS_TYPE]: string;
  [BRIEF_FORM_KEYS.COLOR_NOTE]: string;
  [BRIEF_FORM_KEYS.COMPANY]: Option;
  [BRIEF_FORM_KEYS.COMPATIBILITY_TEST]: boolean;
  [BRIEF_FORM_KEYS.CONDITIONING]: boolean;
  [BRIEF_FORM_KEYS.CONTACT]: Option;
  [BRIEF_FORM_KEYS.CONTAINER]: string;
  [BRIEF_FORM_KEYS.COUNTRIES]: string[];
  [BRIEF_FORM_KEYS.COUNTRY_COMPLIANCE_NOTE]: string;
  [BRIEF_FORM_KEYS.CURL_DEFINING]: boolean;
  [BRIEF_FORM_KEYS.DEEP_CONDITIONING]: boolean;
  [BRIEF_FORM_KEYS.DETANGLE]: boolean;
  [BRIEF_FORM_KEYS.DIRECTIONS_FOR_USE]: string;
  [BRIEF_FORM_KEYS.ESTIMATED_QUOTE_STANDARD]: boolean;
  [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_DARK_CIRCLES]: boolean;
  [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_LINE_CROWS_FEET]: boolean;
  [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_PUFFINESS]: boolean;
  [BRIEF_FORM_KEYS.FIRMING]: boolean;
  [BRIEF_FORM_KEYS.FRAGRANCE_COMMENT]: string;
  [BRIEF_FORM_KEYS.FRAGRANCE_TYPE]: string;
  [BRIEF_FORM_KEYS.GLUTEN_FREE_STATEMENT]: boolean;
  [BRIEF_FORM_KEYS.GLUTEN_FREE]: boolean;
  [BRIEF_FORM_KEYS.HAIR_SMOOTHING]: boolean;
  [BRIEF_FORM_KEYS.HARD_HOLD]: boolean;
  [BRIEF_FORM_KEYS.HRIPT_FOR_HYPOALLERGENIC_TEST]: boolean;
  [BRIEF_FORM_KEYS.HRIPT_TEST]: boolean;
  [BRIEF_FORM_KEYS.HRIPT_WITH_DERMATOLOGIST_TEST]: boolean;
  [BRIEF_FORM_KEYS.HYDRATION_LONG_TERM]: boolean;
  [BRIEF_FORM_KEYS.HYDRATION]: boolean;
  [BRIEF_FORM_KEYS.HYPOALLERGENIC]: boolean;
  [BRIEF_FORM_KEYS.IFRA_COMPLIANCE_STATEMENT]: boolean;
  [BRIEF_FORM_KEYS.INITIAL_DATE_OF_BRIEF]: string;
  [BRIEF_FORM_KEYS.LIGHT_HOLD]: boolean;
  [BRIEF_FORM_KEYS.MASTER_BOX]: string;
  [BRIEF_FORM_KEYS.MATERIAL]: Option;
  [BRIEF_FORM_KEYS.MATTIFYING]: boolean;
  [BRIEF_FORM_KEYS.MINIMUM_ORDER_QUANTITY]: string;
  [BRIEF_FORM_KEYS.MOISTURE]: boolean;
  [BRIEF_FORM_KEYS.MOISTURIZATION]: boolean;
  [BRIEF_FORM_KEYS.MUST_EXCLUDE_INGREDIENTS]: string;
  [BRIEF_FORM_KEYS.MUST_HAVE_INGREDIENTS]: string;
  [BRIEF_FORM_KEYS.NO_ANIMAL_DERIVED_INGREDIENTS_STATEMENT]: boolean;
  [BRIEF_FORM_KEYS.NO_ANIMAL_DERIVED_INGREDIENTS]: boolean;
  [BRIEF_FORM_KEYS.NO_ANIMAL_TESTED_INGREDIENTS_STATEMENT]: boolean;
  [BRIEF_FORM_KEYS.NO_ANIMAL_TESTING]: boolean;
  [BRIEF_FORM_KEYS.NON_OTC_CLAIM_COMMENTS]: string;
  [BRIEF_FORM_KEYS.OCCULAR_TEST]: boolean;
  [BRIEF_FORM_KEYS.OPACITY]: string;
  [BRIEF_FORM_KEYS.OTC_NOTE]: string;
  [BRIEF_FORM_KEYS.OTC]: string;
  [BRIEF_FORM_KEYS.OTHER_R_AND_D_DOCUMENTS]: string;
  [BRIEF_FORM_KEYS.PET_TEST]: boolean;
  [BRIEF_FORM_KEYS.PRELIMINARY_IL_PER_SUBMISSION_STANDARD]: boolean;
  [BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET]: string;
  [BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET_NOTE]: string;
  [BRIEF_FORM_KEYS.PRODUCT_CATEGORY]: Option;
  [BRIEF_FORM_KEYS.PRODUCT_DESCRIPTION]: string;
  [BRIEF_FORM_KEYS.PRODUCT_FUNCTION]: string;
  [BRIEF_FORM_KEYS.PRODUCT_OBJECTIVE]: string;
  [BRIEF_FORM_KEYS.PRODUCT_PURPOSE]: string;
  [BRIEF_FORM_KEYS.PRODUCT_TYPE]: Option;
  [BRIEF_FORM_KEYS.PRODUCTION_LOCATION]: string;
  [BRIEF_FORM_KEYS.PROJECT_NAME]: string;
  [BRIEF_FORM_KEYS.RADIANCE_GLOW]: boolean;
  [BRIEF_FORM_KEYS.RETAILER_STANDARDS]: string[];
  [BRIEF_FORM_KEYS.SAMPLE_QUANTITY]: Option;
  [BRIEF_FORM_KEYS.SAMPLE_RECIPIENTS]: SampleRecipient[];
  [BRIEF_FORM_KEYS.SELF_TANNER]: boolean;
  [BRIEF_FORM_KEYS.SHINE]: boolean;
  [BRIEF_FORM_KEYS.SIZE]: string;
  [BRIEF_FORM_KEYS.SKIN_SMOOTHING]: boolean;
  [BRIEF_FORM_KEYS.SOOTHING]: boolean;
  [BRIEF_FORM_KEYS.SPF_DETERMINATION_FDA_TEST]: boolean;
  [BRIEF_FORM_KEYS.SPF_DETERMINATION_ISO_TEST]: boolean;
  [BRIEF_FORM_KEYS.STABILITY_TEST]: boolean;
  [BRIEF_FORM_KEYS.STATEMENTS_NOTE]: string;
  [BRIEF_FORM_KEYS.STRENGTHENING]: boolean;
  [BRIEF_FORM_KEYS.STRUCTURE]: string;
  [BRIEF_FORM_KEYS.TARGET_COST]: string;
  [BRIEF_FORM_KEYS.TARGET_CUSTOMER]: string;
  [BRIEF_FORM_KEYS.TARGET_DATE]: string;
  [BRIEF_FORM_KEYS.TEST_HANDLING_RESPONSIBILITY]: string;
  [BRIEF_FORM_KEYS.TIGHTENING]: boolean;
  [BRIEF_FORM_KEYS.TINT]: string;
  [BRIEF_FORM_KEYS.UNIT]: string;
  [BRIEF_FORM_KEYS.VEGAN_INGREDIENTS_STATEMENT]: boolean;
  [BRIEF_FORM_KEYS.VEGAN]: boolean;
  [BRIEF_FORM_KEYS.WATER_ACTIVITY_TEST]: boolean;
};

export interface CustomerBriefFormValues extends InternalBriefFormValues {
  [BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET]: string;
  [BRIEF_FORM_KEYS.ADDITIONAL_COMMENTS]: string;
  [BRIEF_FORM_KEYS.SPF]: string;
  [BRIEF_FORM_KEYS.COLOR_NOTE]: string;
  [BRIEF_FORM_KEYS.TINT]: string;
}

export const internalBriefValidationSchema = Yup.object().shape({
  [BRIEF_FORM_KEYS.APPROVAL_DATE]: Yup.date().typeError(
    'Must be a valid date: mm/dd/yy'
  ),
  [BRIEF_FORM_KEYS.PROJECT_NAME]: Yup.string().required(
    'Name of Project is required'
  ),
  [BRIEF_FORM_KEYS.INITIAL_DATE_OF_BRIEF]: Yup.date().typeError(
    'Must be a valid date: mm/dd/yy'
  ),
  [BRIEF_FORM_KEYS.TARGET_DATE]: Yup.date().typeError(
    'Must be a valid date: mm/dd/yy'
  ),
  [BRIEF_FORM_KEYS.SIZE]: Yup.number().typeError('Must be a number'),
  [BRIEF_FORM_KEYS.MINIMUM_ORDER_QUANTITY]: Yup.number()
    .typeError('Must be a number')
    .required('Initial Order Quantity is required'),
  [BRIEF_FORM_KEYS.COGS_TYPE]: Yup.string().required('Target COGS is required'),
  [BRIEF_FORM_KEYS.COGS_RANGE]: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }).when(BRIEF_FORM_KEYS.COGS_TYPE, {
    is: (ct: string) => ct === cogsType.Range,
    then: Yup.object({
      label: Yup.string().required('COGS Range is required'),
      value: Yup.string().required('COGS Range is required'),
    }),
  }),
  [BRIEF_FORM_KEYS.TARGET_COST]: Yup.string().when(BRIEF_FORM_KEYS.COGS_TYPE, {
    is: (ct: string) => ct === cogsType.Specific,
    then: Yup.string().required('COGS Specific Value is required'),
  }),
  [BRIEF_FORM_KEYS.COMPANY]: Yup.object({
    label: Yup.string().required('Company Name is required'),
    value: Yup.string().required('Company Name is required'),
  }),
  [BRIEF_FORM_KEYS.SAMPLE_RECIPIENTS]: Yup.array()
    .compact((v: { [x: string]: Maybe<string | Option> }) =>
      isEmpty(Object.values(v))
    )
    .of(
      Yup.object().shape({
        fullName: Yup.string().required('Full Name is required'),
        addressLine1: Yup.string().required('Address Line 1 is required'),
        city: Yup.string().required('City is required'),
        state: Yup.object({
          label: Yup.string().required('State is required'),
          value: Yup.string().required('State is required'),
        }).transform((_value, originalVal) =>
          typeof originalVal === 'object'
            ? originalVal
            : { label: originalVal, value: originalVal }
        ),
        zip: Yup.string().required('Zip Code is required'),
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
        phone: Yup.string().required('Phone is required'),
      })
    ),
});

export const initialInternalBriefFormValues: InternalBriefFormValues = {
  [BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK_NOTE]: '',
  [BRIEF_FORM_KEYS.ANTI_FRIZZ]: false,
  [BRIEF_FORM_KEYS.ANTI_REDNESS]: false,
  [BRIEF_FORM_KEYS.APPROVAL_DATE]: '',
  [BRIEF_FORM_KEYS.MUST_EXCLUDE_INGREDIENTS]: '',
  [BRIEF_FORM_KEYS.BLUE_LIGHT_PROTECTION]: false,
  [BRIEF_FORM_KEYS.BRIGHTENING]: false,
  [BRIEF_FORM_KEYS.COGS_RANGE]: { label: '', value: '' },
  [BRIEF_FORM_KEYS.TARGET_COST]: '',
  [BRIEF_FORM_KEYS.COGS_TYPE]: '',
  [BRIEF_FORM_KEYS.COLOR_NOTE]: '',
  [BRIEF_FORM_KEYS.COMPANY]: { label: '', value: '' },
  [BRIEF_FORM_KEYS.COUNTRIES]: [],
  [BRIEF_FORM_KEYS.COUNTRY_COMPLIANCE_NOTE]: '',
  [BRIEF_FORM_KEYS.CONDITIONING]: false,
  [BRIEF_FORM_KEYS.CONTACT]: { label: '', value: '' },
  [BRIEF_FORM_KEYS.CURL_DEFINING]: false,
  [BRIEF_FORM_KEYS.DEEP_CONDITIONING]: false,
  [BRIEF_FORM_KEYS.DETANGLE]: false,
  [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_LINE_CROWS_FEET]: false,
  [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_PUFFINESS]: false,
  [BRIEF_FORM_KEYS.SIZE]: '',
  [BRIEF_FORM_KEYS.UNIT]: '',
  [BRIEF_FORM_KEYS.FIRMING]: false,
  [BRIEF_FORM_KEYS.FRAGRANCE_COMMENT]: '',
  [BRIEF_FORM_KEYS.FRAGRANCE_TYPE]: '',
  [BRIEF_FORM_KEYS.GLUTEN_FREE]: false,
  [BRIEF_FORM_KEYS.GLUTEN_FREE_STATEMENT]: false,
  [BRIEF_FORM_KEYS.HAIR_SMOOTHING]: false,
  [BRIEF_FORM_KEYS.HARD_HOLD]: false,
  [BRIEF_FORM_KEYS.HYDRATION]: false,
  [BRIEF_FORM_KEYS.HYDRATION_LONG_TERM]: false,
  [BRIEF_FORM_KEYS.HYPOALLERGENIC]: false,
  [BRIEF_FORM_KEYS.IFRA_COMPLIANCE_STATEMENT]: false,
  [BRIEF_FORM_KEYS.INITIAL_DATE_OF_BRIEF]: '',
  [BRIEF_FORM_KEYS.MINIMUM_ORDER_QUANTITY]: '',
  [BRIEF_FORM_KEYS.LIGHT_HOLD]: false,
  [BRIEF_FORM_KEYS.MATERIAL]: { label: '', value: '' },
  [BRIEF_FORM_KEYS.MATTIFYING]: false,
  [BRIEF_FORM_KEYS.MOISTURE]: false,
  [BRIEF_FORM_KEYS.MOISTURIZATION]: false,
  [BRIEF_FORM_KEYS.MUST_HAVE_INGREDIENTS]: '',
  [BRIEF_FORM_KEYS.PROJECT_NAME]: '',
  [BRIEF_FORM_KEYS.NO_ANIMAL_DERIVED_INGREDIENTS]: false,
  [BRIEF_FORM_KEYS.NO_ANIMAL_TESTING]: false,
  [BRIEF_FORM_KEYS.NO_ANIMAL_DERIVED_INGREDIENTS_STATEMENT]: false,
  [BRIEF_FORM_KEYS.NO_ANIMAL_TESTED_INGREDIENTS_STATEMENT]: false,
  [BRIEF_FORM_KEYS.NON_OTC_CLAIM_COMMENTS]: '',
  [BRIEF_FORM_KEYS.OPACITY]: '',
  [BRIEF_FORM_KEYS.OTC]: '',
  [BRIEF_FORM_KEYS.OTC_NOTE]: '',
  [BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET_NOTE]: '',
  [BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET]: '',
  [BRIEF_FORM_KEYS.PRODUCT_CATEGORY]: { label: '', value: '' },
  [BRIEF_FORM_KEYS.PRODUCT_DESCRIPTION]: '',
  [BRIEF_FORM_KEYS.DIRECTIONS_FOR_USE]: '',
  [BRIEF_FORM_KEYS.PRODUCT_FUNCTION]: '',
  [BRIEF_FORM_KEYS.PRODUCT_OBJECTIVE]: '',
  [BRIEF_FORM_KEYS.PRODUCT_PURPOSE]: '',
  [BRIEF_FORM_KEYS.TARGET_CUSTOMER]: '',
  [BRIEF_FORM_KEYS.PRODUCT_TYPE]: { label: '', value: '' },
  [BRIEF_FORM_KEYS.PRODUCTION_LOCATION]: '',
  [BRIEF_FORM_KEYS.RADIANCE_GLOW]: false,
  [BRIEF_FORM_KEYS.SAMPLE_QUANTITY]: { label: '1', value: '1' },
  [BRIEF_FORM_KEYS.SELF_TANNER]: false,
  [BRIEF_FORM_KEYS.SHINE]: false,
  [BRIEF_FORM_KEYS.SKIN_SMOOTHING]: false,
  [BRIEF_FORM_KEYS.SOOTHING]: false,
  [BRIEF_FORM_KEYS.RETAILER_STANDARDS]: [],
  [BRIEF_FORM_KEYS.STATEMENTS_NOTE]: '',
  [BRIEF_FORM_KEYS.STRENGTHENING]: false,
  [BRIEF_FORM_KEYS.STRUCTURE]: '',
  [BRIEF_FORM_KEYS.TARGET_DATE]: '',
  [BRIEF_FORM_KEYS.TIGHTENING]: false,
  [BRIEF_FORM_KEYS.TINT]: '',
  [BRIEF_FORM_KEYS.VEGAN]: false,
  [BRIEF_FORM_KEYS.VEGAN_INGREDIENTS_STATEMENT]: false,
  [BRIEF_FORM_KEYS.EYES_REDUCE_APPEARANCE_OF_DARK_CIRCLES]: false,
  [BRIEF_FORM_KEYS.BENCHMARKS]: [
    {
      brandName: '',
      comment: '',
      productName: '',
      url: '',
      appearance: false,
      color: false,
      fragrance: false,
      texture: false,
      viscosity: false,
    },
  ],
  [BRIEF_FORM_KEYS.SAMPLE_RECIPIENTS]: [
    {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: { label: '', value: '' },
      zip: '',
      email: '',
      phone: '',
    },
  ],
  [BRIEF_FORM_KEYS.CONTAINER]: '',
  [BRIEF_FORM_KEYS.CLOSURE]: { label: '', value: '' },
  [BRIEF_FORM_KEYS.MASTER_BOX]: '',
  [BRIEF_FORM_KEYS.BROAD_SPECTRUM_TEST]: false,
  [BRIEF_FORM_KEYS.COMPATIBILITY_TEST]: false,
  [BRIEF_FORM_KEYS.HRIPT_TEST]: false,
  [BRIEF_FORM_KEYS.HRIPT_WITH_DERMATOLOGIST_TEST]: false,
  [BRIEF_FORM_KEYS.HRIPT_FOR_HYPOALLERGENIC_TEST]: false,
  [BRIEF_FORM_KEYS.OCCULAR_TEST]: false,
  [BRIEF_FORM_KEYS.PET_TEST]: false,
  [BRIEF_FORM_KEYS.SPF_DETERMINATION_FDA_TEST]: false,
  [BRIEF_FORM_KEYS.SPF_DETERMINATION_ISO_TEST]: false,
  [BRIEF_FORM_KEYS.STABILITY_TEST]: false,
  [BRIEF_FORM_KEYS.WATER_ACTIVITY_TEST]: false,
  [BRIEF_FORM_KEYS.TEST_HANDLING_RESPONSIBILITY]: '',
  [BRIEF_FORM_KEYS.ADDITIONAL_TESTING_BULK]: false,
  [BRIEF_FORM_KEYS.PRELIMINARY_IL_PER_SUBMISSION_STANDARD]: true,
  [BRIEF_FORM_KEYS.ESTIMATED_QUOTE_STANDARD]: true,
  [BRIEF_FORM_KEYS.COA]: false,
  [BRIEF_FORM_KEYS.OTHER_R_AND_D_DOCUMENTS]: '',
};

export const initialCustomerBriefFormValues: Partial<CustomerBriefFormValues> = {
  ...initialInternalBriefFormValues,
  [BRIEF_FORM_KEYS.PREVIOUSLY_BROUGHT_TO_MARKET]: '',
  [BRIEF_FORM_KEYS.ADDITIONAL_COMMENTS]: '',
  [BRIEF_FORM_KEYS.SPF]: '',
  [BRIEF_FORM_KEYS.TINT]: '',
};
