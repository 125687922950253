// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
// Components
import { ShoppingCartItem } from './shopping-cart-item.component';
import { Button, ShoppingCartContext } from 'features/ready-to-launch/ui';
// Assets
import products3 from 'features/ready-to-launch/images/icons/products3.svg';
import products4 from 'features/ready-to-launch/images/icons/products4.svg';
import arrowRight from 'features/ready-to-launch/images/icons/arrowRight.svg';
import { CartItem } from 'features/ready-to-launch/types';
import {
  checkCartVersionIntegrity,
  getCart,
} from 'features/ready-to-launch/utils/local-storage.util';
// Utils
import { useAnalytics, useMobile } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { SAMPLE_PRICE, FOOTER } from 'features/ready-to-launch/constants';
import { ROUTES } from 'features/navigation';

const EMPTY_MESSAGE = 'Your shopping cart is empty';
const CART_HEADER = 'Your samples';
const COMPARE_MESSAGE = 'Want to add more formulations?';
const BUTTON_1_TEXT = 'Build your sample';
const BUTTON_2_TEXT = 'Checkout';
const BUTTON_3_TEXT = 'Create new product';
const SUBTOTAL_TEXT = 'SUBTOTAL';
const MAIL_TO_SUBJECT = '?subject=Help with Prime Matter Labs Ready To Launch';

export const ShoppingCart: React.FC = () => {
  const analytics = useAnalytics();
  const { isMobile } = useMobile();
  const { resetForm } = useFormikContext();
  const { closeShoppingCart } = useContext(ShoppingCartContext);
  const navigate = useNavigate();
  const [items, setItems] = useState<CartItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const isEmpty = items.length <= 0;

  const numberOfSamples = (() => {
    return items.reduce((acc: number, cv: any) => {
      return acc + cv.itemQuantity;
    }, 0);
  })();

  const subtotal = numberOfSamples * SAMPLE_PRICE;

  useEffect(() => {
    checkCartVersionIntegrity();
    const getCartItems = () => {
      const currentCart = getCart();
      setItems(currentCart ? JSON.parse(currentCart) : []);
    };
    getCartItems();
    window.addEventListener('storage', getCartItems);

    return () => window.removeEventListener('storage', getCartItems);
  }, []);

  const restartWizard = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCShoppingCart,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.createNewProduct,
      nonInteraction: true,
    });
    resetForm();
    navigate(ROUTES.MASS_CUSTOMIZATION.productCategory.route);
  };

  const redirectToWizard = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCShoppingCart,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.buildYourSample,
      nonInteraction: true,
    });

    navigate(ROUTES.MASS_CUSTOMIZATION.wizard.route);
    closeShoppingCart();
  };

  const handleSubmit = async () => {
    try {
      setHasError(false);
      setIsLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_R_AND_D_API_BASE_URL}/api/v1/orders`,
        {
          method: 'POST',
          redirect: 'follow',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
          body: JSON.stringify({ orders: JSON.parse(getCart() as string) }),
        }
      );
      const parsedResponse = await response.json();
      if (parsedResponse.error) {
        Rollbar.error(`Error during checkout: ${parsedResponse.exception}`);
        throw new Error(parsedResponse.error);
      }

      if (parsedResponse.url) {
        analytics?.trackEvent({
          eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCShoppingCart,
          eventAction: AnalyticConstants.EVENT_ACTIONS.click,
          eventLabel: AnalyticConstants.EVENT_LABEL.checkout,
          eventValue: subtotal,
          nonInteraction: false,
        });
        const isProduction = process.env.REACT_APP_ENV === 'production';
        if (isProduction) {
          gtag_report_conversion(
            AnalyticConstants.GOOGLE_ADS_EVENT_IDS.RTL_CHECKOUT_EVENT,
            parsedResponse.url
          );
        } else {
          window.location.href = parsedResponse.url;
        }
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const renderItems = () => {
    return (
      <div className="sm:mx-10 mx-5">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:flex-wrap items-start sm:items-end mb-8 text-2xl sm:text-3xl font-display">
          <div className="mb-6 sm:mb-0">{CART_HEADER}</div>
          <Button
            fullWidth={isMobile}
            bgColor="blue"
            imageSrc={arrowRight}
            isLoading={isLoading}
            variant="lg"
            handleAction={handleSubmit}
          >
            {BUTTON_2_TEXT}
          </Button>
          {hasError && (
            <p className="text-red-40 text-sm mt-2 w-full text-center sm:text-right">
              Sorry, we encountered a problem. <br />
              <a
                href={`mailto:${FOOTER.EMAIL}${MAIL_TO_SUBJECT}`}
                className="underline cursor-pointer"
              >
                Email us
              </a>{' '}
              for more help.
            </p>
          )}
        </div>
        <div
          style={{ width: '105%', maxHeight: '230px' }}
          className="pr-9 pt-3 h-1/2 overflow-y-scroll"
        >
          {items.map((item, idx) => {
            return (
              <ShoppingCartItem
                idx={idx}
                key={`item-${item.name}-${idx}`}
                item={item}
              />
            );
          })}
        </div>
        <div className="flex sm:flex-row flex-col items-center sm:p-6 p-8 bg-legacyPeach">
          <img width="28" height="40" src={products4} alt="products4" />
          <div className="sm:ml-3 font-display text-base">
            {COMPARE_MESSAGE}
          </div>
          <div className="sm:ml-auto sm:mt-0 mt-5">
            <Button bgColor="black" variant="md" handleAction={restartWizard}>
              {BUTTON_3_TEXT}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderEmptyCart = () => {
    return (
      <div className="flex flex-col">
        <img
          className="mx-auto"
          height="72"
          width="65"
          src={products3}
          alt="products2"
        />
        <div className="mt-4 text-2xl sm:text-3xl font-display">
          {EMPTY_MESSAGE}
        </div>
        <div className="mx-auto mt-5">
          <Button handleAction={redirectToWizard} variant="md" bgColor="black">
            {BUTTON_1_TEXT}
          </Button>
        </div>
      </div>
    );
  };

  const renderSubTotal = () => {
    if (isMobile) {
      return (
        <>
          <div className="sticky flex flex-col z-30 bottom-0 w-full pt-5 pb-6 pl-5 pr-10 bg-lightestGreen border-t border-gray">
            <div className="flex items-center">
              <div className="font-mono text-xs font-medium mr-5">
                {SUBTOTAL_TEXT}
              </div>
              <div className="text-xl font-display text-left">
                {numberOfSamples} Sample{numberOfSamples > 1 ? 's' : ''}
              </div>
              <div className="font-display ml-auto text-2xl">${subtotal}</div>
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="sticky bottom-0 w-full z-30 bg-lightestGreen border-t border-gray">
        <div className="flex justify-between px-10 py-7">
          <div className="font-mono text-xs font-medium shrink mr-12">
            {SUBTOTAL_TEXT}
          </div>
          <div className="flex flex-col flex-grow font-display text-left justify-center items-start">
            <div className="text-xl">
              {numberOfSamples} Sample{numberOfSamples > 1 ? 's' : ''}
            </div>
          </div>
          <div className="self-center font-display text-3xl">${subtotal}</div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={
        !isMobile
          ? { top: '63px', bottom: '128px' }
          : { top: '63px', bottom: '100px' }
      }
      className="sm:mx-auto w-full mb-28 sm:mb-0 text-center pt-15 flex flex-col justify-between z-20 sm:border-l border-t sm:border-r sm:w-3/5 absolute sm:right-0 bg-lightPeach"
    >
      {isEmpty && renderEmptyCart()}
      {!isEmpty && renderItems()}
      {!isEmpty && renderSubTotal()}
    </div>
  );
};
