// Libraries
import React from 'react';
// Components
import { FormLayout } from '../layout';
import { FormSelectList } from 'features/ui';
// Constants
import { BRIEF_FORM_INPUTS, CLAIMS } from '../../constants';
const TITLE = 'Are there claims your product should qualify for?';

export const ProductClaims: React.FC = () => {
  return (
    <FormLayout title={TITLE}>
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6">
        <label className="col-span-2 sm:col-start-2 sm:col-span-12 font-mono text-xs font-semibold mb-3">
          {'Select all that apply'.toLocaleUpperCase()}
        </label>
      </div>
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12">
        <div className="col-span-2 sm:col-start-2 sm:col-span-8 bg-white">
          <FormSelectList
            type="checkbox"
            inputName={BRIEF_FORM_INPUTS.productClaims}
            items={CLAIMS}
          />
        </div>
      </div>
    </FormLayout>
  );
};
