// Libraries
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
// Assets
import PlusIcon from '../images/icons/plus.svg';
import MinusIcon from '../images/icons/minus.svg';
import { changeItemQuantity } from '../utils/local-storage.util';
// Constants
import { INPUT_NAMES } from '../constants';

interface IncrementDecrementProps {
  initialValue?: number;
  idx?: number;
}

export const IncrementDecrement: React.FC<IncrementDecrementProps> = ({
  initialValue,
  idx,
}) => {
  const { setFieldValue } = useFormikContext();
  const MIN_PRODUCT_QTY = 1;
  const MAX_PRODUCT_QTY = 2;
  const [currentNum, setCurrentNum] = useState(initialValue || MIN_PRODUCT_QTY);

  const incrementNum = () => {
    if (currentNum < MAX_PRODUCT_QTY) {
      const newNum = currentNum + 1;
      setFieldValue(INPUT_NAMES.itemQuantity, newNum);
      setCurrentNum(newNum);

      (idx as number) >= 0 && changeItemQuantity(idx as number, newNum);
    }
  };

  const decrementNum = () => {
    if (currentNum > MIN_PRODUCT_QTY) {
      const newNum = currentNum - 1;
      setFieldValue(INPUT_NAMES.itemQuantity, newNum);
      setCurrentNum(newNum);

      (idx as number) >= 0 && changeItemQuantity(idx as number, newNum);
    }
  };

  return (
    <div className="text-black flex justify-center w-28 px-2 py-4 border border-gray">
      <button onClick={decrementNum}>
        <img src={MinusIcon} alt="minus icon" />
      </button>
      <div className="mx-2.5 text-xl font-display text-center select-none w-6">
        {currentNum}
      </div>
      <button onClick={incrementNum}>
        <img src={PlusIcon} alt="plus icon" />
      </button>
    </div>
  );
};
