//Libraries
import React from 'react';

interface StepProps {
  children: React.ReactNode[] | React.ReactNode;
  currentStep: number;
  currentStepName: string;
  decrementStep: () => void;
  incrementStep: () => void;
  resetWizard: () => void;
  stepNumber: number;
}

export const StepContext = React.createContext({
  currentStep: 0,
  currentStepName: '',
  incrementStep: () => {},
  decrementStep: () => {},
  resetWizard: () => {},
});

export const Step: React.FC<StepProps> = ({
  children,
  currentStep,
  currentStepName,
  decrementStep,
  incrementStep,
  resetWizard,
  stepNumber,
}) => {
  // stepNumber is this component's step
  // currentStep is the current step of the whole wizard
  if (stepNumber !== currentStep) return null;

  return (
    <StepContext.Provider
      value={{
        currentStep,
        incrementStep,
        decrementStep,
        resetWizard,
        currentStepName,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};
