// Libraries
import React, { useEffect, useState } from 'react';
// Components
import { AdditionalInfo } from '../additional-info';
import { BasicInfo } from '../basic-info';
import { Benchmarks } from '../benchmarks';
import { EstimatedOrderQuantity } from '../estimated-order-quantity';
import { ExistingProductInfo } from '../existing-product-info';
import { FillCapacity } from '../fill-capacity';
import { FinalDetailsInterstitial } from '../final-details-interstitial';
import { Fragrance } from '../fragrance';
import { IngredientRequirements } from '../ingredient-requirements';
import { IntendedMarkets } from '../intended-markets';
import { MarketStrategyInterstitial } from '../market-strategy-interstitial';
import { OtcInfo } from '../otc-info';
import { Packaging } from '../packaging';
import { ProductCategory } from '../product-category';
import { ProductClaims } from '../product-claims';
import { ProductType } from '../product-type';
import { ProgressBar } from '../progress-bar';
import { ProjectBasicsInterstitial } from '../project-basics-interstitial';
import { RetailerStandards } from '../retailer-standards';
import { TargetAudience } from '../target-audience';
import { TargetRetailPrice } from '../target-retail-price';
import { TotalCostOfGoods } from '../total-cost-of-goods';
import { WizardContext } from './wizard.context';

// Constants
const HUNDREDS_MULTIPLIER = 100;

export const Wizard: React.FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageNumber]);

  const incrementPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const decrementPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const PAGES = [
    <ProjectBasicsInterstitial />,
    <BasicInfo />,
    <TargetAudience />,
    <IntendedMarkets />,
    <MarketStrategyInterstitial />,
    <ProductCategory />,
    <ProductType />,
    <ExistingProductInfo />,
    <OtcInfo />,
    <FillCapacity />,
    <EstimatedOrderQuantity />,
    <TotalCostOfGoods />,
    <TargetRetailPrice />,
    <FinalDetailsInterstitial />,
    <Packaging />,
    <IngredientRequirements />,
    <ProductClaims />,
    <RetailerStandards />,
    <Fragrance />,
    <Benchmarks />,
    <AdditionalInfo />,
  ];

  const Component = PAGES[pageNumber];

  const percentageComplete =
    ((pageNumber + 1) / PAGES.length) * HUNDREDS_MULTIPLIER;

  return (
    <div className="text-left h-screen text-darkGray">
      <ProgressBar percentageComplete={percentageComplete} />
      <WizardContext.Provider
        value={{ pageNumber, incrementPage, decrementPage }}
      >
        {Component}
      </WizardContext.Provider>
    </div>
  );
};
