// Libraries
import React from 'react';
// Components
import { DontSeeYourProduct } from 'features/ready-to-launch/ui';
import { SummaryTable } from './summary-table.component';
import { MobileSummaryTable } from './mobile-summary-table.component';
// Utils
import { IMassCustomizationScreen } from 'features/ready-to-launch/types';
import { useMobile } from 'hooks';

export const CustomizationSummary: React.FC<IMassCustomizationScreen> = ({
  product,
}) => {
  const { isMobile } = useMobile();
  return (
    <div className="sm:grid sm:grid-cols-3 px-5 sm:px-24.5 sm:gap-5 pt-10">
      {isMobile ? (
        <MobileSummaryTable product={product} />
      ) : (
        <SummaryTable product={product} />
      )}
      <div className="col-span-3 -mt-24">
        <DontSeeYourProduct />
      </div>
    </div>
  );
};
