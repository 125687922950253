//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
//Components
import { Loader } from 'features/ui';
import { OnboardingWrapper } from 'features/onboarding';
import { UserContext } from 'context';
//Utils
import {
  apiConstants,
  useApi,
  EmployeeAttributes,
  IApiData,
  IJWTPayload,
} from 'api';
import { AxiosError } from 'axios';
import { useError } from 'hooks';
// Constants
const { NOT_FOUND_STATUS } = apiConstants;

interface EmployeeAppProps {
  children: any;
}

const EmployeeApp: React.FC<EmployeeAppProps> = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const throwError = useError();
  const { getEmployee } = useApi();

  const { setPermissions, setUserSession, userSession } = useContext(
    UserContext
  )!;
  const [isEmployeeLoading, setIsEmployeeLoading] = useState<boolean>(true);

  const updateEmployeeSession = (
    { id, attributes }: IApiData<EmployeeAttributes>,
    token: string
  ) => {
    const decoded = jwtDecode<IJWTPayload>(token);

    if (decoded?.permissions?.length) {
      setPermissions(decoded.permissions);
    } else {
      throw new Error('No permissions found for user');
    }

    setUserSession({
      ...userSession,
      uuid: id,
      givenName: attributes?.givenName,
      familyName: attributes?.familyName,
      employeeMetadata: {
        ...userSession.employeeMetadata,
        location: attributes?.location,
        searchLocation: attributes?.searchLocation,
      },
    });
  };

  const findEmployee = async () => {
    getEmployee({
      urlParams: user?.sub || '',
      handleSuccess: (data: IApiData<EmployeeAttributes>, token: string) =>
        updateEmployeeSession(data, token),
      handleError: (error) => {
        if ((error as AxiosError)?.response?.status !== NOT_FOUND_STATUS) {
          // in most cases the error will be an expect 404 error
          // when its not we send the unexpected error to Rollbar
          Rollbar.error(error, {
            sub: user?.sub,
          });

          throwError(error as AxiosError);
        }
      },
      handleFinally: () => setIsEmployeeLoading(false),
    });
  };

  useEffect(() => {
    if (user) {
      // Configure employee for Rollbar
      Rollbar.configure({
        payload: {
          person: {
            id: user?.sub,
            email: user?.email,
          },
        },
      });

      findEmployee();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  if (isLoading || (isAuthenticated && isEmployeeLoading)) {
    return <Loader />;
  }

  // This means the user has signed up for the app through auth0
  // but doesn't exist in the the database yet
  if (isAuthenticated && !userSession.uuid) {
    return <OnboardingWrapper />;
  }

  return children;
};

export default EmployeeApp;
