// Libraries
import React from 'react';
import * as Yup from 'yup';
// Components
import { ChooseBase } from '../choose-base.component';
import { GoalsStep, ExtrasStep, NameStep } from '../customization-form';
import { CustomizationSummary } from '../customization-summary';
// Assets
import washImage from '../images/swatches/wash.png';
import creamImage from '../images/swatches/bodyCream.png';
import scrubImage from '../images/swatches/scrub.png';
import smallJar from '../images/smallJar.webp';
import tottle from '../images/tottle.webp';
import bodyImage from '../images/body.png';
import mobileBodyImage from '../images/mobileBody.png';
// Utils
import {
  SAMPLE_PRICE,
  EXTRACTS,
  INITIAL_FORM_VALUES,
  SUMMARY_TABLE_TEXT,
  SPECIFICATIONS,
  WAITLIST_TAG_NAMES,
} from '../constants';
import { Product } from '../types';

const { aloeVera, coffee, greenTea, lemonPeel, oat } = EXTRACTS;
const { parabens, vegan, sephora } = SPECIFICATIONS;

const GOAL_LIMIT = 2;

const bodyCare: Product = {
  categoryCardProperties: {
    imageSrc: bodyImage,
    mobileImageSrc: mobileBodyImage,
    tags: [],
  },
  categoryDisabled: false,
  goals: [
    {
      title: 'Exfoliating',
      subTitle:
        'Help to provide gentle exfoliation using tropical fruit derived AHAs.',
      ingredients:
        'Water, Glycerin, Spondias Mombin Pulp Extract, Mangifera indica (Mango) Pulp Extract, Musa Sapientum (Banana) Pulp Extract',
    },
    {
      title: 'Moisturizing',
      subTitle:
        'Nourish and soften the skin using a blend of Kahai Oil, Marula Oil and Jojoba Oil, that are rich in vitamins, antioxidants and essential fatty acids.',
      ingredients:
        'Caryodendron Orinocense (Kahai) Seed Oil, Sclerocarya Birrea (Marula) Seed Oil, Simmondsia Chinensis (Jojoba) Seed Oil',
    },
    {
      title: 'Hydration',
      subTitle:
        'Provide deep hydration using Hyaluronic Acid. This humectant anchors moisture within the skin, keeping it hydrated and bouncy.',
      ingredients: 'Sodium Hyaluronate, Water',
    },
    {
      title: 'Skin Complexion',
      subTitle:
        'Give skin a firmer feel and diminish the appearance of stretch marks and cellulite with Green Coffee Oil, which has antioxidant properties and high amounts of Vitamin E.',
      ingredients:
        'Coffea Arabica (Coffee) Seed Oil (and) Brassica Campestris (Rapeseed) Sterols',
    },
  ],
  goalScreenProperties: {
    goalLimit: GOAL_LIMIT,
    title: `select ${GOAL_LIMIT} option${GOAL_LIMIT > 1 ? 's' : ''}`,
    description:
      'Let’s create a formula for the goals you’d like your product to address.',
  },
  extras: [aloeVera, coffee, greenTea, lemonPeel, oat],
  initialFormValues: {
    ...INITIAL_FORM_VALUES,
    productCategory: 'bodyCare',
  },
  initialHeaderText: (
    <>
      Choose your <span className="text-blue-60">body care base</span>
    </>
  ),
  initialSubheaderText:
    'After you select a body care base, you’ll identify the goals you’d like your product to address.',
  initialProgressStepValues: [
    {
      stepNumber: 0,
      stepText: 'Base',
      isActive: false,
      isTouched: true,
      isCompleted: true,
      stepName: 'productBase',
    },
    {
      stepNumber: 1,
      stepText: 'Goals',
      isActive: true,
      isTouched: true,
      isCompleted: false,
      stepName: 'goals',
    },
    {
      stepNumber: 2,
      stepText: 'Extras',
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'extras',
    },
    {
      stepNumber: 3,
      stepText: 'Name',
      lastStep: true,
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'name',
    },
    {
      stepNumber: 4,
      stepText: 'Summary',
      lastStep: true,
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'summary',
    },
  ],
  productBases: [
    {
      cost: `$${SAMPLE_PRICE}`,
      details:
        'A rich and buttery formula to hydrate and nourish skin. Floral scent.',
      imageSrc: creamImage,
      sampleImageSrc: smallJar,
      title: 'Body Cream',
      ingredients:
        'Water, Butyrospermum Parkii (Shea) Butter, Carthamus Tinctorius (Safflower) Seed Oil, Glyceryl Stearate, PEG-100 Stearate, Glycerin, Caprylyl Methicone, Theobroma Cacao (Cocoa)Seed Butter, Phenoxyethanol, SodiumPolyacrylate, Hydrogenated Polydecene, Caprylyl Glycol, Chlorphenesin, Disodium EDTA,Trideceth-6, Fragrance, Aloe Barbadensis LeafJuice, Squalane, Cetearyl Alcohol, Ceteareth-20',
    },
    {
      cost: `$${SAMPLE_PRICE}`,
      details:
        'A citrus-based exfoliating formula to gently remove dull, dry skin. Citrus scent.',
      imageSrc: scrubImage,
      sampleImageSrc: tottle,
      title: 'Body Scrub',
      ingredients:
        'Water, Sodium Lauroyl Sarcosinate, AcrylatesCopolymer, Cocoamidopropyl Betaine, Polylactic Acid, Polysorbate 20, Pumice,Fragrance, Coco-Glucoside, Glyceryl Oleate, Phenoxyethanol, Sodium Chloride, SodiumLaurate, Glycerin, Mentha Piperita (Peppermint)Oil, Citric Acid, Ethylhexylglycerin, DisodiumEDTA, Citrus Aurantium Dulcis (Orange) PeelOil, Salicylic Acid, Tetrahexyldecyl Ascorbate, FD&C Yellow No.5, Tocopherol, HydrogenatedPalm Glycerides Citrate, FD&C Red 40',
    },
    {
      cost: `$${SAMPLE_PRICE}`,
      details:
        'A foaming wash to gently cleanse skin for daily use. Citrus scent.',
      imageSrc: washImage,
      isWaitlisted: true,
      waitlistTag: WAITLIST_TAG_NAMES.bodyCareBodyWash,
      sampleImageSrc: tottle,
      title: 'Body Wash',
      ingredients:
        'Water, Cetearyl Alcohol, Caprylic/Capric Triglyceride, Diisopropyl Dimer Dilinoleate, Glycerin, Butyrospermum Parkii (Shea) Butter, Stearic Acid, Sodium Stearoyl Lactylate, Hydrogenated Sunflower Seed Oil Glyceryl Esters, Hydrogenated Sunflower Seed Oil Polyglyceryl-3 Esters, Phenoxyethanol, Caprylyl Glycol, Xanthan Gum, Citric Acid, Sodium Hydroxide',
    },
  ],
  specifications: [parabens, vegan, sephora],
  steps: [ChooseBase, GoalsStep, ExtrasStep, NameStep, CustomizationSummary],
  summaryTableText: SUMMARY_TABLE_TEXT,
  title: 'Body Care',
  validationSchema: Yup.object().shape({
    productBase: Yup.string().required(),
    goals: Yup.array().min(1).max(GOAL_LIMIT),
    name: Yup.string().required().min(1).max(25),
    itemQuantity: Yup.number().min(1).max(10),
  }),
};

export default bodyCare;
