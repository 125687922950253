// Libraries
import React from 'react';
// Components
import { useMobile } from 'hooks';
// Assets
import idea from '../../../assets/idea.svg';

interface ITipBox {
  tipText: string;
}

export const TipBox: React.FC<ITipBox> = ({ tipText }) => {
  const { isMobile } = useMobile();
  return isMobile ? (
    <div className="flex items-start bg-legacyPeach px-5 py-6">
      <img height="44" width="44" src={idea} alt="idea" />
      <div className="ml-4 font-display text-base">{tipText}</div>
    </div>
  ) : (
    <div className="flex flex-col bg-legacyPeach pl-6 pr-20 py-10">
      <img height="44" width="44" src={idea} alt="idea" />
      <div className="font-display text-base pt-4">{tipText}</div>
    </div>
  );
};
