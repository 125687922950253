// Libraries
import React from 'react';
// Components
import {
  PrimaryComponent,
  MasterBox,
  Closure,
  Material,
} from '../form-modules';

export const Packaging: React.FC = () => {
  return (
    <>
      <div className="flex flex-col gap-6">
        <p
          id="components"
          className="font-inter text-lg font-normal text-grey-20 "
        >
          Components
        </p>

        <PrimaryComponent />
        <Material />
        <Closure />

        <MasterBox />
      </div>
    </>
  );
};
