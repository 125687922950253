// Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
// Components
import { UserContext } from 'context';
import { Wizard } from './wizard';
// Utils
import { BriefFormValues } from '../types';
import { useApi } from 'api';
// Constants
import { ROUTES } from 'features/navigation';
import { BRIEF_FORM_INPUTS } from '../constants';
import { BRIEF_TYPES } from 'features/constants';
import { useSnackbar } from 'hooks';

enum Units {
  ml = 'ml',
  g = 'g',
  oz = 'oz',
}

const thresholds: Record<Units, number> = {
  [Units.ml]: 30,
  [Units.g]: 30,
  [Units.oz]: 1,
};

const OZ_MINIMUM = 0.25;
const G_AND_ML_MINIMUM = 7.5;

enum OtcValues {
  yes = 'Yes',
  no = 'No',
  unsure = 'Unsure',
}

const AEROSOL = 'Aerosol';

const AEROSOL_OR_OTC_THRESHOLD = 10_000;
const LOW_FILL_SIZE_THRESHOLD = 20_000;
const STANDARD_MOQ_THRESHOLD = 5_000;

export const briefValidationSchema = Yup.object().shape({
  additionalInfo: Yup.string(),
  benchmarks: Yup.array().of(
    Yup.object().shape({
      url: Yup.string().url('Must be a valid URL'),
      color: Yup.boolean(),
      viscosity: Yup.boolean(),
      texture: Yup.boolean(),
      fragrance: Yup.boolean(),
      appearance: Yup.boolean(),
    })
  ),
  cogsSpecific: Yup.number()
    .typeError('Cost must be a number')
    .positive('Cost must be a positive number'),
  cogsRange: Yup.string().when(BRIEF_FORM_INPUTS.cogsSpecific, {
    is: (val: any) => val === '' || val === undefined,
    then: (schema) => schema.required(),
  }),
  fragranceComment: Yup.string().url('Must be a URL'),
  fragranceType: Yup.string().required(),
  intendedMarkets: Yup.array().of(Yup.string()).min(1),
  isOtc: Yup.string().required(),
  isOtcNote: Yup.string(),
  labels: Yup.array().of(Yup.string()),
  minimumOrderQuantity: Yup.number()
    .transform((value, originalValue) =>
      /\s/.test(originalValue) ? NaN : value
    )
    .typeError('Order quantity must be a number')
    .positive('Order quantity must be a positive number')
    .integer('Order quantity must be a whole number')
    .required('Order quantity is required')
    .when(['size', 'unit'], {
      is: (size: number, unit: keyof Units) => size < thresholds[unit as Units],
      then: (schema) =>
        schema.min(
          LOW_FILL_SIZE_THRESHOLD,
          'Prime Matter Labs is unable to fulfill your proposed order. The minimum order quantity for your fill size is between 20,000 - 50,000.'
        ),
      otherwise: Yup.number().when(['isOtc', 'structure'], {
        is: (isOtc: OtcValues, structure: string) =>
          isOtc === OtcValues.yes || structure === AEROSOL,
        then: (schema) =>
          schema.min(
            AEROSOL_OR_OTC_THRESHOLD,
            'Prime Matter Labs is unable to fulfill your proposed order. The minimum order quantity for your product type is 10,000.'
          ),
        otherwise: (schema) =>
          schema.min(
            STANDARD_MOQ_THRESHOLD,
            'Prime Matter Labs is unable to fulfill your proposed order. The minimum order quantity for your product type is 5,000.'
          ),
      }),
    }),
  msrpPerUnitRange: Yup.string().when(BRIEF_FORM_INPUTS.msrpPerUnitSpecific, {
    is: (val: any) => val === '' || val === undefined,
    then: (schema) => schema.required(),
  }),
  msrpPerUnitSpecific: Yup.number()
    .typeError('Cost must be a number')
    .positive('Cost must be a positive number'),
  mustHaveIngredients: Yup.string(),
  mustExcludeIngredients: Yup.string(),
  previouslyBroughtToMarket: Yup.string().required(),
  previouslyBroughtToMarketNote: Yup.string(),
  primaryPackaging: Yup.string().required(),
  productCategory: Yup.string().required(),
  productClaims: Yup.array().of(Yup.string()),
  productType: Yup.string().when(BRIEF_FORM_INPUTS.productTypeNote, {
    is: (val: string) => val === '',
    then: (schema) => schema.required(),
  }),
  productTypeNote: Yup.string(),
  projectDescription: Yup.string(),
  projectName: Yup.string().required('Project name is required'),
  retailerStandards: Yup.array().of(Yup.string()),
  size: Yup.number()
    .when(BRIEF_FORM_INPUTS.unit, {
      is: (val: string) => val === 'oz',
      then: (schema) =>
        schema.min(OZ_MINIMUM, `Size must be at least ${OZ_MINIMUM} oz`),
    })
    .when(BRIEF_FORM_INPUTS.unit, {
      is: (val: string) => val === 'ml',
      then: (schema) =>
        schema.min(
          G_AND_ML_MINIMUM,
          `Size must be at least ${G_AND_ML_MINIMUM} ml`
        ),
    })
    .when(BRIEF_FORM_INPUTS.unit, {
      is: (val: string) => val === 'g',
      then: (schema) =>
        schema.min(
          G_AND_ML_MINIMUM,
          `Size must be at least ${G_AND_ML_MINIMUM} g`
        ),
    })
    .typeError('Size must be a number')
    .positive('Size must be a positive number')
    .required('Size is required'),
  specificFragrances: Yup.array().of(Yup.string()),
  structure: Yup.string().required(),
  targetAudience: Yup.string(),
  targetDate: Yup.date()
    .typeError('Must be a valid date: mm/dd/yyyy')
    .min(new Date(), 'Date is in the past')
    .required('Delivery date is required'),
  unit: Yup.string().required(),
});

const initialValues: BriefFormValues = {
  additionalInfo: '',
  benchmarks: [
    {
      url: '',
      color: false,
      viscosity: false,
      texture: false,
      fragrance: false,
      appearance: false,
    },
  ],
  cogsRange: '',
  cogsSpecific: '',
  fragranceComment: '',
  fragranceType: '',
  intendedMarkets: [],
  isOtc: '',
  isOtcNote: '',
  labels: [],
  minimumOrderQuantity: '',
  msrpPerUnitRange: '',
  msrpPerUnitSpecific: '',
  mustExcludeIngredients: '',
  mustHaveIngredients: '',
  previouslyBroughtToMarket: '',
  previouslyBroughtToMarketNote: '',
  primaryPackaging: '',
  productCategory: '',
  productClaims: [],
  productType: '',
  productTypeNote: '',
  projectDescription: '',
  projectName: '',
  retailerStandards: [],
  size: '',
  specificFragrances: [],
  structure: '',
  targetAudience: '',
  targetDate: '',
  unit: '',
};

export const CustomerBriefForm: React.FC = () => {
  const navigate = useNavigate();
  const { postProject } = useApi();
  const {
    userSession: { customerMetadata },
  } = useContext(UserContext)!;
  const { showSnackbar } = useSnackbar();

  const parseMaxRangeValue = (value: string) => {
    return value.split('-$')[1];
  };

  const parseMinRangeValue = (value: string) => {
    return value.split('-$')[0].split('$')[1];
  };

  const submitForm = (
    values: BriefFormValues,
    { setSubmitting }: FormikHelpers<BriefFormValues>
  ) => {
    const {
      additionalInfo,
      benchmarks,
      cogsRange,
      cogsSpecific,
      fragranceComment,
      fragranceType,
      intendedMarkets,
      isOtc,
      isOtcNote,
      labels,
      minimumOrderQuantity,
      msrpPerUnitRange,
      msrpPerUnitSpecific,
      mustExcludeIngredients,
      mustHaveIngredients,
      previouslyBroughtToMarket,
      previouslyBroughtToMarketNote,
      primaryPackaging,
      productCategory,
      productClaims,
      productType,
      productTypeNote,
      projectDescription,
      projectName,
      retailerStandards,
      size,
      specificFragrances,
      structure,
      targetAudience,
      targetDate,
      unit,
    } = values;
    postProject({
      data: {
        project: {
          companyUuid: customerMetadata.companyUuid,
          name: projectName,
          category: productCategory,
          description: projectDescription,
          otc: isOtc,
          otcNote: isOtcNote,
          previouslyBroughtToMarket,
          previouslyBroughtToMarketNote,
          productClaims,
          productType,
          productTypeNote,
          retailerStandards,
          briefAttributes: {
            additionalComments: additionalInfo,
            bMarksAttributes: {
              ...benchmarks,
            },
            briefSupplementAttributes: {},
            countries: intendedMarkets,
            fragranceComment,
            fragranceType,
            kind: BRIEF_TYPES.final,
            labels,
            minimumOrderQuantity,
            msrpTargetCost: msrpPerUnitSpecific,
            msrpTargetMaxCost: parseMaxRangeValue(msrpPerUnitRange),
            msrpTargetMinCost: parseMinRangeValue(msrpPerUnitRange),
            mustExcludeIngredients,
            mustHaveIngredients,
            primaryPackaging,
            size,
            specificFragrances,
            structure,
            targetCost: cogsSpecific,
            targetCustomer: targetAudience,
            targetDate: new Date(targetDate),
            targetMaxCost: parseMaxRangeValue(cogsRange),
            targetMinCost: parseMinRangeValue(cogsRange),
            unit,
          },
        },
      },
      handleSuccess: (_data) => {
        navigate(ROUTES.BRIEF_SUBMISSION_SUCCESS.route);
      },
      handleError: (error) => {
        showSnackbar(
          'There was an error creating the brief, please contact support at help@primematterlabs.com',
          'error'
        );
        Rollbar.error(error, {
          brief: values,
        });
      },
      handleFinally: () => {
        // isSubmitting is provided by formik as a generic loader boolean
        // when submitForm is called the isSubmitting boolean is automatically
        // set to true, here we can set it back so loaders will disappear.
        setSubmitting(false);
      },
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validateOnMount
      validationSchema={briefValidationSchema}
    >
      <Wizard />
    </Formik>
  );
};
