// Libraries
import React from 'react';
import { XMarkIcon } from '@heroicons/react/16/solid';

interface ChipProps {
  additionalClasses?: string;
  bgColor?: string;
  borderColor?: string;
  closeIconColor?: string;
  label: string;
  leadingIcon?: React.ReactNode;
  noHover?: boolean;
  onClick?: () => void;
  onCloseIconClick?: (event: React.MouseEvent) => void;
  selected?: boolean;
  textColor?: string;
  trailingIcon?: React.ReactNode;
  value: string;
}

export const Chip: React.FC<ChipProps> = ({
  additionalClasses = '',
  bgColor = 'bg-white',
  borderColor = 'border-grey-80',
  closeIconColor = 'text-grey-50',
  label,
  leadingIcon,
  noHover,
  onClick,
  onCloseIconClick,
  selected,
  textColor = 'text-grey-20',
  trailingIcon,
  value,
}) => {
  return (
    <div
      key={`${label}-${value}`}
      className={`${selected ? 'bg-grey-90' : bgColor} ${
        selected ? 'border-royal-50' : borderColor
      } ${textColor} ${additionalClasses} whitespace-nowrap h-7 ${
        !noHover && 'cursor-pointer'
      } flex gap-[6px] items-center border rounded-full px-2 py-[6px] font-inter text-xs`}
      onClick={onClick && (() => onClick())}
    >
      {leadingIcon}
      <span>{label}</span>
      {trailingIcon}
      {onCloseIconClick && selected && (
        <XMarkIcon
          onClick={onCloseIconClick}
          className={`${closeIconColor} cursor-pointer h-4 w-4`}
        />
      )}
    </div>
  );
};
