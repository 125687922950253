const CART = 'cart';
const VERSION_KEY = 'cart version';
/**
 * The cart version number is used to check if shopping carts are out of sync
 * with the latest changes made to the app.
 * The cart version number should be adjusted anytime a change is
 * made to any part of the app that will affect the shopping cart
 * e.g. changing the keys in formik will result in breaking changes when
 * accessing older version of the cart
 */
const CURRENT_CART_VERSION = '2';

export const emitStorageChangedEvent = () => {
  const iframeEl = document.createElement('iframe');
  iframeEl.style.display = 'none';
  document.body.appendChild(iframeEl);

  iframeEl.contentWindow?.localStorage.setItem('t', Date.now().toString());
  iframeEl.remove();
};

export const saveToLocalStorage = (values: any) => {
  // In cases where cart is outdated, we want to clean it first
  checkCartVersionIntegrity();
  const currentCart = getCart();
  if (currentCart) {
    const parsedCart = JSON.parse(currentCart);
    localStorage.setItem(CART, JSON.stringify([...parsedCart, values]));
  } else {
    localStorage.setItem(CART, JSON.stringify([values]));
    localStorage.setItem(VERSION_KEY, CURRENT_CART_VERSION);
  }
  emitStorageChangedEvent();
};

export const checkCartVersionIntegrity = () => {
  const cartVersion = localStorage.getItem(VERSION_KEY);

  if (!cartVersion || cartVersion !== CURRENT_CART_VERSION) {
    clearLocalStorage();
  }
};

export const getCart = () => localStorage.getItem(CART);

export const removeItemFromLocalStorage = (idx: number) => {
  const currentCart = getCart();
  if (currentCart) {
    const parsedCart = JSON.parse(currentCart);
    parsedCart.splice(idx, 1);
    localStorage.setItem(CART, JSON.stringify([...parsedCart]));
  }
  emitStorageChangedEvent();
};

export const changeItemQuantity = (idx: number, newQuantity: number) => {
  const currentCart = getCart();
  if (currentCart) {
    const parsedCart = JSON.parse(currentCart);
    parsedCart[idx].itemQuantity = newQuantity;
    localStorage.setItem(CART, JSON.stringify([...parsedCart]));
  }
  emitStorageChangedEvent();
};

export const clearLocalStorage = () => {
  localStorage.removeItem(CART);
  localStorage.removeItem(VERSION_KEY);
  emitStorageChangedEvent();
};
