// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
// Assets
import emailIcon from '../images/icons/email.svg';
import primeLogo from '../images/primeLogo.svg';
// Utils
import { useAnalytics } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { FOOTER } from '../constants';
import { ROUTES } from 'features/navigation';

export const Footer: React.FC = () => {
  const analytics = useAnalytics();

  const handleFooterClick = (label: string) => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCFooter,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: label,
      nonInteraction: true,
    });
  };

  const renderFooterLinks = () => {
    return FOOTER.LINKS.map((link) => {
      return (
        <a
          key={link.text}
          className="first:ml-0 ml-3"
          target="_blank"
          rel="noreferrer"
          onClick={() => handleFooterClick(link.analyticsEvent)}
          href={link.url}
        >
          {link.text}
        </a>
      );
    });
  };

  return (
    <footer className="bg-blue-60 sm:h-32">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between py-5 sm:py-0 mx-5 h-full">
        <Link to={ROUTES.MASS_CUSTOMIZATION.root.route}>
          <img
            className="mb-5 sm:mb-0 cursor-pointer"
            src={primeLogo}
            alt="prime logo"
            height="56"
            width="52"
          />
        </Link>
        <div className="flex flex-col sm:flex-row text-white font-display text-base">
          <div className="flex items-start flex-col">
            <div className="flex items-center">
              <img src={emailIcon} alt="email icon" />
              <a
                href={`mailto: ${FOOTER.EMAIL}?subject=Help with Prime Matter Labs Ready To Launch`}
                className="ml-3"
              >
                {FOOTER.EMAIL}
              </a>
            </div>
          </div>
          <div className="flex flex-col mt-5 sm:mt-0 text-xs sm:ml-8 space-y-4">
            <div className="mt-1">{renderFooterLinks()}</div>
            <div className="text-lightBlue sm:self-end">{FOOTER.COPYRIGHT}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};
