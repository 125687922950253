// Libraries
import React from 'react';
import * as Yup from 'yup';
// Assets
import Creams from './images/creams.png';
import CreamAndWater from './images/cream_and_water.png';
import MobileCreams from './images/mobile_creams.png';
import MobileCreamAndWater from './images/mobile_cream_and_water.png';
// Constants
import { ROUTES } from 'features/navigation';

export const QUIZ = {
  INITIAL_FORM_VALUES: {
    moq: '',
    productCategory: '',
    otherText: '',
    productSize: '',
    productType: [],
    rdInvestment: '',
  },
  FORM_VALUES: {
    productCategory: {
      items: [
        {
          title: 'Skin Care',
        },
        {
          title: 'Sun Care',
        },
        {
          title: 'Body Care',
        },
        {
          title: 'Hair Care',
        },
        {
          title: 'Fragrance',
        },
        {
          title: 'Other',
          additionalTextField: {
            name: 'otherText',
            placeholder: "Please explain what you're looking for (required)",
          },
        },
      ],
      label: {
        title: 'Choose your product category',
        subtitle: 'What kind of product are you building?',
      },
    },
    productType: {
      'Skin Care': {
        items: [
          {
            title: 'Moisturizer',
          },
          {
            title: 'Cleanser',
          },
          {
            title: 'Serum',
          },
          {
            title: 'Scrub',
          },
          {
            title: 'Toner',
          },
          {
            title: 'Lip Balm',
          },
          {
            title: 'Other',
          },
        ],
      },
      'Sun Care': {
        items: [
          {
            title: 'Chemical Sunscreen',
          },
          {
            title: 'Mineral Sunscreen',
          },
          {
            title: 'Other',
          },
        ],
      },
      'Body Care': {
        items: [
          {
            title: 'Body Lotion',
          },
          {
            title: 'Body Wash',
          },
          {
            title: 'Hand Cream',
          },
          {
            title: 'Foot Cream',
          },
          {
            title: 'Deodorant',
          },
          {
            title: 'Other',
          },
        ],
      },
      'Hair Care': {
        items: [
          {
            title: 'Shampoo',
          },
          {
            title: 'Conditioner',
          },
          {
            title: 'Hair Serum',
          },
          {
            title: 'Styling Cream',
          },
          {
            title: 'Hair Spray',
          },
          {
            title: 'Pomade',
          },
          {
            title: 'Other',
          },
        ],
      },
      Fragrance: {
        items: [
          {
            title: 'Perfume',
          },
          {
            title: 'Cologne',
          },
          {
            title: 'Other',
          },
        ],
      },
      Other: {
        items: [
          {
            title: 'Other',
          },
        ],
      },
    },
    productSize: {
      items: [
        {
          title: '0.5 ounce',
        },
        {
          title: '1.0 ounce',
        },
        {
          title: '2 ounces',
        },
        {
          title: '6 ounces',
        },
        {
          title: '8 ounces or more',
        },
      ],
      label: {
        title: 'Choose your product size',
        subtitle: 'How much of your product do you want in a bottle, etc?',
      },
    },
    moq: {
      items: [
        {
          title: '1,000 - 4,999',
        },
        {
          title: '5,000 - 9,999',
        },
        {
          title: '10,000 - 19,999',
        },
        {
          title: '20,000 - 49,999',
        },
        {
          title: '50,000 or more',
        },
      ],
      label: {
        title: 'How many units do you expect to produce in the next 12 months?',
        subtitle: 'We offer different services for different quantities.',
      },
    },
    rdInvestment: {
      items: [
        {
          title: 'Yes',
        },
        {
          title: 'No',
        },
      ],
      label: {
        title:
          'Are you ready to invest at least $5,000 for R&D to create a new product?',
        subtitle: '',
      },
    },
  },
  HEADER: <span className="text-blue-60">Lets assess your brand needs</span>,
  SUB_HEADER:
    'We will recommend the best Prime Matter Labs service to help you achieve your goals.',
  VALIDATION_SCHEMA: Yup.object().shape({
    moq: Yup.string().required(),
    productCategory: Yup.string().required(),
    otherText: Yup.string().when('productCategory', {
      is: 'Other',
      then: (schema: any) => schema.required(),
    }),
    productSize: Yup.string().required(),
    productType: Yup.array()
      .of(Yup.string())
      .when('productCategory', {
        is: (val: string) => val !== 'Other',
        then: (schema: any) => schema.min(1),
      }),
    rdInvestment: Yup.string().required(),
  }),
};

export const QUIZ_RESULTS_LOGIC = {
  availableRtlProductTypes: [
    'Moisturizer',
    'Serum',
    'Chemical Sunscreen',
    'Body Lotion',
    'Hand Cream',
    'Shampoo',
    'Conditioner',
  ],
  rdInvestmentShortCircuit: 'Yes',
  unavailableRtlProductCategories: ['Fragrance', 'Other'],
};

export const QUIZ_RESULTS_PAGE = {
  BUILD_YOUR_PROJECT: {
    analyticsEventLabel: 'Start Project Brief',
    bodyOne:
      'Based on your answers, you’re ready to develop a product that meets your needs with Custom Development.',
    bodyTwo:
      'With Custom Development, our expert team will collaborate with you to custom develop a product that brings your vision to life. Just fill out the form with details of the product and an account manager will reach out to take you through every step of the process.',
    buttonText: 'START PROJECT BRIEF',
    buttonUrl: ROUTES.CUSTOM_DEVELOPMENT.route,
    headerText: [
      <span>
        We recommend starting with Prime Matter Labs
        <br />
        <span className="text-blue-60"> Custom Development</span>
      </span>,
    ],
    image: {
      altTag: 'creams',
      src: Creams,
    },
    mobileImg: {
      altTag: 'creams',
      src: MobileCreams,
    },
    pageTitle: 'quiz/custom-development',
  },
  READY_TO_LAUNCH: {
    analyticsEventLabel: 'Order Sample',
    bodyOne:
      'Based on your answers, we recommend Prime Matter Labs Ready to Launch.',
    bodyTwo:
      'Let’s get started! Ready to Launch streamlines the typical manufacturing process for quick turnaround and speed to market. You simply customize your product sample and you’ll receive it in 2 – 3 weeks. Once you’ve approved your sample, you can order full sized products to bring to market!',
    buttonText: 'Order Sample Now',
    buttonUrl: ROUTES.MASS_CUSTOMIZATION.wizard.route,
    headerText: [
      <span>
        We recommend starting with Prime Matter Labs
        <br />
        <span className="text-blue-60"> Ready to Launch</span>
      </span>,
    ],
    image: {
      altTag: 'cream and water',
      src: CreamAndWater,
    },
    learnMoreUrl: ROUTES.MASS_CUSTOMIZATION.root.route,
    mobileImg: {
      altTag: 'cream and water',
      src: MobileCreamAndWater,
    },
    pageTitle: 'quiz/ready-to-launch',
  },
  RTL_WAITLIST: {
    analyticsEventLabel: 'Sign up for waitlist',
    bodyOne: [
      'Based on your answers, we recommend signing up for the Prime Matter Labs Ready to Launch waitlist.',
      <br />,
      <br />,
      "We'll be expanding the catalogue quarterly and will update you when your product is available.",
    ],
    bodyTwo:
      'Ready to Launch streamlines the typical manufacturing process for quick turnaround and speed to market. You simply customize your product sample and you’ll receive it in 2 – 3 weeks. Once you’ve approved your sample, you can order full sized products to bring to market!',
    buttonText: 'Sign up for waitlist',
    buttonUrl: ROUTES.MASS_CUSTOMIZATION.wizard.route,
    headerText: [
      <span>
        We recommend signing up for the Prime Matter Labs
        <span className="text-blue-60"> Ready to Launch </span>
        <br />
        waitlist
      </span>,
    ],
    image: {
      altTag: 'cream and water',
      src: CreamAndWater,
    },
    mobileImg: {
      altTag: 'cream and water',
      src: MobileCreamAndWater,
    },
    pageTitle: 'quiz/waitlist',
  },
};
