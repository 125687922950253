// Libraries
import React from 'react';
import * as Yup from 'yup';
// Components
import { ChooseBase } from '../choose-base.component';
import { GoalsStep, ExtrasStep, NameStep } from '../customization-form';
import { CustomizationSummary } from '../customization-summary';
// Assets
import serumImage from '../images/swatches/serum.png';
import lotionImage from '../images/swatches/lotion.png';
import shampooImage from '../images/swatches/shampoo.png';
import smallBottle from '../images/smallBottle.webp';
import tottle from '../images/tottle.webp';
import hairImage from '../images/hair.png';
import mobileHairImage from '../images/mobileHair.png';
// Utils
import {
  SAMPLE_PRICE,
  EXTRACTS,
  INITIAL_FORM_VALUES,
  SUMMARY_TABLE_TEXT,
  WAITLIST_TAG_NAMES,
  SPECIFICATIONS,
} from '../constants';
import { Product } from '../types';
const { algae, aloeVera, chamomile, coffee, moringa } = EXTRACTS;
const { parabens, vegan, sephora } = SPECIFICATIONS;

const GOAL_LIMIT = 2;

const hairCare: Product = {
  categoryCardProperties: {
    imageSrc: hairImage,
    mobileImageSrc: mobileHairImage,
    tags: [],
  },
  categoryDisabled: false,
  goals: [
    {
      title: 'Shine',
      subTitle:
        'Provide high shine and color enhancement to the hair using plant-based ingredients.',
      ingredients: 'Castor Oil Propanediol Esters',
    },
    {
      title: 'Scalp Care',
      subTitle:
        'Uses botanical and marine derived actives, as well as Biotin and Vitamin B, to help maintain a balanced environment for the scalp and reduce oiliness.',
      ingredients:
        'Water, Glycerin, Rose Extract, Glycyrrhiza Glabra (Licorice) Rhizome/Root, Cichorium Intybus (Chicory) Leaf Extract, Hydrolyzed Rhodophyceae Extract, Panthenol, Linoleic Acid, Menthol, Biotin',
    },
    {
      title: 'Hydration',
      subTitle:
        'Hydrate and strengthen hair through the moisture-retaining properties of Black Oats and Lecithin.',
      ingredients:
        'Glycerin, Water, Avena Strigosa Seed Extract, Lecithin, Potassium Sorbate, Citric Acid',
    },
    {
      title: 'Environmental Protection',
      subTitle:
        'Provide protection from pollution and other environmental stressors to improve hair fiber using Panthenol (Vitamin B5) and Green Tea.',
      ingredients:
        'Water, Galactoarabinan, Salvia Hispanica Seed Extract, Glycerin, Trehalose, Xylitol, Panthenol, Camellia sinensis Leaf Extract, Sodium phosphate',
    },
    {
      title: 'Curl Protection',
      subTitle:
        'Uses extracts from Chia and Flax Seed, to help increase the definition of curls, as well as reduce frizz and volume.',
      ingredients:
        'Water, Linum Usitatissimum (Linseed) Seed Extract, Salvia Hispanica Seed Extract',
    },
  ],
  goalScreenProperties: {
    goalLimit: GOAL_LIMIT,
    title: `select ${GOAL_LIMIT} option${GOAL_LIMIT > 1 ? 's' : ''}`,
    description:
      'Let’s create a formula for the goals you’d like your product to address.',
  },
  extras: [algae, aloeVera, chamomile, coffee, moringa],
  initialFormValues: {
    ...INITIAL_FORM_VALUES,
    productCategory: 'hairCare',
  },
  initialHeaderText: (
    <>
      Choose your <span className="text-blue-60">hair care base</span>
    </>
  ),
  initialSubheaderText:
    'After you select a hair care base, you’ll identify the goals you’d like your product to address.',
  initialProgressStepValues: [
    {
      stepNumber: 0,
      stepText: 'Base',
      isActive: false,
      isTouched: true,
      isCompleted: true,
      stepName: 'productBase',
    },
    {
      stepNumber: 1,
      stepText: 'Goals',
      isActive: true,
      isTouched: true,
      isCompleted: false,
      stepName: 'goals',
    },
    {
      stepNumber: 2,
      stepText: 'Extras',
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'extras',
    },
    {
      stepNumber: 3,
      stepText: 'Name',
      lastStep: true,
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'name',
    },
    {
      stepNumber: 4,
      stepText: 'Summary',
      lastStep: true,
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'summary',
    },
  ],
  productBases: [
    {
      cost: `$${SAMPLE_PRICE}`,
      details:
        'A gentle, cleansing formula that won’t strip hair. Sweet floral scent.',
      imageSrc: shampooImage,
      sampleImageSrc: tottle,
      title: 'Shampoo',
      ingredients:
        'Water, Sodium Cocoyl Isethionate, Cocamidopropyl Hydroxysultaine, Cocamidopropyl Betaine, Glycerin, SodiumMethyl Cocoyl Taurate, Glycol Distearate,Acrylates Copolymer, Glyceryl Caprylate, Fragrance, Sodium Chloride, PotassiumSorbate, Hydroxyethylcellulose, GlycerylUndecylenate, Citric Acid, ButyrospermumParkii (Shea) Butter, Olea Europaea (Olive) FruitOil, Cocos Nucifera (Coconut) Oil, PerseaGratissima (Avocado) Oil',
    },
    {
      cost: `$${SAMPLE_PRICE}`,
      details:
        'A rich formula to nourish and restore moisture. Sweet floral scent.',
      imageSrc: lotionImage,
      sampleImageSrc: tottle,
      title: 'Conditioner',
      ingredients:
        'Water, Cetearyl Alcohol, BehentrimoniumChloride, Alcohol, Phenoxyethanol, Fragrance,Amodimethicone, Cetyl Esters, PotassiumSorbate, Ethylhexylglycerin, Panthenol, SodiumPhytate, Cetrimonium Chloride, Trideceth-12,Citric Acid, Olea Europaea (Olive) Fruit Oil, Cocos Nucifera (Coconut) Oil, Persea Gratissima(Avocado) Oil',
    },
    {
      cost: `$${SAMPLE_PRICE}`,
      details:
        'A lightweight formula that absorbs quickly to deliver actives to hair.',
      imageSrc: serumImage,
      isWaitlisted: true,
      waitlistTag: WAITLIST_TAG_NAMES.hairCareHairSerum,
      sampleImageSrc: smallBottle,
      title: 'Hair Serum',
      ingredients:
        'Water, Caprylic/Capric Triglyceride, Glycerin, Dicaprylyl Ether, Cetearyl Alcohol, Sodium Stearoyl Lactylate, Hydrogenated Sunflower Seed Oil Glyceryl Esters, Hydrogenated Sunflower Seed Oil Polyglyceryl-3 Esters, Butyrospermum Parkii (Shea) Butter, Stearic Acid, Phenoxyethanol, Caprylyl Glycol, Xanthan Gum, Citric Acid, Sodium Hydroxide',
    },
  ],
  steps: [ChooseBase, GoalsStep, ExtrasStep, NameStep, CustomizationSummary],
  summaryTableText: SUMMARY_TABLE_TEXT,
  specifications: [parabens, vegan, sephora],
  title: 'Hair Care',
  validationSchema: Yup.object().shape({
    productBase: Yup.string().required(),
    goals: Yup.array().min(1).max(GOAL_LIMIT),
    name: Yup.string().required().min(1).max(25),
    itemQuantity: Yup.number().min(1).max(10),
  }),
};

export default hairCare;
