// Libraries
import React from 'react';

interface ProgressBarProps {
  percentageComplete: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  percentageComplete,
}) => {
  return (
    <div className="sticky top-[56px] sm:top-[64px] w-full h-1 bg-lightGreen">
      <div
        className="bg-legacyGreen h-1 transition-[width] duration-1000"
        style={{ width: `${percentageComplete}%` }}
      ></div>
    </div>
  );
};
