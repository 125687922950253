// Libraries
import React from 'react';
import * as Yup from 'yup';
// Components
import { ChooseBase } from '../choose-base.component';
import { GoalsStep, ExtrasStep, NameStep } from '../customization-form';
import { CustomizationSummary } from '../customization-summary';
// Assets
import serumImage from '../images/swatches/serum.png';
import lotionImage from '../images/swatches/lotion.png';
import creamImage from '../images/swatches/cream.png';
import smallJar from '../images/smallJar.webp';
import smallBottle from '../images/smallBottle.webp';
import skinImage from '../images/skin.png';
import mobileSkinImage from '../images/mobileSkin.png';
// Utils
import { Product } from '../types';
// Constants
import {
  SAMPLE_PRICE,
  EXTRACTS,
  INITIAL_FORM_VALUES,
  SUMMARY_TABLE_TEXT,
  SPECIFICATIONS,
} from '../constants';

const {
  acai,
  algae,
  aloeVera,
  chamomile,
  greenTea,
  irishMoss,
  lavender,
  lemonPeel,
  raspberry,
  tigerGrass,
} = EXTRACTS;

const { parabens, vegan, fragrance, sephora } = SPECIFICATIONS;

const GOAL_LIMIT = 2;

const skinCare: Product = {
  categoryCardProperties: {
    imageSrc: skinImage,
    mobileImageSrc: mobileSkinImage,
    tags: [],
  },
  goals: [
    {
      title: 'Elasticity',
      subTitle:
        'Improve the appearance of aging skin with skin-conditioning ingredients derived from organic legumes. Makes skin feel and look firmer and more elastic for a more youthful complexion.',
      ingredients: 'Hydrolyzed Lupine Protein',
    },
    {
      title: 'Brightness',
      subTitle:
        'Target dry and dull skin using skin-conditioning agents derived from Holy Basil traditionally used in Ayurvedic medicine. Helps restore radiance and suppleness in stressed or aging skin.',
      ingredients: 'Propanediol, Ocimum Sanctum Leaf Extract',
    },
    {
      title: 'Even Tone',
      subTitle:
        'Helps soothe and moisturize skin with ingredients derived from the Five Flavor Berry used in traditional Chinese medicine. Reduces skin redness and minimizes the appearance of spots to achieve a brighter smoother complexion.',
      ingredients: 'Saccharide Hydrolysate, Maltodextrin',
    },
    {
      title: 'Firmness',
      subTitle:
        'Using skin conditioning ingredients derived from legume seeds (sweet white lupine), it deeply moisturizes by increasing the water content on the skin. It helps reduce the appearance of sagging skin giving the skin a firmer more elastic contoured feel.',
      ingredients:
        'Helianthus Annuus (Sunflower) Seed Oil, Lupinus Albus Seed Extract, Alpha-tocopherol',
    },
    {
      title: 'Hydration',
      subTitle:
        'Provide deep hydration to the skin using ingredients derived from the seed of tropical plants. Preserve the skin’s moisture by limiting water loss and optimizing water distribution on the skin.',
      ingredients:
        'Hydrolyzed Acacia Macrostachya Seed Extract and Maltodextrin',
    },
    {
      title: 'Sensitivity',
      subTitle:
        'Help defend against the effects of indoor and outdoor pollutants and other stressors with skin nourishing ingredients derived from passionfruit. With high antioxidant content, help detoxify the skin to reveal a radiant, glowing complexion.',
      ingredients: 'Propanediol, Passiflora Edulis Fruit Extract',
    },
  ],
  goalScreenProperties: {
    goalLimit: GOAL_LIMIT,
    title: `select up to ${GOAL_LIMIT} option${GOAL_LIMIT > 1 ? 's' : ''}`,
    description:
      'Let’s create a formula for the goals you’d like your product to address.',
  },
  extras: [
    acai,
    algae,
    aloeVera,
    chamomile,
    greenTea,
    irishMoss,
    lavender,
    lemonPeel,
    raspberry,
    tigerGrass,
  ],
  initialFormValues: {
    ...INITIAL_FORM_VALUES,
    productCategory: 'skinCare',
  },
  initialHeaderText: (
    <>
      Choose your <span className="text-blue-60">skin care base</span>
    </>
  ),
  initialSubheaderText:
    'After you select a skin care base, you’ll identify the goals you’d like your product to address.',
  initialProgressStepValues: [
    {
      stepNumber: 0,
      stepText: 'Base',
      isActive: false,
      isTouched: true,
      isCompleted: true,
      stepName: 'productBase',
    },
    {
      stepNumber: 1,
      stepText: 'Goals',
      isActive: true,
      isTouched: true,
      isCompleted: false,
      stepName: 'goals',
    },
    {
      stepNumber: 2,
      stepText: 'Extras',
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'extras',
    },
    {
      stepNumber: 3,
      stepText: 'Name',
      lastStep: true,
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'name',
    },
    {
      stepNumber: 4,
      stepText: 'Summary',
      lastStep: true,
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'summary',
    },
  ],
  productBases: [
    {
      cost: `$${SAMPLE_PRICE}`,
      details:
        'Lightweight formula quickly absorbed and used daily to deliver active ingredients to the skin.',
      imageSrc: serumImage,
      sampleImageSrc: smallBottle,
      title: 'Serum',
      ingredients:
        'Water, Caprylic/Capric Triglyceride, Glycerin, Dicaprylyl Ether, Cetearyl Alcohol, Sodium Stearoyl Lactylate, Hydrogenated Sunflower Seed Oil Glyceryl Esters, Hydrogenated Sunflower Seed Oil Polyglyceryl-3 Esters, Butyrospermum Parkii (Shea) Butter, Stearic Acid, Phenoxyethanol, Caprylyl Glycol, Xanthan Gum, Citric Acid, Sodium Hydroxide',
    },
    {
      cost: `$${SAMPLE_PRICE}`,
      details:
        'Moisturizing, medium-weight formula that can be used day and night.',
      imageSrc: lotionImage,
      sampleImageSrc: smallBottle,
      title: 'Lotion',
      ingredients:
        'Water, Cetearyl Alcohol, Caprylic/Capric Triglyceride, Diisopropyl Dimer Dilinoleate, Glycerin, Butyrospermum Parkii (Shea) Butter, Stearic Acid, Sodium Stearoyl Lactylate, Hydrogenated Sunflower Seed Oil Glyceryl Esters, Hydrogenated Sunflower Seed Oil Polyglyceryl-3 Esters, Phenoxyethanol, Caprylyl Glycol, Xanthan Gum, Citric Acid, Sodium Hydroxide',
    },
    {
      cost: `$${SAMPLE_PRICE}`,
      details: 'Rich formula usually applied at night for extra nourishment.',
      imageSrc: creamImage,
      sampleImageSrc: smallJar,
      title: 'Cream',
      ingredients:
        'Water, Cetearyl Alcohol, Caprylic/Capric Triglyceride, Butyrospermum Parkii (Shea) Butter, Carthamus Tinctorius (Safflower) Seed Oil, Helianthus Annuus (Sunflower) Seed Oil, Glycerin, Stearic Acid, Sodium Stearoyl Lactylate, Hydrogenated Sunflower Seed Oil Glyceryl Esters, Hydrogenated Sunflower Seed Oil Polyglyceryl-3 Esters, Phenoxyethanol, Caprylyl Glycol, Xanthan Gum, Sodium Hydroxide',
    },
  ],
  specifications: [parabens, vegan, fragrance, sephora],
  steps: [ChooseBase, GoalsStep, ExtrasStep, NameStep, CustomizationSummary],
  summaryTableText: SUMMARY_TABLE_TEXT,
  title: 'Skin Care',
  validationSchema: Yup.object().shape({
    productBase: Yup.string().required(),
    goals: Yup.array().min(1).max(GOAL_LIMIT),
    name: Yup.string().required().min(1).max(25),
    itemQuantity: Yup.number().min(1).max(10),
  }),
};

export default skinCare;
