// Libraries
import React from 'react';
// Components
import { Benchmarks } from '../../internal-brief/benchmarks.component';
import { Typography } from 'design-system';

export const BenchmarksSection = () => {
  return (
    <div className="flex flex-col gap-12 pt-[52px]">
      <div className="flex flex-col sm:grid sm:grid-cols-9 gap-12">
        <div className="col-span-9 flex flex-col gap-3">
          <Typography variant="h2" weight="black">
            Benchmarks
          </Typography>
          <Typography variant="h4" font="inter" color="secondary">
            Identify current products in the market to benchmark your product
            against.{' '}
          </Typography>
        </div>
        <div className="col-span-6">
          <Benchmarks />
        </div>
      </div>
    </div>
  );
};
