// Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Assets
import shoppingCart from '../images/icons/shoppingCart.svg';
import mobileShoppingCart from '../images/icons/mobileShoppingCart.svg';
// Utils
import { getCart } from '../utils/local-storage.util';
import { useAnalytics, useMobile } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { ROUTES } from 'features/navigation';

interface TopNavProps {
  toggleCart: () => void;
  isCartOpen: boolean;
}

const constants = {
  logoText: 'PRIME MATTER LABS',
  navText: 'LAUNCH YOUR PRODUCT',
};

export const TopNav: React.FC<TopNavProps> = ({ toggleCart, isCartOpen }) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const [numItems, setNumItems] = useState(0);

  useEffect(() => {
    const getNumItemsFromCartLength = () => {
      setNumItems(
        getCart()
          ? JSON.parse(getCart() as string).reduce((acc: number, cv: any) => {
              return acc + cv.itemQuantity;
            }, 0)
          : 0
      );
    };
    getNumItemsFromCartLength();
    window.addEventListener('storage', getNumItemsFromCartLength);

    return () =>
      window.removeEventListener('storage', getNumItemsFromCartLength);
  }, []);

  const cartBadgeStyles = {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    fontSize: '10px',
    top: '12px',
    right: '15px',
  };

  const navigateToLandingPage = (label: string) => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCTopNav,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: label,
      nonInteraction: true,
    });
    navigate(ROUTES.MASS_CUSTOMIZATION.root.route);
  };

  const trackMarketingSiteClick = (label: string) => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCTopNav,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: label,
      nonInteraction: true,
    });
  };

  const handleCart = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCTopNav,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.shoppingCart,
      nonInteraction: true,
    });
    toggleCart();
  };

  return (
    <nav className="sm:bg-white h-16 sm:mx-5 flex sm:border sm:border-gray relative">
      <a
        onClick={() =>
          trackMarketingSiteClick(AnalyticConstants.EVENT_LABEL.primeMatterLabs)
        }
        href="https://www.primematterlabs.com/"
        target="_blank"
        rel="noreferrer"
        className="bg-black flex flex-col cursor-pointer text-white font-mono w-full py-4 px-5 sm:w-36 sm:text-center text-xs"
      >
        {constants.logoText}
        <div className="sm:hidden text-gray">{constants.navText}</div>
      </a>
      <div className="hidden sm:inline my-auto text-center flex-grow cursor-pointer">
        <span
          onClick={() =>
            navigateToLandingPage(
              AnalyticConstants.EVENT_LABEL.launchYourProduct
            )
          }
          className=" text-blue-60 font-mono text-xs"
        >
          {constants.navText}
        </span>
      </div>
      <span
        style={
          isCartOpen
            ? {
                boxShadow: '0 1px 0 #fff2e7',
              }
            : {}
        }
        className={
          'flex items-center bg-black justify-center w-16 ml-auto sm:border-l sm:border-gray z-10 ' +
          (isCartOpen ? ' bg-lightPeach ' : 'sm:bg-white')
        }
      >
        <button onClick={handleCart}>
          <img
            src={isMobile && !isCartOpen ? mobileShoppingCart : shoppingCart}
            alt="shopping-cart-icon"
          />
        </button>
      </span>
      {numItems > 0 && (
        <span
          className="absolute text-center z-50 bg-blue-60 font-mono text-white"
          style={cartBadgeStyles}
        >
          {numItems}
        </span>
      )}
    </nav>
  );
};
