// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { BriefSelect, BriefTextField, FormLayout } from '../layout';
// Utils
import { BriefFormValues } from '../../types';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';

const STRUCTURE_LABEL = 'FORM/STRUCTURE *';
const SIZE_LABEL = 'SIZE *';
const UNIT_LABEL = 'UNIT *';

const STRUCTURE_OPTIONS = [
  { name: 'Lotion', value: 'Lotion' },
  { name: 'Cream', value: 'Cream' },
  { name: 'Gel', value: 'Gel' },
  { name: 'Pump Spray', value: 'Pump Spray' },
  { name: 'Continuous Spray', value: 'Continuous Spray' },
  { name: 'Aerosol', value: 'Aerosol' },
  { name: 'Wax', value: 'Wax' },
  { name: 'Oil', value: 'Oil' },
  { name: 'Other', value: 'Other' },
];

const UNIT_OPTIONS = [
  { name: 'Ounces', value: 'oz' },
  { name: 'Milliliters', value: 'ml' },
  { name: 'Grams', value: 'g' },
];

const TITLE = "What's the product fill capacity?";

export const FillCapacity: React.FC = () => {
  const { errors, values } = useFormikContext<BriefFormValues>();
  const { structure, size, unit } = BRIEF_FORM_INPUTS;
  const hasErrors = structure in errors || size in errors || unit in errors;
  return (
    <FormLayout isNextButtonDisabled={hasErrors} title={TITLE}>
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6 sm:gap-y-6">
        <div className="sm:col-start-2 col-span-2 sm:col-span-8 mb-6">
          <BriefSelect
            formikKey={BRIEF_FORM_INPUTS.structure}
            label={STRUCTURE_LABEL}
            value={values.structure}
            options={STRUCTURE_OPTIONS}
          />
        </div>
      </div>
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6 sm:gap-y-6 ">
        <div className="sm:col-start-2 col-span-2 sm:col-span-4 mb-6 sm:mb-0">
          <BriefTextField
            label={SIZE_LABEL}
            formikKey={BRIEF_FORM_INPUTS.size}
          />
        </div>
        <div className="col-span-2 sm:col-span-4">
          <BriefSelect
            label={UNIT_LABEL}
            formikKey={BRIEF_FORM_INPUTS.unit}
            value={values.unit}
            options={UNIT_OPTIONS}
          />
        </div>
      </div>
    </FormLayout>
  );
};
