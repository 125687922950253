// Libraries
import React from 'react';

interface DoubleButtonProps {
  buttonOneAction: () => void;
  buttonOneIcon?: Maybe<React.ReactComponentElement<any>>;
  buttonOneText: string;
  buttonTwoAction: () => void;
  buttonTwoIcon?: Maybe<React.ReactComponentElement<any>>;
  buttonTwoText: string;
  iconSize?: string;
  size?: 'small' | 'medium' | 'large';
  type?: 'primary' | 'secondary' | 'soft';
  [key: string]: any;
}

const BUTTON_SIZE = {
  small: 'p-2 text-xs h-6',
  medium: 'p-[10px] text-sm h-[34px]',
  large: 'text-base h-[40px] px-3 py-[15px]',
};

const BUTTON_COLOR = {
  primary:
    'text-grey-90 bg-royal-50 border-royal-50 hover:bg-royal-40 active:bg-royal-30 focus:border-royal-30',
  secondary:
    'text-grey-20 bg-white border-grey-90 hover:bg-royal-95 active:bg-royal-90 focus:bg-white focus:border-royal-50',
  soft:
    'text-grey-20 bg-blue-95 border-grey-90 hover:bg-royal-90 active:bg-royal-80 focus:border-royal-50',
};

const SEPARATOR_COLOR = {
  primary: 'bg-white',
  secondary: 'bg-grey-90',
  soft: 'bg-grey-90',
};

export const DoubleButton: React.FC<DoubleButtonProps> = ({
  buttonOneAction,
  buttonOneIcon,
  buttonOneText,
  buttonTwoAction,
  buttonTwoIcon,
  buttonTwoText,
  iconSize,
  size = 'medium',
  type = 'primary',
  ...rest
}) => {
  let derivedIconSize;

  switch (size) {
    case 'small':
      derivedIconSize = '14px';
      break;
    case 'medium':
      derivedIconSize = '16px';
      break;
    case 'large':
      derivedIconSize = '20px';
      break;
    default:
      derivedIconSize = '16px';
  }

  return (
    <div className={` flex rounded`}>
      <button
        className={`${BUTTON_SIZE[size]} ${BUTTON_COLOR[type]} font-[500] flex gap-2 items-center border border-r-0 rounded-l`}
        onClick={buttonOneAction}
      >
        {buttonOneIcon &&
          React.cloneElement(buttonOneIcon, {
            className: `${
              rest.disabled
                ? 'text-grey-60'
                : type === 'secondary'
                ? 'text-royal-50'
                : ''
            }`,
            height: iconSize ? iconSize : derivedIconSize,
            width: iconSize ? iconSize : derivedIconSize,
          })}
        {buttonOneText}
      </button>
      <div className={`h-full w-[1px] ${SEPARATOR_COLOR[type]}`}></div>
      <button
        className={`${BUTTON_SIZE[size]} ${BUTTON_COLOR[type]} flex items-center gap-2 border border-l-0 rounded-r px-3 py-[15px]`}
        onClick={buttonTwoAction}
      >
        {buttonTwoText}
        {buttonTwoIcon &&
          React.cloneElement(buttonTwoIcon, {
            className: `${
              rest.disabled
                ? 'text-grey-60'
                : type === 'secondary'
                ? 'text-royal-50'
                : ''
            }`,
            height: iconSize ? iconSize : derivedIconSize,
            width: iconSize ? iconSize : derivedIconSize,
          })}
      </button>
    </div>
  );
};

export default DoubleButton;
