// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
// Assets
import productsFour from '../images/icons/products4.svg';
// Constants
import { ROUTES } from 'features/navigation';

interface IMessageBox {
  imageSrc?: string;
  children: React.ReactNode[] | React.ReactNode | string;
}

export const DontSeeYourProduct = () => {
  return (
    <MessageBox imageSrc={productsFour}>
      <span className="ml-3">
        Don't see what you're looking for? Send us a{' '}
        <Link
          className="cursor-pointer text-blue-60"
          to={ROUTES.CREATE_BRIEF.route}
        >
          Project Brief
        </Link>{' '}
        and we’ll contact you about your project.
      </span>
    </MessageBox>
  );
};

export const MessageBox: React.FC<IMessageBox> = ({ children, imageSrc }) => {
  return (
    <div className="flex items-center sm:bg-legacyPeach font-display py-6 pl-6">
      {imageSrc && <img src={imageSrc} alt="" className="h-10" />}
      {children}
    </div>
  );
};
