// Libraries
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
// Assets
import dropdownArrow from '../../../assets/dropdownArrow.png';

interface IBriefSelect {
  formikKey: string;
  formikKeyToNullify?: string;
  label?: string;
  options: Option[];
  value: string;
}

type Option = {
  name: string;
  value: string;
};

export const BriefSelect: React.FC<IBriefSelect> = ({
  formikKey,
  formikKeyToNullify,
  label,
  options,
  value,
}) => {
  const [isShowingOptions, setIsShowingOptions] = useState<boolean>(false);
  const { setFieldValue, validateField } = useFormikContext();

  const handleOptionClick = async (option: Option) => {
    if (formikKeyToNullify) {
      await setFieldValue(formikKeyToNullify, '');
      await validateField(formikKeyToNullify);
    }
    setFieldValue(formikKey, option.value);
    setIsShowingOptions(false);
  };

  const renderOptions = () => {
    return (
      <div className="border font-display text-lg bg-white">
        {options.map((option) => {
          return (
            <div
              key={option.name}
              className="hover:bg-darkPeach px-6 py-2"
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label className="font-mono text-xs font-semibold mb-3">{label}</label>
      )}
      <button
        onClick={() => setIsShowingOptions(!isShowingOptions)}
        className={`${
          isShowingOptions && ''
        } w-full border border-black px-6 py-4 bg-white text-left`}
      >
        <div className="flex justify-between">
          <span
            className={`font-display text-lg ${
              value ? 'text-black' : 'text-gray'
            }`}
          >
            {value || 'Choose'}
          </span>
          <img src={dropdownArrow} alt="" />
        </div>
      </button>
      {isShowingOptions && renderOptions()}
    </div>
  );
};
