// Libraries
import React from 'react';
// Components
import { Page } from 'features/ready-to-launch/ui';
// Assets
import products3 from 'features/ready-to-launch/images/icons/products3.svg';

export const NotFound = () => {
  return (
    <Page>
      <div className="flex flex-col mt-16 mb-14 items-center">
        <img height="72" width="72" src={products3} alt="products" />
        <h3 className="font-headline text-5xl mt-6 mb-3">404 Page Not Found</h3>
        <h6 className="font-display text-xl">
          Sorry, we couldn't find the page you're looking for!
        </h6>
      </div>
    </Page>
  );
};
