// Libraries
import React, { useState, useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import {
  StepContext,
  Button,
  IncrementDecrement,
} from 'features/ready-to-launch/ui';
// Assets
import blueCheckMark from 'features/ready-to-launch/images/icons/blueCheckMark.svg';
import priceStar from 'features/ready-to-launch/images/icons/priceStar.svg';
// Utils
import { useAnalytics } from 'hooks';
import {
  Extra,
  Goal,
  IMassCustomizationScreen,
  ProductBase,
} from 'features/ready-to-launch/types';
import { determineCongratsScreenTitle } from 'features/ready-to-launch/utils';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const INGREDIENTS_TRUNCATE_AFTER_NUMBER = 4;
const ADDITIONAL_INGREDIENTS_TRUNCATE_AFTER_NUMBER = 2;
const PARABENS = 'parabens';
const CONDITIONER = 'Conditioner';
const ALTERNATE_PARABEN_COPY = 'No Parabens, Paraffins, or Sulfates';

// Styles
const generateTruncatedTextStyles = (
  lineLength: number
): React.CSSProperties => {
  // These styles handle truncating text that is large than one line
  return {
    display: '-webkit-box',
    WebkitLineClamp: lineLength,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };
};

interface ITableCellTitle {
  title: string;
}
const TableCellTitle: React.FC<ITableCellTitle> = ({ title }) => {
  return (
    <h6 className="font-mono text-sm pb-1 text-gray font-semibold">{title}</h6>
  );
};

interface ITableCell {
  children: React.ReactNode[] | React.ReactNode;
}

const TableCell: React.FC<ITableCell> = ({ children }) => {
  return <div className="py-6 px-8">{children}</div>;
};

export const SummaryTable: React.FC<IMassCustomizationScreen> = ({
  product,
}) => {
  const analytics = useAnalytics();
  const {
    values: { productBase, extras, goals, name },
    submitForm,
    resetForm,
  } = useFormikContext<any>();
  const { resetWizard } = useContext(StepContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);

  const getAdditionalIngredients = () => {
    const selectedGoals = goals.map((goalName: string) =>
      product.goals.find((goal: Goal) => goal.title === goalName)
    );

    const selectedExtras = extras.map((extraName: string) =>
      product.extras.find((extra: Extra) => extra.title === extraName)
    );

    return selectedGoals
      .concat(selectedExtras)
      .map((item: Extra & Goal) => item.ingredients)
      .join(', ');
  };

  const additionalIngredients = getAdditionalIngredients();
  const selectedProductBase = product.productBases.find(
    (base: ProductBase) => base.title === productBase
  );
  const productBaseIngredients = selectedProductBase?.ingredients;
  const sampleImage = selectedProductBase?.sampleImageSrc;
  const summaryTableText = product.summaryTableText;

  const handleSubmission = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCCongratsSummary,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.addToCart,
      nonInteraction: true,
    });
    submitForm().then(() => {
      setTimeout(() => resetForm(), 0);
      setIsBtnDisabled(true);
      resetWizard();
    });
  };

  const renderSpecifications = () => {
    return product.specifications.map((specification, index) => {
      const isParabenSpec = specification.title === PARABENS;
      return (
        <div
          key={`${specification}-${index}}`}
          className="flex font-mono text-white items-start mb-2 text-xs font-semibold"
        >
          <img src={blueCheckMark} alt="" />
          <div className="ml-2 w-full">
            {isParabenSpec && productBase === CONDITIONER
              ? ALTERNATE_PARABEN_COPY.toUpperCase()
              : specification.copy.toUpperCase()}
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="relative grid grid-cols-3 grid-rows-6 col-span-3"
      style={{ height: 612, top: '-6rem' }}
    >
      <div className="flex flex-col justify-between col-span-1 row-span-6">
        {/* PACKAGING IMAGE */}
        <div className="flex flex-wrap h-full items-center justify-center border border-gray bg-lightBlue">
          <div className="absolute top-4 left-12">
            <div className="relative text-center">
              <h3
                className="absolute top-1/2 left-1/2 select-none font-display text-[1.875rem]"
                style={{ transform: 'translate(-50%, -50%) rotate(-15deg)' }}
              >
                {summaryTableText.sampleCost}
              </h3>
              <img src={priceStar} alt={summaryTableText.sampleCost} />
            </div>
          </div>
          <img
            style={{ maxHeight: 438 }}
            className="px-30 py-10"
            src={sampleImage}
            alt="sample container"
          />
        </div>
        {/* STANDARDS && CERTS */}
        <div className="flex flex-col justify-center p-6 bg-black text-white">
          {renderSpecifications()}
        </div>
      </div>
      <div className="grid grid-cols-2 grid-rows-5 col-span-2 row-span-6">
        {/* PRODUCT TITLE */}
        <div className="flex justify-between col-span-2 row-span-1 bg-lightPeach border border-gray border-l-0">
          <div className="flex flex-col justify-center pl-8 w-1/2">
            <h4
              title={`${name} ${determineCongratsScreenTitle(
                product,
                productBase
              )}`}
              className="font-display text-[1.875rem] whitespace-nowrap overflow-hidden text-ellipsis"
            >
              {`${name} ${determineCongratsScreenTitle(product, productBase)}`}
            </h4>
            <h6 className="font-display text-base">
              {summaryTableText.twoOunceSample}
            </h6>
          </div>
          <div className="flex items-center">
            <div className="bg-white mr-3">
              <IncrementDecrement />
            </div>
            <div className="mr-8">
              <Button
                variant="lg"
                handleAction={handleSubmission}
                disabled={isBtnDisabled}
              >
                {summaryTableText.buttonText}
              </Button>
            </div>
          </div>
        </div>
        {/* GOALS ROW */}
        <div className="col-span-2 row-span-1 bg-white border-r border-gray">
          <TableCell>
            <TableCellTitle title="GOALS" />
            <h5
              className="font-display text-lg truncate cursor-default"
              title={goals.join(' & ')}
            >
              {goals.join(' & ')}
            </h5>
          </TableCell>
        </div>
        {/* EXTRAS ROW */}
        <div className="col-span-2 row-span-1 bg-white border-r border-gray border-t">
          <TableCell>
            <TableCellTitle title={summaryTableText.extrasTitle} />
            <h5
              className="font-display text-lg truncate cursor-default"
              title={extras.length ? extras.join(', ') : 'No extras selected'}
            >
              {extras.length ? extras.join(', ') : 'No extras selected'}
            </h5>
          </TableCell>
        </div>
        {/* ingredients */}
        <div className="col-span-2 row-span-3 bg-white border border-gray border-l-0">
          <TableCell>
            <TableCellTitle title={summaryTableText.ingredientsTitle} />
            <p
              className="text-gray font-display text-sm cursor-default"
              title={productBaseIngredients}
              style={generateTruncatedTextStyles(
                INGREDIENTS_TRUNCATE_AFTER_NUMBER
              )}
            >
              {productBaseIngredients}
            </p>
            <br />
            <TableCellTitle title={summaryTableText.addlIngredientsTitle} />
            <p
              className="text-gray font-display text-sm cursor-default"
              title={additionalIngredients}
              style={generateTruncatedTextStyles(
                ADDITIONAL_INGREDIENTS_TRUNCATE_AFTER_NUMBER
              )}
            >
              {additionalIngredients}
            </p>
          </TableCell>
        </div>
      </div>
    </div>
  );
};
