// Libraries
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import { HowDoesItWork } from './how-does-it-work.component';
import { FAQ } from './faq.component';
import { LandingHeader } from './landing-header.component';
import { Page, Modal } from 'features/ready-to-launch/ui';
// Assets
import science2 from 'features/ready-to-launch/images/icons/science2.svg';
// Constants
import { ROUTES } from 'features/navigation';

const MODAL_TEXT = 'Starting your customization';

const MODAL_DELAY = 1500;

export const LandingPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeHandler = () => {
    setIsModalOpen(false);
  };

  const sendToWizard = () => {
    setIsModalOpen(true);
    setTimeout(() => {
      setIsModalOpen(false);
      navigate(ROUTES.MASS_CUSTOMIZATION.wizard.route);
    }, MODAL_DELAY);
  };

  const renderModal = () => {
    if (!isModalOpen) return;
    return (
      <Modal onClose={closeHandler}>
        <div className="flex sm:flex-row flex-col my-auto items-center justify-center">
          <img className="" src={science2} alt="image2" />
          <div className="font-headline text-[3rem] max-w-sm">{MODAL_TEXT}</div>
        </div>
      </Modal>
    );
  };

  return (
    <Page>
      {renderModal()}
      <LandingHeader sendToWizard={sendToWizard} />
      <HowDoesItWork />
      <FAQ sendToWizard={sendToWizard} />
    </Page>
  );
};
