import { API_TYPES } from 'features/constants';

// TODO: this interface was moved to api/types.ts
// do not import from here, eventually will be removed
export interface IApiResponse {
  data?: Maybe<IApiData & IApiData[]>;
  error?: string;
  errors?: string[];
  errorMessage?: string;
  exception?: string;
  included: IApiData[];
  status: number;
}

// TODO: this interface was moved to api/types.ts
// do not import from here, eventually will be removed
interface IRelationship {
  data: {
    id: string;
    type?: keyof typeof API_TYPES;
  };
}

// TODO: this interface was moved to api/types.ts
// do not import from here, eventually will be removed
export interface IApiRelationships {
  [type: string]: IRelationship[] & IRelationship;
}

// TODO: this interface was moved to api/types.ts
// do not import from here, eventually will be removed
export interface IApiData {
  id: string;
  attributes:
    | CompanyAttributes
    | EmployeeAttributes
    | FormulaAttributes
    | InciAttributes
    | IngredientAttributes
    | PriceAttributes
    | RawMaterialAttributes
    | SampleAttributes
    | SampleSubmissionAttributes
    | any;
  type?: keyof typeof API_TYPES;
  relationships: IApiRelationships;
}

export type IngredientAttributes = {
  active: boolean;
  amount: number | string;
  areEuInciFactorsVerified?: boolean;
  areEuIncisVerified?: boolean;
  areUsInciFactorsVerified?: boolean;
  areUsIncisVerified?: boolean;
  formulaUuid: string;
  highestPrice: Maybe<PriceAttributes>;
  key: Maybe<boolean>;
  lotNumber: Maybe<string>;
  manufacturer: Maybe<any>;
  mayContain: boolean;
  position: number;
  rawMaterialEuIncis: Array<any>;
  rawMaterialLocation: string;
  rawMaterialName: string;
  rawMaterialRmId: Maybe<string>;
  rawMaterialSampleCode: Maybe<string>;
  rawMaterialTradeName: string;
  rawMaterialUsIncis: Array<any>;
  rawMaterialUuid: string;
  solvent: boolean;
  supplier: Maybe<any>;
  usApiDrugPurposes: string[];
  usIncisVerified: false;
};

export type InciAttributes = {
  casNumber: Maybe<string>;
  createdAt: Date;
  ecNumber: Maybe<string>;
  euName: string;
  functions: string[];
  harmonizedName: Maybe<string>;
  kind: string;
  name: Maybe<string>;
  updatedAt: Date;
  usName: string;
};

export type RawMaterialAttributes = {
  // Classifications
  canadaRestricted: Maybe<boolean>;
  canadaRestrictedExplanation: Maybe<string>;
  crueltyFree: Maybe<boolean>;
  crueltyFreeExplanation: Maybe<string>;
  euRestricted: Maybe<boolean>;
  euRestrictedExplanation: Maybe<string>;
  glutenFree: Maybe<boolean>;
  glutenFreeExplanation: Maybe<string>;
  p65Restricted: Maybe<boolean>;
  p65RestrictedExplanation: Maybe<string>;
  reachRegistered: Maybe<boolean>;
  reachRegisteredExplanation: Maybe<string>;
  reachRestricted: Maybe<boolean>;
  reachRestrictedExplanation: Maybe<string>;
  vegan: Maybe<boolean>;
  veganExplanation: Maybe<string>;

  // EU Verification
  euInciFactorsVerified: unknown;
  euInciFactorsVerifiedBy: unknown;
  euIncisVerified: unknown;
  euIncisVerifiedBy: unknown;
  // US Verification
  usInciFactorsVerified: unknown;
  usInciFactorsVerifiedBy: unknown;
  usIncisVerified: unknown;
  usIncisVerifiedBy: unknown;

  // Raw Material
  createdAt: Date;
  functions: Array<string>;
  location: string;
  marketingDescription: string;
  marketingName: string;
  name: string;
  notes: string;
  origin: Array<string>;
  rmId: Maybe<string>;
  sampleCode: Maybe<string>;
  synonyms: Array<string>;
  tradeName: string;
  updatedAt: Date;

  // INCIS
  euIncis: Array<any>;
  incis: { data: Array<any> };
  usIncis: Array<any>;
};

export type CompanyAttributes = {
  name: string;
  customer: IApiData;
};

export type FormulaAttributes = {
  appearance?: string;
  batchQuantity?: string;
  color?: string;
  companyName: string;
  companyUuid: string;
  createdAt: string;
  description?: string;
  directionsForUse: string;
  dryWeight: number;
  externalId: string;
  featuresBenefits?: string;
  name: string;
  observations?: string;
  odor?: string;
  pH24h?: number;
  pHInitial?: number;
  packaging?: string;
  phMax?: number;
  phMin?: number;
  procedure: string[];
  projectExternalId?: string;
  projectName: string;
  projectUuid: string;
  recommendedFor?: string;
  sg24h?: number;
  sgInitial?: number;
  sgMax?: number;
  sgMin?: number;
  stability?: string;
  status?: string;
  usage?: string;
  visc24hReadingNum?: number;
  visc24hReadingPercent?: number;
  visc24hSpindle?: string;
  visc24hSpindleSpeed?: number;
  viscInitialReadingNum?: number;
  viscInitialReadingPercent?: number;
  viscInitialSpindle?: string;
  viscInitialSpindleSpeed?: number;
};

export type SampleSubmissionAttributes = {
  additionalNotes?: string;
  dateCreated: string;
  submissionFormId: string;
};

export type SampleAttributes = {
  customerFeedback: string;
  labNotes: string;
  lotNumber: string;
  packaging: string;
  quantity: number;
  size: number;
  unit: string;
};

export type EmployeeAttributes = {
  giveName: string;
  familyName: string;
  fullName: string;
  avatarColor: string;
  location: string;
  searchLocation: string;
};

export type Vendor = {
  id: string;
  name: string;
};

export enum PURCHASE_QUANTITY_UNITS {
  lb = 'lb',
  kg = 'kg',
  g = 'g',
}

export enum MOQ_UNITS {
  lb = 'lb',
  kg = 'kg',
  dollars = 'dollars',
}

export type LeadTimeUnits = 'days' | 'weeks' | 'months' | '';

export type Price = {
  id: string;
  cost: number;
  unit: keyof typeof PURCHASE_QUANTITY_UNITS | '';
  leadTimeNumber?: string;
  leadTimeUnits: LeadTimeUnits;
  location: string;
  moq?: string | number;
  moqUnit?: keyof typeof MOQ_UNITS | '';
  quantityPurchased?: string | number;
  receivedAt?: string;
  received_at?: string;
  manufacturer?: Vendor;
  supplier?: Vendor;
  uuid?: string;
};

export interface PriceAttributes extends Price {}

export interface ITableColumnCollection {
  [key: string]: ITableColumn;
}

export interface ITableColumn {
  align: 'left' | 'right';
  columnWidth: string;
  headerText?: string;
  padding?: string;
  includeAsFilter?: boolean;
}
