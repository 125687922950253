//Libraries
import { ITrackEventParams } from './analytics.context';
import ReactGA from 'react-ga4';

//Utils
import * as analyticsConstants from 'features/analytics/analytics.constants';

declare global {
  interface Window {
    Rollbar: any;
  }
}

ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA4_DESTINATION_ID!,
  },
  {
    trackingId: process.env.REACT_APP_GA4_ANALYTICS_ID!,
  },
]);

export const analytics = () => {
  return {
    pageView: (page: string) => {
      /* set the page first, this associates the current page
         with any subsequent events */
      ReactGA.set({ page: page });
      /* then send the a pageview interaction event to GA */
      ReactGA.send({
        hitType: analyticsConstants.HIT_TYPES.pageView,
        page: page,
        title: page,
      });
    },

    trackEvent: ({
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      nonInteraction,
    }: ITrackEventParams) => {
      ReactGA.event({
        category: eventCategory,
        action: eventAction,
        label: eventLabel, // optional
        value: eventValue, // optional, must be a number
        nonInteraction: nonInteraction, // optional, true/false
      });
    },
  };
};
