// Libraries
import React from 'react';
// Components
import { Divider, Typography } from 'design-system';
import {
  Benefits,
  FillSize,
  HaveYouBroughtThisProductToMarketBefore,
  IngredientNotes,
  InitialOrderQuantity,
  Otc,
  TargetCogs,
  Timeline,
} from '../../form-modules';
// import { PrimeTip } from '../prime-tip.component';

// const PRIME_TIP = [
//   'Have multiple products in mind? No problem. Start with one, then a Prime representative will contact you to learn about additional needs. You’ll also have an option to leave us notes on the last page of this form.',
// ];

interface AdditionalInfoProps {
  projectIsRtl: boolean;
}

export const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  projectIsRtl,
}) => {
  return (
    <div className="flex flex-col gap-12 pt-[52px] pb-[144px]">
      <div className="flex flex-col sm:grid sm:grid-cols-9 gap-12">
        <div className="col-span-9 flex flex-col gap-3">
          <Typography variant="h2" weight="black">
            Additional Info
          </Typography>
          <Typography variant="h4" font="inter" color="secondary">
            Tell us more about your product.
          </Typography>
        </div>
        <div className="col-span-6">
          <FillSize />
        </div>
        {/* <div className="sm:col-span-3 col-span-6">
          <PrimeTip contents={PRIME_TIP} />
        </div> */}
        <div className="col-span-6">
          <Divider />
        </div>
        <div className="col-span-6">
          <InitialOrderQuantity />
          <div className="-mt-3 mb-12">
            <Divider />
          </div>
          <Timeline />
        </div>
        {!projectIsRtl && (
          <>
            <div className="col-span-6">
              <Divider />
            </div>
            <div className="col-span-6">
              <Otc />
            </div>
            <div className="col-span-6">
              <Divider />
            </div>
            <div className="col-span-6">
              <TargetCogs />
            </div>
            <div className="col-span-6">
              <Divider />
            </div>
            <div className="col-span-6">
              <IngredientNotes />
            </div>
            {/* <div className="col-span-3">
              <PrimeTip contents={PRIME_TIP} />
            </div> */}
            <div className="col-span-6">
              <Divider />
            </div>
            <div className="col-span-6">
              <HaveYouBroughtThisProductToMarketBefore />
            </div>
            <div className="col-span-6">
              <Divider />
            </div>
            <div className="col-span-6">
              <Benefits />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
