// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
// Components
import { Page, PageHeader, Step, Button } from './ui';
import { ProgressStepper, MobileProgressIndicator } from './customization-form';
import { Product, ProgressStepType } from './types';
// Utils
import products from './products/products.util';
import { useAnalytics, useMobile } from 'hooks';
import { ProductCategoryType } from './types';
import { determineCongratsScreenTitle } from './utils';
// Assets
import dropper from './images/icons/dropper.svg';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { STEP_NUMBER } from './constants';
import { ROUTES } from 'features/navigation';

interface IWizard {
  productCategory?: ProductCategoryType;
}

// 🧙‍♂️ 🔮
export const Wizard: React.FC<IWizard> = ({ productCategory }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!productCategory)
      navigate(ROUTES.MASS_CUSTOMIZATION.productCategory.route);
  }, [productCategory, navigate]);

  const PRODUCT: Maybe<Product> = productCategory
    ? products[productCategory]
    : undefined;
  const INITIAL_PROGRESS_STEPS = PRODUCT
    ? PRODUCT.initialProgressStepValues
    : [];
  const STEPS = PRODUCT ? PRODUCT.steps : [];
  const analytics = useAnalytics();
  const { isMobile } = useMobile();
  const [currentStep, setCurrentStep] = useState<number>(STEP_NUMBER.zero);
  const [isShoppingCartOpen, setIsShoppingCartOpen] = useState<boolean>(false);
  const {
    values: { productBase },
    errors,
  } = useFormikContext<any>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const isInitialStep = currentStep === STEP_NUMBER.zero;
  const isSummaryStep = currentStep === STEPS.length - 1;

  const [progressSteps, setProgressSteps] = useState<ProgressStepType[]>(
    // Creating a duplicate set of steps in order to preserve initial state
    INITIAL_PROGRESS_STEPS.map((step: ProgressStepType) => ({ ...step }))
  );

  const STEP_HEADER_TEXT = {
    determineHeader: (stepNumber: number, productBase?: string) => {
      switch (stepNumber) {
        case STEP_NUMBER.zero:
          return PRODUCT?.initialHeaderText;
        case STEPS.length - 1:
          return (
            <>
              Congrats on creating your{' '}
              <span className="text-blue-60">
                {PRODUCT &&
                  productBase &&
                  determineCongratsScreenTitle(PRODUCT, productBase)}
              </span>
              !
            </>
          );
        default:
          return (
            <>
              Customize your <span className="text-blue-60">{productBase}</span>
            </>
          );
      }
    },
    determineSubheader: (stepNumber: number) => {
      switch (stepNumber) {
        case STEP_NUMBER.zero:
          return PRODUCT?.initialSubheaderText;
        case STEPS.length - 1:
          return 'Choose how many samples you want and then add to cart.';
        default:
          break;
      }
    },
  };

  const setForwardProgress = (currentStep: number, nextStep: number) => {
    const currentStepToUpdate = progressSteps[currentStep];
    const nextStepToUpdate = progressSteps[nextStep];
    currentStepToUpdate.isCompleted = true;
    currentStepToUpdate.isActive = false;
    nextStepToUpdate.isActive = true;
    nextStepToUpdate.isTouched = true;
    setProgressSteps([...progressSteps]);
  };

  const setBackwardProgress = (currentStep: number, prevStep: number) => {
    const currentStepToUpdate = progressSteps[currentStep];
    const prevStepToUpdate = progressSteps[prevStep];
    currentStepToUpdate.isActive = false;
    prevStepToUpdate.isActive = true;
    setProgressSteps([...progressSteps]);
  };

  const decrementStep = () => {
    setCurrentStep((currentStep) => {
      const prevStep = currentStep - 1;

      if (prevStep < 0) {
        return currentStep;
      } else {
        analytics?.trackEvent({
          eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCBackButton,
          eventAction: AnalyticConstants.EVENT_ACTIONS.click,
          eventLabel: `From ${INITIAL_PROGRESS_STEPS[currentStep].stepName} back to ${INITIAL_PROGRESS_STEPS[prevStep].stepName}`,
          nonInteraction: true,
        });

        setBackwardProgress(currentStep, prevStep);
        setIsShoppingCartOpen(false);
        return prevStep;
      }
    });
  };

  const incrementStep = () => {
    setCurrentStep((currentStep) => {
      const nextStep = currentStep + 1;

      if (nextStep >= STEPS.length) {
        return currentStep;
      } else {
        currentStep > STEP_NUMBER.zero &&
          setForwardProgress(currentStep, nextStep);
        setIsShoppingCartOpen(false);
        analytics?.trackEvent({
          eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCNextButton,
          eventAction: AnalyticConstants.EVENT_ACTIONS.click,
          eventLabel: `From ${INITIAL_PROGRESS_STEPS[currentStep].stepName} to ${INITIAL_PROGRESS_STEPS[nextStep].stepName}`,
          nonInteraction: true,
        });
        return nextStep;
      }
    });
  };

  const skipStep = (stepToSkipTo: number) => {
    setCurrentStep((currentStep) => {
      const category = `MC${INITIAL_PROGRESS_STEPS[currentStep].stepText}`;

      analytics?.trackEvent({
        eventCategory: AnalyticConstants.EVENT_CATEGORIES[category],
        eventAction: AnalyticConstants.EVENT_ACTIONS.click,
        eventLabel: `Step ${stepToSkipTo}`,
        nonInteraction: true,
      });

      progressSteps[currentStep].isActive = false;
      progressSteps[stepToSkipTo].isActive = true;
      setProgressSteps([...progressSteps]);
      setIsShoppingCartOpen(false);
      return stepToSkipTo;
    });
  };

  const resetWizard = () => {
    setIsShoppingCartOpen(true);
    // Creating a duplicate set of steps in order to preserve initial state
    setProgressSteps(
      INITIAL_PROGRESS_STEPS.map((step: ProgressStepType) => ({ ...step }))
    );
    setCurrentStep(STEP_NUMBER.zero);
  };

  const renderSteps = (productBase: string) => {
    return STEPS.map((Component: React.FC<{ product: any }>, index: number) => {
      return (
        <Step
          key={index}
          stepNumber={index}
          currentStep={currentStep}
          currentStepName={INITIAL_PROGRESS_STEPS[currentStep].stepName}
          incrementStep={incrementStep}
          decrementStep={decrementStep}
          resetWizard={resetWizard}
        >
          <Page initialCartState={isShoppingCartOpen}>
            <div className="mb-3 sm:mb-0">
              <PageHeader
                header={STEP_HEADER_TEXT.determineHeader(
                  currentStep,
                  productBase
                )}
                subheader={STEP_HEADER_TEXT.determineSubheader(currentStep)}
              >
                {isInitialStep ? (
                  <img
                    width={isMobile ? 52 : 96}
                    className="sm:self-start"
                    src={dropper}
                    alt="dropper"
                  />
                ) : (
                  ''
                )}
              </PageHeader>

              {!isInitialStep && !isSummaryStep && (
                <ProgressStepper steps={progressSteps} skipStep={skipStep} />
              )}
            </div>
            <div
              className={`bg-lightestBlue sm:pb-20 ${
                isSummaryStep ? 'mt-24 sm:mt-14' : ''
              }`}
            >
              {isMobile && !isInitialStep && !isSummaryStep && (
                <MobileProgressIndicator
                  currentStep={currentStep}
                  totalSteps={STEPS.slice(1, STEPS.length - 1).length}
                  stepName={INITIAL_PROGRESS_STEPS[
                    currentStep
                  ].stepText.toUpperCase()}
                />
              )}
              <Component product={PRODUCT} />
              {isMobile &&
                currentStep >= STEP_NUMBER.one &&
                currentStep <= STEP_NUMBER.three && (
                  <div className="bg-black w-full p-4 sticky bottom-0">
                    <Button
                      disabled={
                        !!errors[INITIAL_PROGRESS_STEPS[currentStep].stepName]
                      }
                      fullWidth
                      variant="md"
                      handleAction={incrementStep}
                    >
                      Next
                    </Button>
                  </div>
                )}
            </div>
          </Page>
        </Step>
      );
    });
  };

  return <>{renderSteps(productBase)}</>;
};
