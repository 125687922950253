//Libraries
import React, { useEffect, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
//Components
import CustomerApp from 'features/customer-app.component';
import EmployeeApp from 'features/employee-app.component';
import LeftNav from './navigation/left-nav.component';
import { ErrorBoundary } from 'features/error';
import {
  UserContext,
  MobileContextProvider,
  SnackbarContextProvider,
} from 'context';
import { Loader, Footer } from 'features/ui';
import { Navigation } from 'features/navigation';
// Constants
import { ROLES } from 'features/rbac';
import { LeftNavContextProvider } from 'context/left-nav-context';

const App: React.FC = () => {
  const { user, isLoading, isAuthenticated } = useAuth0();

  const { roles, setRoles } = useContext(UserContext)!;

  const [areRolesLoading, setAreRolesLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      setRoles(user['http://www.primematterlabs.com/roles']);
      setAreRolesLoading(false);
    }
  }, [user, setRoles]);

  if (isLoading || (isAuthenticated && areRolesLoading)) {
    return <Loader />;
  }

  const isEmployee = roles && roles.includes(ROLES.EMPLOYEE);

  return (
    <div className="relative min-h-screen">
      <div data-testid={isEmployee ? 'employee-app' : 'customer-app'}>
        <MobileContextProvider>
          <SnackbarContextProvider>
            <ErrorBoundary>
              {isEmployee ? (
                <EmployeeApp>
                  <LeftNavContextProvider>
                    <Navigation isEmployee={isEmployee} />
                    <LeftNav />
                  </LeftNavContextProvider>
                </EmployeeApp>
              ) : (
                <>
                  <CustomerApp>
                    <Navigation />
                  </CustomerApp>
                  <div className="bg-white absolute bottom-0 w-full">
                    <Footer />
                  </div>
                </>
              )}
            </ErrorBoundary>
          </SnackbarContextProvider>
        </MobileContextProvider>
      </div>
    </div>
  );
};

export default App;
