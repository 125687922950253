// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Utils
import { BriefFormValues } from '../../types';

interface ITypeCard {
  handleClick: React.MouseEventHandler<HTMLDivElement>;
  name: string;
  image: JSX.Element;
}

export const TypeCard: React.FC<ITypeCard> = ({ handleClick, image, name }) => {
  const { values } = useFormikContext<BriefFormValues>();
  const isSelected = values['productType'].includes(name);
  return (
    <div
      onClick={handleClick}
      className={`${
        isSelected ? 'bg-lightPeach' : 'bg-white'
      } font-headline sm:mb-0 mb-5 text-[1.875rem] flex items-center hover:bg-lightPeach justify-between h-[7.5rem] sm:col-span-4 px-6 py-7 border shadow-choiceCard cursor-pointer`}
    >
      <div>{name}</div>
      {image}
    </div>
  );
};
