// Libraries
import React from 'react';
// Utils
import { useMobile } from 'hooks';

interface PageHeaderProps {
  header: React.ReactNode[] | React.ReactNode;
  subheader?: string | React.ReactNode[] | React.ReactNode;
  children?: React.ReactNode[] | React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  header,
  subheader,
  children,
}) => {
  const { isMobile } = useMobile();

  if (isMobile) {
    return (
      <div className="flex flex-col mb-10 mt-16 px-5 justify-between">
        <div className="flex">
          <h1 className="font-headline font-[900] text-[1.875rem]">{header}</h1>
          {children}
        </div>
        {subheader && (
          <p className="font-display text-xl mt-5 pr-6">{subheader}</p>
        )}
      </div>
    );
  }
  return (
    <div className="flex mt-24 mx-24 justify-between">
      <div>
        <h1 className="font-headline font-[900] text-[3rem]">{header}</h1>
        {subheader && (
          <p className="font-display text-lg mt-5 pr-6 sm:mb-10">{subheader}</p>
        )}
      </div>
      {children}
    </div>
  );
};

// Example usage:
// const PAGE_HEADER_TEXT = {
//   header: (
//     <>
//       Choose your <span className="text-blue-60">product base</span>
//     </>
//   ),
//   subheader:
//     'After you select a base for your sample you’ll determine it’s desired benefits, extras and fragrances (each base has the same customization options).',
// };

// const Component = () => {
//   return (
//     <Page>
//       <PageHeader
//         header={PAGE_HEADER_TEXT.header}
//         subheader={PAGE_HEADER_TEXT.subheader}
//       >
//         <img className="self-start" src={samplesOne} alt="sample-one" />
//         {/* <p className="font-display underline text-xs whitespace-nowrap mt-6 cursor-pointer h-fit">
//           Preview ingredients
//         </p> */}
//       </PageHeader>
//     </Page>
//   );
// };
