// Libraries
import React from 'react';
// Components
import { Button } from 'features/ready-to-launch/ui';
// Utils
import { useAnalytics, useMobile } from 'hooks';
// Assets
import RightArrow from 'features/ready-to-launch/images/icons/arrowRight.svg';
import { ReactComponent as Bullet } from 'features/ready-to-launch/images/icons/bullet.svg';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const LANDING_HEADER_TEXT = {
  tag: 'WELCOME TO PRIME MATTER LABS READY TO LAUNCH',
  title: 'A trusted approach to creating custom skin, hair & sun care products',
  description: (
    <>
      <p className="mb-4">
        We’ve made it easy for you to create your dream product, with a process
        that’s backed by cosmetic science and formulation expertise.
      </p>
      <p>
        Build a sample in minutes, and once your product is perfected, you’ll
        determine the final order size.
        <span className="font-bold p-1">
          Order minimums as low as 1000 available.
        </span>
      </p>
    </>
  ),
  buttonText: 'Build your product',
};

const PRODUCT_NAMES = ['SKIN CARE', 'SUN CARE', 'HAIR CARE', 'BODY CARE'];

export const LandingHeader: React.FC<any> = ({ sendToWizard }) => {
  const analytics = useAnalytics();
  const { isMobile } = useMobile();

  const handleButtonClick = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCLandingPage,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.buildYourProductOne,
      nonInteraction: true,
    });
    sendToWizard();
  };

  return (
    <div
      className="sm:bg-landing-hero bg-landing-hero-mobile bg-no-repeat mt-10 bg-auto"
      style={{
        backgroundPositionX: '100%',
        height: '36.5rem',
        backgroundSize: '60% 100%',
      }}
    >
      <div className="flex sm:grid grid-cols-3 gap-5 px-5 sm:px-24.5">
        <div className="col-span-2 mt-14">
          <h6 className="text-sm text-gray font-mono font-medium mb-4">
            {LANDING_HEADER_TEXT.tag}
          </h6>
          <h1 className="text-[1.875rem] sm:text-[3rem] leading-10 text-blue-60 font-headline mb-4 pr-11 sm:pr-28">
            {LANDING_HEADER_TEXT.title}
          </h1>
          <div className="font-display text-base mb-8 pr-8 sm:pr-32">
            {LANDING_HEADER_TEXT.description}
          </div>
          <ul className="flex text-gray text-sm font-mono mb-10">
            {PRODUCT_NAMES.map((name, index) => {
              return (
                <li
                  key={index}
                  className={`flex items-center ${
                    index === 0 ? 'ml-0' : 'ml-3'
                  }`}
                >
                  <Bullet />
                  <span className="ml-3 font-semibold">{name}</span>
                </li>
              );
            })}
            <li className="flex items-center ml-3">
              <Bullet />
            </li>
          </ul>
          <Button
            imageSrc={RightArrow}
            handleAction={handleButtonClick}
            variant="lg"
            fullWidth={isMobile}
          >
            {LANDING_HEADER_TEXT.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
