// Libraries
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
// Components
import { CharacterLimit } from './character-limit.component';
// Utils
import { useMobile } from 'hooks';
// Constants
import { KEY_PRESSES } from 'features/constants';

interface IContentEditableTextField {
  inputName: string;
  placeholder: string;
  remainingText: string;
  characterLimit: number;
}

const OVERFLOW_THRESHOLD = 10;

export const ContentEditableTextField: React.FC<IContentEditableTextField> = ({
  inputName,
  characterLimit,
  placeholder,
  remainingText,
}) => {
  const { isMobile } = useMobile();
  const { setFieldValue, values } = useFormikContext<any>();
  const [isEditingContent, setIsEditingContent] = useState<boolean>(false);
  const [content, setContent] = useState<string>(values.name || '');

  const handleInput = (e: any) => {
    setContent(e.target.value);
    setFieldValue(inputName, e.target.value);
  };

  if (isMobile) {
    return (
      <>
        <div className="font-display text-[1.875rem] mx-auto flex flex-col items-center overflow-hidden">
          {isEditingContent ? (
            <input
              className="text-black text-center bg-lightestBlue caret-black focus:outline-none"
              // prevents cutting off of text that is larger than expected
              // i.e. (lots of spaces, all caps, etc)
              type="text"
              autoFocus
              maxLength={characterLimit}
              value={content}
              onBlur={() => setIsEditingContent(false)}
              onChange={handleInput}
            />
          ) : (
            <span
              className="cursor-text text-center text-gray"
              onClick={() => setIsEditingContent(true)}
            >
              {content ? content : placeholder}
            </span>
          )}
          <span
            onClick={() => setIsEditingContent(true)}
            className="text-blue-60"
          >
            {' '}
            {remainingText}
          </span>
        </div>
        <div className="flex justify-center">
          <CharacterLimit
            contentLength={content.length}
            characterLimit={characterLimit}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="font-display text-[1.875rem] mx-auto flex justify-center overflow-hidden">
        <div className="flex flex-col">
          <div className="text-center">
            {isEditingContent ? (
              <input
                className="text-black bg-lightestBlue caret-black text-center focus:outline-none"
                // prevents cutting off of text that is larger than expected
                // i.e. (lots of spaces, all caps, etc)
                size={characterLimit + OVERFLOW_THRESHOLD}
                type="text"
                autoFocus
                maxLength={characterLimit}
                value={content}
                onBlur={() => setIsEditingContent(false)}
                onChange={handleInput}
              />
            ) : (
              <span
                tabIndex={0}
                className="cursor-text text-gray"
                onClick={() => setIsEditingContent(true)}
                onKeyDown={(e) => {
                  if (e.key === KEY_PRESSES.ENTER) {
                    setIsEditingContent(true);
                  }
                }}
              >
                {content ? content : placeholder}
              </span>
            )}
          </div>
          <div className="text-center">
            <span
              onClick={() => setIsEditingContent(true)}
              className="text-blue-60"
            >
              {' '}
              {remainingText}
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <CharacterLimit
          contentLength={content.length}
          characterLimit={characterLimit}
        />
      </div>
    </>
  );
};
