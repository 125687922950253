// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import { StepContext, Button } from 'features/ready-to-launch/ui';
// Utils
import { useMobile } from 'hooks';

interface IFormCTA {
  description: string;
  stepName: string;
  title: string;
}

export const FormCTA: React.FC<IFormCTA> = ({
  title,
  description,
  stepName,
}) => {
  const { isMobile } = useMobile();
  const { incrementStep } = useContext(StepContext);
  const { errors } = useFormikContext<any>();

  return (
    <div className="flex justify-between">
      <div className="flex flex-col">
        <div className="font-mono text-xs text-blue-60">
          {title.toUpperCase()}
        </div>
        <div className="font-display sm:text-lg">{description}</div>
      </div>
      {!isMobile && (
        <Button
          disabled={!!errors[stepName]}
          handleAction={incrementStep}
          variant="lg"
        >
          Next
        </Button>
      )}
    </div>
  );
};
