// Libraries
import React, { useContext, useState } from 'react';
import { Field } from 'formik';
// Components
import BigChoiceCard from './ui/bigChoiceCard';
import { WaitListModal } from 'features/quiz';
import { DontSeeYourProduct, StepContext } from './ui';
// Utils
import { useAnalytics } from 'hooks';
import { IMassCustomizationScreen, ProductBase } from './types';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { INPUT_NAMES } from './constants';

export const ChooseBase: React.FC<IMassCustomizationScreen> = ({ product }) => {
  const analytics = useAnalytics();
  const { incrementStep } = useContext(StepContext);
  const [isWishlistModalOpen, setIsWishlistModalOpen] = useState<boolean>(
    false
  );
  const [waitlistTag, setWaitlistTag] = useState<Maybe<string>>(undefined);

  const handleClick = (productBase: string) => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCChooseBase,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: productBase,
      nonInteraction: true,
    });
    incrementStep();
  };

  const handleWaitlistClick = (wlt: Maybe<string>, productBase: string) => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCChooseBase,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: `${productBase} waitlist`,
      nonInteraction: true,
    });
    setWaitlistTag(wlt);
    setIsWishlistModalOpen(true);
  };

  const renderChoiceCards = () => {
    return product.productBases.map(
      (
        {
          title,
          cost,
          details,
          imageSrc,
          isWaitlisted,
          waitlistTag,
        }: ProductBase,
        index: number
      ) => (
        <div key={`${title}-${index}`} className="col-span-1">
          <Field
            name={INPUT_NAMES.productBase}
            component={BigChoiceCard}
            details={details}
            handleClick={() =>
              isWaitlisted
                ? handleWaitlistClick(waitlistTag, title)
                : handleClick(title)
            }
            imageSrc={imageSrc}
            isWaitlisted={isWaitlisted}
            title={title}
            titleDetail={cost}
          />
        </div>
      )
    );
  };

  return (
    <div className="flex flex-col sm:grid sm:grid-cols-3 sm:px-24.5 px-5 gap-5 pb-20 pt-10 bg-lightestBlue">
      <WaitListModal
        isOpen={isWishlistModalOpen}
        handleClose={() => setIsWishlistModalOpen(false)}
        waitlistTag={waitlistTag}
      />
      {renderChoiceCards()}
      <div className="col-span-3">
        <DontSeeYourProduct />
      </div>
    </div>
  );
};
