// Libraries
import React from 'react';
import { useFormikContext, Field } from 'formik';
// Components
import { Checkbox, RadioButton } from 'features/ui';
// Utils
import { useAnalytics } from 'hooks';
import { FormSelectListItem } from 'features/ready-to-launch/types';
// Constants
import { KEY_PRESSES } from 'features/constants';
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { capitalize } from '@material-ui/core';
import { QUIZ } from 'features/quiz/constants';

interface FormSelectListProps {
  analyticsPrefix?: string;
  inputName: string;
  items: FormSelectListItem[];
  limit?: number;
  type: 'checkbox' | 'radio';
}

export const FormSelectList: React.FC<FormSelectListProps> = ({
  inputName,
  items,
  limit,
  type,
  analyticsPrefix = '',
}) => {
  // productType & productCategory come from the quiz
  const PRODUCT_CATEGORY = 'productCategory';
  const PRODUCT_TYPE = 'productType';
  const isProductType = inputName === PRODUCT_TYPE;
  const isProductCategory = inputName === PRODUCT_CATEGORY;
  const analytics = useAnalytics();
  const { values, setFieldValue } = useFormikContext<any>();

  const trackClick = (label: string, track = true) => {
    const category = !isProductType
      ? `${analyticsPrefix}${capitalize(values.productCategory)}${capitalize(
          inputName
        )}`
      : // Use formikContext's productCategory to determine correct ga event label
        values.productCategory;

    if (track) {
      analytics?.trackEvent({
        eventCategory:
          AnalyticConstants.EVENT_CATEGORIES[category] ||
          AnalyticConstants.EVENT_CATEGORIES[capitalize(inputName)],
        eventAction: AnalyticConstants.EVENT_ACTIONS.click,
        eventLabel: label,
        nonInteraction: true,
      });
    }
  };

  return (
    <>
      <div className="border border-b-0 w-full">
        {items.map((item, idx) => {
          const { title, subTitle, value, fieldsToClear } = item;
          const isSelected =
            values[inputName].includes(title) ||
            values[inputName].includes(value);
          if (isSelected && fieldsToClear) {
            fieldsToClear.forEach((fieldToClear) => {
              values[fieldToClear].length !== 0 &&
                setFieldValue(
                  fieldToClear,
                  Array.isArray(values[fieldToClear]) ? [] : ''
                );
            });
          }
          const isDisabled =
            limit && values[inputName].length >= limit && !isSelected;
          return (
            <React.Fragment key={`${title}-${idx}`}>
              <label
                tabIndex={0}
                onKeyDown={(event) => {
                  if (
                    event.key === KEY_PRESSES.ENTER &&
                    (event.target as Element).nodeName === 'LABEL'
                  ) {
                    const updatedList = [...values[inputName]];

                    if (isSelected) {
                      const foundItemIndex = updatedList.findIndex(
                        (t) => t === title || t === value
                      );
                      updatedList.splice(foundItemIndex, 1);
                      setFieldValue(inputName, updatedList, true);
                      return;
                    }

                    if (limit && updatedList.length < limit) {
                      setFieldValue(
                        inputName,
                        [...updatedList, value || title],
                        true
                      );
                      return;
                    }

                    if (!limit) {
                      setFieldValue(
                        inputName,
                        [...updatedList, value || title],
                        true
                      );
                      return;
                    }
                  }
                }}
              >
                <Field
                  disabled={!!isDisabled}
                  type={type}
                  name={inputName}
                  onClick={() => {
                    trackClick(title, !isSelected);
                    isProductCategory &&
                      setFieldValue(
                        PRODUCT_TYPE,
                        QUIZ.INITIAL_FORM_VALUES.productType
                      );
                  }}
                  value={value || title}
                  className="hidden"
                />
                <div className="flex flex-col border-b">
                  <div
                    key={`${title}-${idx}`}
                    className="cursor-pointer flex items-center font-display sm:px-7 sm:py-4 px-6 py-4"
                  >
                    <div className="mr-6">
                      {type === 'radio' ? (
                        <RadioButton
                          inputName={inputName}
                          title={title}
                          value={value}
                        />
                      ) : (
                        <Checkbox
                          inputName={inputName}
                          title={title}
                          value={value}
                        />
                      )}
                    </div>
                    <div
                      className={`flex flex-col ${
                        isDisabled ? ' text-gray' : ''
                      }`}
                    >
                      <div className="text-lg">{title}</div>
                      <div>{subTitle}</div>
                    </div>
                  </div>
                  {item?.additionalTextField && isSelected && (
                    <div className="w-full px-7 pb-6">
                      <Field
                        name={item.additionalTextField.name}
                        className="w-full border-b border-lightGray focus:outline-none font-display"
                        placeholder={item.additionalTextField.placeholder}
                      />
                    </div>
                  )}
                  {item?.expandedContent && isSelected && item.expandedContent}
                </div>
              </label>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};
