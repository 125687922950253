// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import {
  StepContext,
  Button,
  ContentEditableTextField,
} from 'features/ready-to-launch/ui';
// Utils
import { useAnalytics, useMobile } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
import { INPUT_NAMES } from 'features/ready-to-launch/constants';

const PLACEHOLDER_TEXT = 'Click to name your';
const CHARACTER_LIMIT = 25;
const BUTTON_TEXT = 'Finish building product';

export const NameStep: React.FC = () => {
  const analytics = useAnalytics();
  const { isMobile } = useMobile();
  const { incrementStep } = useContext(StepContext);
  const {
    values: { productBase },
    errors,
  } = useFormikContext<any>();

  const handleButtonClick = () => {
    incrementStep();
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCName,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.finishBuildingProduct,
      nonInteraction: true,
    });
  };

  return (
    <div className="pt-14 pb-72">
      <ContentEditableTextField
        inputName={INPUT_NAMES.name}
        placeholder={PLACEHOLDER_TEXT}
        remainingText={productBase}
        characterLimit={CHARACTER_LIMIT}
      />
      <div className="flex justify-center mt-8 mx-5 sm:mx-0">
        <Button
          disabled={!!errors.name}
          fullWidth={isMobile}
          variant="md"
          handleAction={handleButtonClick}
        >
          {BUTTON_TEXT}
        </Button>
      </div>
    </div>
  );
};
