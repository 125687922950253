// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Button } from 'design-system';
import { ScrollIntoView } from './scroll-into-view.component';
// Utils
import { CustomerBriefFormValues } from '../internal-brief/form.config';
import {
  BRIEF_FORM_KEYS,
  RESTART_CONFIRM_STATE,
  RTL_CONFIRM_STATE,
  YES_NO_CHOICE,
} from '../brief.constants';
import { useRtlIconMap } from '../rtl-logic';
import { useConfirmation } from 'hooks';
// Assets
import { ReactComponent as ReadyToLaunchIcon } from '../../../img/ready-to-launch.svg';

interface TintProps {
  projectIsRtl?: boolean;
}

export const Tint: React.FC<TintProps> = ({ projectIsRtl }) => {
  const { getFieldProps, setFieldValue, values } = useFormikContext<
    CustomerBriefFormValues
  >();

  const rtlIconMap = useRtlIconMap({
    values,
    moduleKey: BRIEF_FORM_KEYS.TINT,
  });
  const { confirm } = useConfirmation();

  const handleNonRtlClick = async (value: string) => {
    const shouldProceed = await confirm(RTL_CONFIRM_STATE);

    if (shouldProceed) {
      setFieldValue(BRIEF_FORM_KEYS.TINT, value);
    } else {
      return;
    }
  };

  const tintedIsRtl = rtlIconMap[BRIEF_FORM_KEYS.TINT]
    ? rtlIconMap[BRIEF_FORM_KEYS.TINT][YES_NO_CHOICE.YES]
    : false;
  const notTintedIsRtl = rtlIconMap[BRIEF_FORM_KEYS.TINT]
    ? rtlIconMap[BRIEF_FORM_KEYS.TINT][YES_NO_CHOICE.NO]
    : false;

  return (
    <ScrollIntoView>
      {({ ref }) => (
        <div ref={ref} className="flex flex-col gap-3">
          <p className="font-inter text-base font-bold text-grey-20">Tinted</p>
          <div className="flex items-center space-x-3">
            <Button
              additionalStyles={tintedIsRtl ? 'py-3 px-7' : 'py-3 px-10'}
              text="Yes"
              size="large"
              type="secondary"
              data-testid={`tint-${YES_NO_CHOICE.YES}`}
              {...getFieldProps(BRIEF_FORM_KEYS.TINT)}
              leadingIcon={
                tintedIsRtl ? (
                  <ReadyToLaunchIcon className="bg-peach-50" />
                ) : undefined
              }
              selected={values[BRIEF_FORM_KEYS.TINT] === YES_NO_CHOICE.YES}
              action={
                tintedIsRtl || !projectIsRtl
                  ? async () => {
                      if (values.tint && values.countries.length > 0) {
                        const shouldProceed = await confirm(
                          RESTART_CONFIRM_STATE
                        );

                        if (!shouldProceed) return;
                      }
                      setFieldValue(BRIEF_FORM_KEYS.TINT, YES_NO_CHOICE.YES);
                    }
                  : () => handleNonRtlClick(YES_NO_CHOICE.YES)
              }
              checked={values[BRIEF_FORM_KEYS.TINT] === YES_NO_CHOICE.YES}
            />
            <Button
              additionalStyles={notTintedIsRtl ? 'py-3 px-7' : 'py-3 px-10'}
              text="No"
              size="large"
              type="secondary"
              data-testid={`tint-${YES_NO_CHOICE.NO}`}
              {...getFieldProps(BRIEF_FORM_KEYS.TINT)}
              leadingIcon={
                notTintedIsRtl ? (
                  <ReadyToLaunchIcon className="bg-peach-50" />
                ) : undefined
              }
              selected={values[BRIEF_FORM_KEYS.TINT] === YES_NO_CHOICE.NO}
              action={
                notTintedIsRtl || !projectIsRtl
                  ? async () => {
                      if (values.tint && values.countries.length > 0) {
                        const shouldProceed = await confirm(
                          RESTART_CONFIRM_STATE
                        );

                        if (!shouldProceed) return;
                      }

                      setFieldValue(BRIEF_FORM_KEYS.TINT, YES_NO_CHOICE.NO);
                    }
                  : () => handleNonRtlClick(YES_NO_CHOICE.NO)
              }
              checked={values[BRIEF_FORM_KEYS.TINT] === YES_NO_CHOICE.NO}
            />
          </div>
        </div>
      )}
    </ScrollIntoView>
  );
};
