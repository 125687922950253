// Libraries
import React from 'react';
// Components
import { Page } from 'features/ready-to-launch/ui/page.component';

const LAST_MODIFIED_DATE = 'June 17, 2022';

export const ReturnPolicyPage = () => {
  return (
    <Page>
      <div className="max-w-5xl mx-auto my-16 shadow-returnPolicy">
        <div className="flex flex-col p-16 font-display">
          <span className="mx-auto text-6xl text-blue-60">Return Policy</span>
          <br />
          <span>Last Modified: {LAST_MODIFIED_DATE}</span>
          <br />
          <span className="underline">Refunds</span>
          <span>
            All sales of samples are final and no refund will be issued.
          </span>
          <br />
          <span className="underline">Questions</span>
          <span>
            If you have any questions concerning our return policy, please
            contact us at:{' '}
            <a className="text-blue-60" href="mailto:rtl@primematterlabs.com">
              rtl@primematterlabs.com
            </a>
            .
          </span>
        </div>
      </div>
    </Page>
  );
};
