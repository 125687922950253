// Libraries
import React from 'react';
// Assets
import { ReactComponent as ThinArrowRight } from 'features/ready-to-launch/images/icons/thinArrowRight.svg';
import { IMassCustomizationScreen } from 'features/ready-to-launch/types';
// Utils
import { useMobile } from 'hooks';

interface IProductCategoryCard extends IMassCustomizationScreen {
  goToWizard: (productCategory: string) => void;
}

export const ProductCategoryCard: React.FC<IProductCategoryCard> = ({
  goToWizard,
  product: {
    categoryDisabled,
    initialFormValues: { productCategory },
    productBases,
    categoryCardProperties: { imageSrc, mobileImageSrc, tags },
    title,
  },
}) => {
  const { isMobile } = useMobile();

  return (
    <div
      className="flex flex-col border w-11/12 sm:w-1/2 mt-5 sm:mt-0 sm:mx-5 cursor-pointer shadow-categoryCard"
      onClick={() => !categoryDisabled && goToWizard(productCategory)}
      style={
        categoryDisabled ? { filter: 'grayscale(100%)', cursor: 'default' } : {}
      }
    >
      <div className="flex flex-grow bg-white hover:bg-legacyGreen">
        <div className="absolute ml-auto h-7">
          {tags.map((tag, idx) => (
            <div
              key={idx}
              className="bg-black text-white font-mono font-semibold text-sm px-2 py-1 mb-1 w-fit-content"
            >
              {tag}
            </div>
          ))}
        </div>
        <img
          src={isMobile ? mobileImageSrc : imageSrc}
          style={{
            minWidth: isMobile ? '122px' : '',
          }}
          alt=""
          className="h-full object-cover"
        />
        <div className="flex flex-col w-full">
          <div className="mt-8 ml-6 sm:ml-7">
            <h3 className="font-headline text-[1.875rem] font-black mb-3">
              {title}
            </h3>
            <ul className="font-display text-gray">
              {productBases.map((pb) => (
                <li key={pb.title}>{pb.title}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-lightPeach">
        <div className="flex justify-between py-3 px-5">
          <div className="font-display text-lg">
            {categoryDisabled ? 'Coming Soon' : 'Build Product'}
          </div>
          {!categoryDisabled && <ThinArrowRight className="self-center" />}
        </div>
      </div>
    </div>
  );
};
