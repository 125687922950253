// Libraries
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Bars3Icon,
  BookOpenIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DocumentPlusIcon,
  HomeIcon,
  PlusCircleIcon,
} from '@heroicons/react/16/solid';
// Components
import { LeftNavContext, MobileContext, UserContext } from 'context';
// import { Typography } from 'design-system';
// Utils
import { useAuth0 } from '@auth0/auth0-react';
// Assets
import ElementLogoLight from 'img/element-logo-light.svg';
import ElementLogoLightSmall from 'img/element-logo-light-small.svg';
// Constants
import { ROUTES } from './navigation.constants';

const LeftNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // TODO: Uncomment once titles have been removed from pages in mobile view
  // const { pathname } = location;
  const { logout } = useAuth0();

  const { isLeftNavOpen, setIsLeftNavOpen } = useContext(LeftNavContext);
  const { isMobile } = useContext(MobileContext);
  const {
    userSession: { givenName, familyName },
  } = useContext(UserContext)!;
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState<boolean>(false);

  const justify = isLeftNavOpen ? 'justify-start' : 'justify-center';

  const initials = `${givenName?.charAt(0) || ''}${
    familyName?.charAt(0) || ''
  }`;

  // TODO: Uncomment once titles have been removed from pages in mobile view
  // type RouteKey = keyof typeof ROUTES;
  // type RouteItem = { text: string; route: string };

  type MenuItem = {
    displayText: string;
    href: string;
    icon: JSX.Element;
  };

  type Group = {
    title?: string;
    items: MenuItem[];
  };

  const MENU_ITEMS: Record<string, Group> = {
    projectGroup: {
      items: [
        {
          displayText: 'Dashboard',
          href: ROUTES.HOME.route,
          icon: <HomeIcon className="h-5 w-5" />,
        },
        {
          displayText: 'New Brief',
          href: ROUTES.CREATE_INTERNAL_BRIEF.route,
          icon: <DocumentPlusIcon className="h-5 w-5" />,
        },
      ],
    },
    rawMaterialGroup: {
      title: isLeftNavOpen ? 'RAW MATERIALS' : 'RM',
      items: [
        {
          displayText: 'Raw Materials Dictionary',
          href: ROUTES.RAW_MATERIALS.route,
          icon: <BookOpenIcon className="h-5 w-5" />,
        },
        {
          displayText: 'Add Raw Material',
          href: ROUTES.CREATE_RAW_MATERIAL.route,
          icon: <PlusCircleIcon className="h-5 w-5" />,
        },
      ],
    },
    inciGroup: {
      title: isLeftNavOpen ? 'INCIS' : 'INCI',
      items: [
        {
          displayText: 'INCI Glossary',
          href: ROUTES.INCIS.route,
          icon: <BookOpenIcon className="h-5 w-5" />,
        },
        {
          displayText: 'Add INCI',
          href: ROUTES.CREATE_INCI.route,
          icon: <PlusCircleIcon className="h-5 w-5" />,
        },
      ],
    },
  };

  const handleClick = (action: () => void) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsSettingsMenuOpen(false);
    action();
  };

  // TODO: Uncomment once titles have been removed from pages in mobile view
  // const findTextByRoute = (routeToFind: string): string | null => {
  //   for (const key in ROUTES) {
  //     const routeObj = ROUTES[key as RouteKey] as RouteItem;
  //     if (routeObj.route === routeToFind) {
  //       return routeObj.text;
  //     }
  //   }
  //   return null; // Return null or a default value if no match is found
  // };

  const renderNavMenuItems = () => {
    return Object.keys(MENU_ITEMS).map((groupKey, index) => {
      const group = MENU_ITEMS[groupKey as keyof typeof MENU_ITEMS];
      return (
        <div key={index} className="flex flex-col gap-2 mt-9 text-white">
          {group?.title && (
            <div
              className={`text-xs ${
                isLeftNavOpen ? 'text-left' : 'text-center'
              } px-2 font-mono text-grey-50 font-[500]`}
            >
              {group.title.toUpperCase()}
            </div>
          )}
          {group.items.map((item: MenuItem, index: number) => {
            const isActive = item.href === location.pathname;
            return (
              <div
                onClick={handleClick(() => navigate(item.href))}
                key={index}
                className={`h-12 flex items-center transition-transform duration-1000 ${justify} p-2 cursor-pointer ${
                  isActive ? '' : 'hover:bg-grey-30'
                } rounded-sm ${isActive ? 'bg-black' : ''}`}
              >
                {item.icon}
                {isLeftNavOpen && (
                  <div className="ml-2 text-sm font-semibold font-inter">
                    {item.displayText}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };

  return isMobile && !isLeftNavOpen ? (
    <div
      onClick={() => setIsLeftNavOpen(!isLeftNavOpen)}
      className="fixed left-0 top-0 mb-6 flex items-center gap-[10px] w-full h-12 p-3 z-[1250]"
    >
      <Bars3Icon className="w-6 h-6" />
      {/*TODO: Uncomment once titles have been removed from pages in mobile view */}
      {/* <Typography variant="h4">{findTextByRoute(pathname)}</Typography> */}
    </div>
  ) : (
    <div
      onClick={() => setIsLeftNavOpen(!isLeftNavOpen)}
      className={`fixed left-0 top-0 z-[1250] min-h-screen px-2 py-4 ${
        isLeftNavOpen ? 'w-60' : 'w-16'
      } flex flex-col bg-grey-20 border-r cursor-pointer`}
    >
      <div className={`h-48px flex ${justify}`}>
        <img
          src={isLeftNavOpen ? ElementLogoLight : ElementLogoLightSmall}
          alt="element logo large"
        />
      </div>
      <div className="flex justify-end h-3 w-full relative">
        <div
          className={`absolute ${
            isMobile ? '-top-[54px]' : 'top-2'
          } -right-[22px] p-[2px] flex items-center justify-center bg-grey-20 border border-white rounded-full`}
        >
          <ChevronRightIcon
            height="20"
            width="20"
            className={` text-white z-10 ${isLeftNavOpen ? 'rotate-180' : ''}`}
          />
        </div>
      </div>
      {renderNavMenuItems()}
      <div
        onClick={(event) => {
          event.stopPropagation();
          setIsSettingsMenuOpen(!isSettingsMenuOpen);
        }}
        className={`flex mt-auto ${justify} items-center ${
          isLeftNavOpen ? 'ml-[6px]' : ''
        }`}
      >
        <div className=" bg-teal-90 w-8 h-8 flex items-center justify-center rounded-full border p-4">
          <h6 className="text-xs text-grey-20 font-semibold">
            {initials.toUpperCase()}
          </h6>
        </div>
        {isLeftNavOpen && (
          <div className="flex ml-2 items-center w-full justify-between text-white font-inter font-semibold text-sm">
            <span className="mr-4">
              {givenName} {familyName}
            </span>
            <ChevronDownIcon
              className={`transition-transform duration-300 ${
                isSettingsMenuOpen ? 'rotate-180' : ''
              }`}
              height="16"
              width="16"
            />
          </div>
        )}
      </div>
      {isSettingsMenuOpen && (
        <div className="absolute min-w-[180px] shadow-md rounded-sm flex flex-col bottom-[58px] left-4 right-2 bg-white">
          <div
            onClick={handleClick(() => navigate(ROUTES.PROFILE.route))}
            className="p-4 rounded-t-sm border-b border-b-grey-90 hover:bg-blue-95"
          >
            Settings
          </div>
          <div
            onClick={handleClick(logout)}
            className="p-4 rounded-b-sm hover:bg-blue-95"
          >
            Log Out
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftNav;
