// Libraries
import React from 'react';
// Components
import { GiveSampleFeedback } from './give-sample-feedback.component';
// Utils
import { Task, TaskStatus } from 'features/home/customer-portal/types';
import { useAnalytics } from 'hooks';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

interface SampleFeedbackActionsProps {
  task: Task;
  handleOpenSampleFeedbackModal: () => void;
}

export const SampleFeedbackActions: React.FC<SampleFeedbackActionsProps> = ({
  task,
  handleOpenSampleFeedbackModal,
}) => {
  const analytics = useAnalytics()!;

  const handleShowModalClick = () => {
    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.sampleFeedbackModel,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.openFeedbackModal,
    });

    handleOpenSampleFeedbackModal();
  };

  if (task.status === TaskStatus.pending || task.status === TaskStatus.done) {
    return (
      <GiveSampleFeedback
        isSampleFeedbackTaskCompleted={task.status === TaskStatus.done}
        handleOpenSampleFeedbackModal={handleShowModalClick}
      />
    );
  }

  return null;
};
