// Libraries
import * as Yup from 'yup';
// Utils
import { cogsType } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS, PRODUCT_CATEGORIES } from '../brief.constants';

export const formulaSectionValidationSchema = Yup.object().shape({
  [BRIEF_FORM_KEYS.PROJECT_NAME]: Yup.string().required().label('Project Name'),
  [BRIEF_FORM_KEYS.PRODUCT_CATEGORY]: Yup.object()
    .shape({
      value: Yup.string().required().label('Product Category'),
      label: Yup.string().required().label('Product Category'),
    })
    .nullable()
    .required()
    .label('Product Category'),
  [BRIEF_FORM_KEYS.PRODUCT_TYPE]: Yup.object()
    .shape({
      value: Yup.string().required().label('Product Type'),
      label: Yup.string().required().label('Product Type'),
    })
    .nullable()
    .required()
    .label('Product Type'),
  [BRIEF_FORM_KEYS.STRUCTURE]: Yup.string().required().label('Structure'),
  [BRIEF_FORM_KEYS.SPF]: Yup.string().when(BRIEF_FORM_KEYS.PRODUCT_CATEGORY, {
    is: (pc: Option) => pc.value === PRODUCT_CATEGORIES.SUN_CARE,
    then: (schema) => schema.required().label('SPF'),
  }),
  [BRIEF_FORM_KEYS.TINT]: Yup.string().when(BRIEF_FORM_KEYS.PRODUCT_CATEGORY, {
    is: (pc: Option) => pc.value === PRODUCT_CATEGORIES.SUN_CARE,
    then: (schema) => schema.required().label('Tint'),
  }),
  [BRIEF_FORM_KEYS.COUNTRIES]: Yup.array()
    .of(Yup.string().required().label('Country'))
    .min(1, 'At least one country is required')
    .label('Countries'),
});

const sharedAdditionalInfoValidationSchema = Yup.object().shape({
  [BRIEF_FORM_KEYS.SIZE]: Yup.number()
    .required()
    .typeError('Fill Size Quantity must be a number')
    .label('Fill Size Quantity'),
  [BRIEF_FORM_KEYS.UNIT]: Yup.string().required().label('Unit'),
  [BRIEF_FORM_KEYS.MINIMUM_ORDER_QUANTITY]: Yup.number()
    .typeError(({ label }) => label + ' must be a number')
    .required()
    .label('Initial Order Quantity'),
  [BRIEF_FORM_KEYS.TARGET_DATE]: Yup.date()
    .typeError('Target Launch Date must be a valid date: mm/dd/yy')
    .required()
    .label('Target Launch Date'),
});

export const rtlAdditionalInfoValidationSchema = Yup.object().shape({
  ...sharedAdditionalInfoValidationSchema.fields,
});

export const customDevelopmentAdditionalInfoValidationSchema = Yup.object().shape(
  {
    ...sharedAdditionalInfoValidationSchema.fields,
    [BRIEF_FORM_KEYS.COGS_TYPE]: Yup.string().required(
      'Target COGS is required'
    ),
    [BRIEF_FORM_KEYS.COGS_RANGE]: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).when(BRIEF_FORM_KEYS.COGS_TYPE, {
      is: (ct: string) => ct === cogsType.Range,
      then: Yup.object({
        label: Yup.string().required('COGS Range is required'),
        value: Yup.string().required('COGS Range is required'),
      }),
    }),
    [BRIEF_FORM_KEYS.TARGET_COST]: Yup.string().when(
      BRIEF_FORM_KEYS.COGS_TYPE,
      {
        is: (ct: string) => ct === cogsType.Specific,
        then: Yup.string().required('COGS Specific Value is required'),
      }
    ),
  }
);

export const allRTLValidationsSchema = Yup.object().shape({
  ...formulaSectionValidationSchema.fields,
  ...rtlAdditionalInfoValidationSchema.fields,
});

export const allCustomDevelopmentValidationsSchema = Yup.object().shape({
  ...formulaSectionValidationSchema.fields,
  ...customDevelopmentAdditionalInfoValidationSchema.fields,
});
