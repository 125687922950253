// Libraries
import React from 'react';
// Components
// Assets
import done from '../../../assets/done.png';
import incomplete from '../../../assets/incomplete.png';

interface IStepListItem {
  isComplete: boolean;
  stepNumber: number;
  text: string;
}

const STEP = 'STEP';

const StepListItem: React.FC<IStepListItem> = ({
  isComplete,
  stepNumber,
  text,
}) => {
  return (
    <div className="flex mb-5">
      <img
        className="h-10 w-10 self-center"
        src={isComplete ? done : incomplete}
        alt={isComplete ? 'done' : 'incomplete'}
      />
      <div className="flex flex-col ml-5">
        <div
          className={`font-mono text-sm font-semibold ${
            isComplete ? 'text-gray' : 'text-blue-60'
          }`}
        >
          {STEP} {stepNumber}
        </div>
        <div
          className={`font-display text-[1.75rem] sm:text-[28px] ${
            isComplete ? 'text-gray' : ''
          }`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default StepListItem;
