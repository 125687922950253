import skinCare from './skin-care.product';
import sunCare from './sun-care.product';
import hairCare from './hair-care.product';
import bodyCare from './body-care.product';

interface Products {
  [x: string]: any;
}

const products: Products = {
  skinCare,
  sunCare,
  hairCare,
  bodyCare,
};

export default products;
