// Libraries
import React from 'react';
// Components
import { Note } from 'design-system';
// Assets
import { ReactComponent as ReadyToLaunchIcon } from 'img/ready-to-launch.svg';

interface RtlTipProps {
  additionalClasses?: string;
  contents: string[];
  url?: string;
}

export const RtlTip: React.FC<RtlTipProps> = ({
  additionalClasses,
  contents,
  url,
}) => {
  return (
    <Note
      additionalClasses={additionalClasses}
      header="Prime Tip: Ready to Launch"
      contents={contents}
      icon={<ReadyToLaunchIcon />}
      iconColor={'text-peach-50'}
      noteColor="bg-peach-95"
      textColor="text-peach-20"
      url={url}
      urlColor="text-royal-50"
    />
  );
};
