// Libraries
import React, { useState } from 'react';
// Components
import { Footer, TopNav } from 'features/ready-to-launch/ui';
import { ShoppingCart } from 'features/ready-to-launch/shopping-cart';

interface PageProps {
  initialCartState?: boolean;
  children: React.ReactNode[] | React.ReactNode;
}

export const ShoppingCartContext = React.createContext({
  closeShoppingCart: () => {},
  openShoppingCart: () => {},
});

export const Page: React.FC<PageProps> = ({
  children,
  initialCartState = false,
}) => {
  const [isCartOpen, setIsCartOpen] = useState(initialCartState);

  const handleToggleCart = () => {
    setIsCartOpen((prevState) => !prevState);
  };

  const closeShoppingCart = () => setIsCartOpen(false);
  const openShoppingCart = () => setIsCartOpen(true);

  return (
    // Resetting all CSS and setting max-w for mass-customization app
    <div className="reset">
      <div className="relative sm:max-w-screen-xl m-auto">
        <ShoppingCartContext.Provider
          value={{ closeShoppingCart, openShoppingCart }}
        >
          <TopNav toggleCart={handleToggleCart} isCartOpen={isCartOpen} />
          {isCartOpen && <ShoppingCart />}
          {children}
          <Footer />
        </ShoppingCartContext.Provider>
      </div>
    </div>
  );
};
