// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { TextField } from 'design-system';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS } from '../brief.constants';

export const InitialOrderQuantity: React.FC = () => {
  const { errors, getFieldProps } = useFormikContext<InternalBriefFormValues>();

  return (
    <div className="mb-6">
      <p
        id="initial-order-quantity"
        className="font-inter text-lg text-grey-20 mb-6"
      >
        Initial Order Quantity
      </p>
      <TextField
        {...getFieldProps(BRIEF_FORM_KEYS.MINIMUM_ORDER_QUANTITY)}
        trailingText="units"
        placeholder="000,000"
        type="number"
        error={Boolean(errors.minimumOrderQuantity)}
        helperText={errors.minimumOrderQuantity}
      />
    </div>
  );
};
