// Libraries
import React, { useContext } from 'react';
// Components
import { ProgressStep } from './progress-step.component';
import { StepContext } from 'features/ready-to-launch/ui';
// Assets
import chevronLeft from 'features/ready-to-launch/images/icons/chevronLeft.svg';
// Utils
import { useMobile } from 'hooks';
import { ProgressStepType } from 'features/ready-to-launch/types';
// Constants
import { KEY_PRESSES } from 'features/constants';

interface IProgressStepper {
  skipStep: (stepToSkipTo: number) => void;
  steps: ProgressStepType[];
}

export const ProgressStepper: React.FC<IProgressStepper> = ({
  skipStep,
  steps,
}) => {
  const { isMobile } = useMobile();
  const { decrementStep } = useContext(StepContext);

  const renderProgressSteps = () => {
    // First step is 'choose base' and should not appear in progress bar.
    return steps.slice(1, steps.length - 1).map((step) => {
      const {
        stepNumber,
        stepText,
        isCompleted,
        isActive,
        isTouched,
        lastStep,
      } = step;
      return (
        <ProgressStep
          key={stepNumber}
          stepNumber={stepNumber}
          stepText={stepText}
          isActive={isActive}
          isCompleted={isCompleted}
          isTouched={isTouched}
          lastStep={lastStep}
          skipStep={skipStep}
        />
      );
    });
  };
  return (
    <div className="mx-5 sm:mx-24 mt-8 flex flex-col">
      {!isMobile && <div className="flex ">{renderProgressSteps()}</div>}
      <div
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === KEY_PRESSES.ENTER) {
            e.preventDefault();
            decrementStep();
          }
        }}
        onClick={decrementStep}
        className="max-w-max flex mt-1 mb-3 cursor-pointer text-gray font-display underline"
      >
        <img src={chevronLeft} alt="" />
        Back
      </div>
    </div>
  );
};
