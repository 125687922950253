// Libraries
import React from 'react';
// Components
import { Button } from 'features/ready-to-launch/ui';
import { SAMPLE_PRICE } from 'features/ready-to-launch/constants';
// Utils
import { useAnalytics, useMobile } from 'hooks';
// Assets
import RightArrow from 'features/ready-to-launch/images/icons/arrowRight.svg';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const SEPHORA_LINK =
  'https://www.sephora.com/beauty/clean-beauty-products?activeTab=cleanplusanim0721_cleanmakeup_tab_UFE';
const EU_LAW_LINK =
  'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02009R1223-20211001';
const PROJECT_BRIEF_LINK = 'https://app.primematterlabs.com/build-your-project';

export const FAQ: React.FC<any> = ({ sendToWizard }) => {
  const analytics = useAnalytics();
  const { isMobile } = useMobile();

  const handleLinkClick = (label: string) => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCLandingPage,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: label,
      nonInteraction: true,
    });
  };

  const FAQ_TEXT = {
    buttonText: 'Build your product',
    title: 'Frequently Asked Questions',
    questionsAndAnswers: [
      {
        question: 'What is included in the sample?',
        answer:
          'Each sample includes a 2-ounce trial of the customized product you created.',
      },
      {
        question: 'What is the fee for each sample?',
        answer: `Each sample is $${SAMPLE_PRICE} with standard ground shipping included. When you’re ready to launch, your sample fee will be credited back to you (max. $${SAMPLE_PRICE}) with your product order.`,
      },
      {
        question: 'When will I receive my sample?',
        answer:
          'The formulation process typically takes 15 - 20 business days from the time your order is placed. We’ll send you a shipping notification with a tracking number via email as soon as your sample ships.',
      },
      {
        question: 'How many formulas can I create?',
        answer: 'You can create as many formulas as you want!',
      },
      {
        question: 'How can I place an order?',
        answer:
          'An account manager will help you place a product order. Each unit will cost between $3.50 - $5.70, depending on the product base and order size you choose.',
      },
      {
        question:
          'What formula standards are being used to make the samples and products?',
        answer: (
          <>
            We are using the most widely accepted clean standards supported by{' '}
            <a
              target="_blank"
              rel="noreferrer"
              onClick={() => handleLinkClick(SEPHORA_LINK)}
              href={SEPHORA_LINK}
              className="cursor-pointer underline text-blue-60"
            >
              Clean at Sephora
            </a>{' '}
            and the{' '}
            <a
              target="_blank"
              rel="noreferrer"
              onClick={() => handleLinkClick(EU_LAW_LINK)}
              href={EU_LAW_LINK}
              className="cursor-pointer underline text-blue-60"
            >
              EU law
            </a>{' '}
            for all of our products.
          </>
        ),
      },
      {
        question: 'What are my options for packaging?',
        answer:
          'An account manager will work with you to recommend packaging options for your product.',
      },
      {
        question: 'What if I don’t see the product I want to produce?',
        answer: (
          <>
            We’ll be adding new products to Ready to Launch in the future! In
            the meantime, you can provide us with a{' '}
            <a
              target="_blank"
              rel="noreferrer"
              onClick={() => handleLinkClick(PROJECT_BRIEF_LINK)}
              href={PROJECT_BRIEF_LINK}
              className="cursor-pointer underline text-blue-60"
            >
              Project Brief
            </a>{' '}
            and we’ll contact you as soon as possible about your project.
          </>
        ),
      },
    ],
  };

  const renderFAQ = () => {
    return FAQ_TEXT.questionsAndAnswers.map(({ question, answer }, index) => {
      return (
        <li key={`faq-${index}`} className="pb-8">
          <h5 className="text-lg font-display pb-2">{question}</h5>
          <p className="text-base font-display">{answer}</p>
        </li>
      );
    });
  };

  const handleButtonClick = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCLandingPage,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.buildYourProductTwo,
      nonInteraction: true,
    });
    sendToWizard();
  };

  return (
    <div className="bg-lightestGreen w-full pb-20 pt-20 relative">
      <div className="absolute flex w-full sm:w-auto p-5 sm:p-0 justify-center mb-14 -top-9 sm:-top-7 sm:inset-x-1/4">
        <Button
          fullWidth={isMobile}
          imageSrc={RightArrow}
          handleAction={handleButtonClick}
          variant="md"
        >
          {FAQ_TEXT.buttonText}
        </Button>
      </div>
      <div className="sm:grid grid-cols-3 gap-5 sm:px-24.5 px-5">
        <h3 className="col-span-1 text-[1.875rem] mb-6 font-display">
          {FAQ_TEXT.title}
        </h3>
        <ul className="col-span-2">{renderFAQ()}</ul>
      </div>
    </div>
  );
};
