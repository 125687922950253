// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Checkbox, TextArea } from 'design-system';
import { ScrollIntoView } from './scroll-into-view.component';
// Utils
import { InternalBriefFormValues } from '../internal-brief/form.config';
import { useConfirmation } from 'hooks';
// Constants
import { BRIEF_FORM_KEYS, RTL_CONFIRM_STATE } from '../brief.constants';
// Assets
import { ReactComponent as ReadyToLaunchIcon } from '../../../img/ready-to-launch.svg';

export const COMPLIANT_COUNTRIES = ['US', 'Canada', 'EU', 'Other'];

interface CountryComplianceProps {
  projectIsRtl?: boolean;
}

export const CountryCompliance: React.FC<CountryComplianceProps> = ({
  projectIsRtl,
}) => {
  const { getFieldProps, values, errors, setFieldValue } = useFormikContext<
    InternalBriefFormValues
  >();
  const { confirm } = useConfirmation();

  const handleClick = async (value: string) => {
    const currentCountries = values[BRIEF_FORM_KEYS.COUNTRIES] || [];

    // Check if the value is already present
    const valueIndex = currentCountries.indexOf(value);

    let updatedCountries;
    if (valueIndex > -1) {
      // Value is present, remove it
      updatedCountries = currentCountries.filter(
        (country) => country !== value
      );
    } else {
      // Value is not present, add it
      updatedCountries = [...currentCountries, value];
    }

    if (projectIsRtl) {
      const shouldProceed = await confirm(RTL_CONFIRM_STATE);
      if (shouldProceed) {
        setFieldValue(BRIEF_FORM_KEYS.COUNTRIES, updatedCountries);
      } else {
        return;
      }
    } else {
      setFieldValue(BRIEF_FORM_KEYS.COUNTRIES, updatedCountries);
    }
  };

  const renderCountries = () => {
    return COMPLIANT_COUNTRIES.map((option) => {
      if (option === COMPLIANT_COUNTRIES[0]) {
        return (
          <div className="mb-3 flex flex-row items-center" key={option}>
            <Checkbox
              {...getFieldProps(BRIEF_FORM_KEYS.COUNTRIES)}
              primaryLabel={option}
              value={option}
              checked={values.countries.includes(option)}
              {...(projectIsRtl
                ? {
                    leadingIcon: <ReadyToLaunchIcon />,
                  }
                : {})}
            />
          </div>
        );
      }
      if (option === COMPLIANT_COUNTRIES[COMPLIANT_COUNTRIES.length - 1]) {
        return (
          <div className="mb-3" key={option}>
            <Checkbox
              {...getFieldProps(BRIEF_FORM_KEYS.COUNTRIES)}
              primaryLabel={option}
              description="Note: Additional countries will require a third party regulatory consultant"
              direction="col"
              value={option}
              checked={values.countries.includes(option)}
              onChange={() => handleClick(option)}
            />
          </div>
        );
      }
      return (
        <div className="mb-3" key={option}>
          <Checkbox
            {...getFieldProps(BRIEF_FORM_KEYS.COUNTRIES)}
            value={option}
            primaryLabel={option}
            checked={values.countries.includes(option)}
            onChange={() => handleClick(option)}
          />
        </div>
      );
    });
  };

  return (
    <ScrollIntoView>
      {({ ref }) => (
        <div ref={ref} id="country-compliance">
          <div className="mb-6">
            <p className="font-inter text-lg font-normal text-grey-20">
              Country Compliance
            </p>
            {errors.countries && (
              <p className="text-red-50 text-sm">{errors.countries}</p>
            )}
          </div>
          <div>{renderCountries()}</div>
          <TextArea
            {...getFieldProps(BRIEF_FORM_KEYS.COUNTRY_COMPLIANCE_NOTE)}
            placeholder="Notes on other"
          />
        </div>
      )}
    </ScrollIntoView>
  );
};
