// Libraries
import React, { useState, useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import {
  Button,
  IncrementDecrement,
  StepContext,
} from 'features/ready-to-launch/ui';
// Assets
import priceStar from 'features/ready-to-launch/images/icons/priceStar.svg';
// Utils
import { useAnalytics } from 'hooks';
import {
  Extra,
  Goal,
  IMassCustomizationScreen,
  ProductBase,
} from 'features/ready-to-launch/types';
import { determineCongratsScreenTitle } from 'features/ready-to-launch/utils/utils';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';
const INGREDIENTS_TRUNCATE_AFTER_NUMBER = 10;
const ADDITIONAL_INGREDIENTS_TRUNCATE_AFTER_NUMBER = 2;

// Styles
const generateTruncatedTextStyles = (
  lineLength: number
): React.CSSProperties => {
  // These styles handle truncating text that is large than one line
  return {
    display: '-webkit-box',
    WebkitLineClamp: lineLength,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };
};

interface ITableCellTitle {
  title: string;
}
const TableCellTitle: React.FC<ITableCellTitle> = ({ title }) => {
  return (
    <h6 className="font-mono text-sm pb-1 text-gray font-semibold">{title}</h6>
  );
};

interface ITableCell {
  children: React.ReactNode[] | React.ReactNode;
}

const TableCell: React.FC<ITableCell> = ({ children }) => {
  return <div className="py-6 px-8">{children}</div>;
};

export const MobileSummaryTable: React.FC<IMassCustomizationScreen> = ({
  product,
}) => {
  const analytics = useAnalytics();
  const {
    values: { productBase, extras, goals, name },
    submitForm,
    resetForm,
  } = useFormikContext<any>();
  const { resetWizard } = useContext(StepContext);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);

  const getAdditionalIngredients = () => {
    const selectedGoals = goals.map((goalName: string) =>
      product.goals.find((goal: Goal) => goal.title === goalName)
    );

    const selectedExtras = extras.map((extraName: string) =>
      product.extras.find((extra: Extra) => extra.title === extraName)
    );

    return selectedGoals
      .concat(selectedExtras)
      .map((item: Goal & Extra) => item.ingredients)
      .join(', ');
  };

  const additionalIngredients = getAdditionalIngredients();
  const selectedProductBase = product.productBases.find(
    (base: ProductBase) => base.title === productBase
  );
  const productBaseIngredients = selectedProductBase?.ingredients;
  const sampleImage = selectedProductBase?.sampleImageSrc;
  const summaryTableText = product.summaryTableText;

  const handleSubmission = () => {
    analytics?.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.MCCongratsSummary,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.addToCart,
      nonInteraction: true,
    });
    submitForm().then(() => {
      setTimeout(() => resetForm(), 0);
      setIsBtnDisabled(true);
      resetWizard();
    });
  };

  return (
    <div className="flex flex-col border-gray relative -mt-24 pb-20">
      <div className="absolute -top-12 right-0">
        <div className="relative text-center">
          <h3
            className="absolute top-1/2 left-1/2 select-none font-display text-[1.875rem]"
            style={{ transform: 'translate(-50%, -50%) rotate(15deg)' }}
          >
            {summaryTableText.sampleCost}
          </h3>
          <img src={priceStar} alt={summaryTableText.sampleCost} />
        </div>
      </div>
      <div className="flex">
        {/* PACKAGING IMAGE */}
        <div className="p-7 border bg-lightBlue">
          <img
            style={{ maxHeight: 200 }}
            className="p-1"
            src={sampleImage}
            alt="sample container"
          />
        </div>
        {/* PRODUCT TITLE */}
        <div className="flex w-full bg-lightPeach border border-gray border-l-0">
          <div className="flex flex-col justify-center pl-8">
            <h4 className="font-display text-[2.25rem]">
              {`${name} ${determineCongratsScreenTitle(product, productBase)}`}
            </h4>
            <h6 className="font-display text-base">
              {summaryTableText.twoOunceSample}
            </h6>
          </div>
        </div>
      </div>
      <div className="flex justify-between bg-black py-6 px-8">
        <div className="bg-white">
          <IncrementDecrement />
        </div>
        <Button
          variant="md"
          handleAction={handleSubmission}
          disabled={isBtnDisabled}
        >
          {summaryTableText.buttonText}
        </Button>
      </div>
      {/* GOALS && EXTRAS */}
      <div className="bg-white border border-gray">
        <TableCell>
          <TableCellTitle title="GOALS" />
          <h5
            className="font-display text-lg truncate cursor-default"
            title={goals.join(' & ')}
          >
            {goals.join(' & ')}
          </h5>
        </TableCell>
      </div>
      <div className="col-span-1 row-span-1 bg-white border border-t-0 border-gray">
        <TableCell>
          <TableCellTitle title={summaryTableText.extrasTitle} />
          <h5
            className="font-display text-lg truncate cursor-default"
            title={extras.length ? extras.join(', ') : 'No extras selected'}
          >
            {extras.length ? extras.join(', ') : 'No extras selected'}
          </h5>
        </TableCell>
      </div>
      {/* ingredients */}
      <div className="col-span-2 row-span-2 bg-white border border-gray">
        <TableCell>
          <TableCellTitle title={summaryTableText.ingredientsTitle} />
          <p
            className="text-gray font-display text-sm cursor-default"
            title={productBaseIngredients}
            style={generateTruncatedTextStyles(
              INGREDIENTS_TRUNCATE_AFTER_NUMBER
            )}
          >
            {productBaseIngredients}
          </p>
          <br />
          <TableCellTitle title={summaryTableText.addlIngredientsTitle} />
          <p
            className="text-gray font-display text-sm cursor-default"
            title={additionalIngredients}
            style={generateTruncatedTextStyles(
              ADDITIONAL_INGREDIENTS_TRUNCATE_AFTER_NUMBER
            )}
          >
            {additionalIngredients}
          </p>
        </TableCell>
      </div>
    </div>
  );
};
