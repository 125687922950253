// Libraries
import React from 'react';
import * as Yup from 'yup';
// Components
import { ChooseBase } from '../choose-base.component';
import { GoalsStep, ExtrasStep, NameStep } from '../customization-form';
import { CustomizationSummary } from '../customization-summary';
// Assets
import mineralSpfImage from '../images/swatches/mineralSpf.png';
import spfLotionImage from '../images/swatches/spfLotion.webp';
import smallJar from '../images/smallJar.webp';
import smallBottle from '../images/smallBottle.webp';
import sunImage from '../images/sun.png';
import mobileSunImage from '../images/mobileSun.png';
// Utils
import {
  SAMPLE_PRICE,
  EXTRACTS,
  INITIAL_FORM_VALUES,
  SUMMARY_TABLE_TEXT,
  WAITLIST_TAG_NAMES,
  SPECIFICATIONS,
} from '../constants';
import { Product } from '../types';
const { aloeVera, greenTea, tigerGrass } = EXTRACTS;

const { parabens, vegan, fragrance, sephora } = SPECIFICATIONS;

const GOAL_LIMIT = 1;

const sunCare: Product = {
  categoryCardProperties: {
    imageSrc: sunImage,
    mobileImageSrc: mobileSunImage,
    tags: [],
  },
  categoryDisabled: false,
  goals: [
    {
      title: 'Barrier Protection',
      subTitle:
        'Help restore your skin barrier for better skin hydration with Niacinamide (Vitamin B3) while also evening out skin tone and helping to protect from sun damage.',
      ingredients: 'Niacinamide',
    },
    {
      title: 'Radiance',
      subTitle:
        'Using a stable form of Vitamin C, target dullness, and even out skin tone to help restore radiance and suppleness in stressed or aging skin.',
      ingredients: 'Tetrahexyldecyl Ascorbate',
    },
    {
      title: 'Hydration',
      subTitle:
        'Provide deep hydration using Hyaluronic Acid. This humectant anchors moisture within the skin, keeping it hydrated and bouncy.',
      ingredients: 'Sodium Hyaluronate, Water',
    },
  ],
  goalScreenProperties: {
    goalLimit: GOAL_LIMIT,
    title: `select ${GOAL_LIMIT} option${GOAL_LIMIT > 1 ? 's' : ''}`,
    description:
      'Let’s create a formula for the goals you’d like your product to address.',
  },
  extras: [aloeVera, greenTea, tigerGrass],
  initialFormValues: {
    ...INITIAL_FORM_VALUES,
    productCategory: 'sunCare',
  },
  initialHeaderText: (
    <>
      Choose your <span className="text-blue-60">sun care base</span>
    </>
  ),
  initialSubheaderText:
    'After you select a sun care base, you’ll identify the goals you’d like your product to address.',
  initialProgressStepValues: [
    {
      stepNumber: 0,
      stepText: 'Base',
      isActive: false,
      isTouched: true,
      isCompleted: true,
      stepName: 'productBase',
    },
    {
      stepNumber: 1,
      stepText: 'Goals',
      isActive: true,
      isTouched: true,
      isCompleted: false,
      stepName: 'goals',
    },
    {
      stepNumber: 2,
      stepText: 'Extras',
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'extras',
    },
    {
      stepNumber: 3,
      stepText: 'Name',
      lastStep: true,
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'name',
    },
    {
      stepNumber: 4,
      stepText: 'Summary',
      lastStep: true,
      isActive: false,
      isTouched: false,
      isCompleted: false,
      stepName: 'summary',
    },
  ],
  productBases: [
    {
      congratsScreenTitle: 'SPF 30 lotion',
      cost: `$${SAMPLE_PRICE}`,
      details: (
        <>
          A lightweight, broad spectrum SPF 30 formula that is water resistant
          up to 80 minutes.{' '}
          <span className="text-gray">(Octinoxate and Oxybenzone free)</span>
        </>
      ),
      imageSrc: spfLotionImage,
      sampleImageSrc: smallBottle,
      title: 'SPF 30 Lotion',
      ingredients:
        'Homosalate, Octocrylene, Octisalate, Avobenzone, Acrylates/C10-30 Alkyl Acrylate Crosspolymer, Aloe Barbadensis Leaf Juice, C12-15 AlkylBenzoate, Carbomer, Disodium EDTA, Ethylhexylglycerin, HydroxypropylMethylcellulose, Phenoxyethanol, Polyethylene, Polysorbate 20, Propylene Glycol, SodiumHydroxide, Sorbitan Oleate, Theobroma Cacao(Cocoa) Seed Butter, Tocopheryl Acetate, Water',
    },
    {
      congratsScreenTitle: 'SPF 50 lotion',
      cost: `$${SAMPLE_PRICE}`,
      details: (
        <>
          A lightweight, broad spectrum SPF 50 formula that is water resistant
          up to 80 minutes.{' '}
          <span className="text-gray">(Octinoxate and Oxybenzone free)</span>
        </>
      ),
      imageSrc: spfLotionImage,
      sampleImageSrc: smallBottle,
      title: 'SPF 50 Lotion',
      ingredients:
        'Homosalate, Octocrylene, Octisalate, Avobenzone, Acrylates/C10-30 Alkyl, Acrylate Crosspolymer, Aloe Barbadensis Leaf Juice, C12-15 AlkylBenzoate, Carbomer, Disodium EDTA, Ethylhexylglycerin, HydroxypropylMethylcellulose, Phenoxyethanol, Polyethylene, Polysorbate 20, Propylene Glycol, SodiumHydroxide, Sorbitan Oleate, Theobroma Cacao(Cocoa) Seed Butter, Tocopheryl Acetate, Water',
    },
    {
      cost: `$${SAMPLE_PRICE}`,
      details: 'A lightweight, broad spectrum mineral formula.',
      imageSrc: mineralSpfImage,
      isWaitlisted: true,
      waitlistTag: WAITLIST_TAG_NAMES.sunCareMineralSunscreen,
      sampleImageSrc: smallJar,
      title: 'Mineral Sunscreen',
      ingredients:
        'Water, Cetearyl Alcohol, Caprylic/Capric Triglyceride, Butyrospermum Parkii (Shea) Butter, Carthamus Tinctorius (Safflower) Seed Oil, Helianthus Annuus (Sunflower) Seed Oil, Glycerin, Stearic Acid, Sodium Stearoyl Lactylate, Hydrogenated Sunflower Seed Oil Glyceryl Esters, Hydrogenated Sunflower Seed Oil Polyglyceryl-3 Esters, Phenoxyethanol, Caprylyl Glycol, Xanthan Gum, Sodium Hydroxide',
    },
  ],
  specifications: [parabens, vegan, fragrance, sephora],
  steps: [ChooseBase, GoalsStep, ExtrasStep, NameStep, CustomizationSummary],
  summaryTableText: SUMMARY_TABLE_TEXT,
  title: 'Sun Care',
  validationSchema: Yup.object().shape({
    productBase: Yup.string().required(),
    goals: Yup.array().min(1).max(GOAL_LIMIT),
    name: Yup.string().required().min(1).max(25),
    itemQuantity: Yup.number().min(1).max(10),
  }),
};

export default sunCare;
