// Libraries
import React from 'react';
// Components
import { FormLayout } from '../layout';
import { FormSelectList } from 'features/ui';
// Utils
import { BRIEF_FORM_INPUTS, RETAILER_STANDARDS } from '../../constants';
// Constants
const TITLE = 'Are there any standards that your product should meet?';
const SUB_TITLE =
  'Select the clean beauty criteria you want your product to comply with.';

export const RetailerStandards: React.FC = () => {
  return (
    <FormLayout title={TITLE} subTitle={SUB_TITLE}>
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6">
        <label className="col-span-2 sm:col-start-2 sm:col-span-12 font-mono text-xs font-semibold mb-3">
          {'Select all that apply'.toLocaleUpperCase()}
        </label>
      </div>
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12">
        <div className="col-span-2 sm:col-start-2 sm:col-span-8 bg-white">
          <FormSelectList
            type="checkbox"
            inputName={BRIEF_FORM_INPUTS.retailerStandards}
            items={RETAILER_STANDARDS}
          />
        </div>
      </div>
    </FormLayout>
  );
};
