// Libraries
import React from 'react';

interface BottomBarProps {
  buttons: React.ReactNode[];
  fixedDivWidth?: string;
}

export const BottomBar: React.FC<BottomBarProps> = ({
  buttons,
  fixedDivWidth,
}) => {
  return (
    <div
      style={{ width: fixedDivWidth ? fixedDivWidth : '100%' }}
      className={`fixed bottom-0 flex gap-3 justify-end p-2 bg-grey-99 border border-grey-90`}
    >
      {buttons.map((button, index) => {
        return <div key={`button-${index}`}>{button}</div>;
      })}
    </div>
  );
};

export default BottomBar;
