// Libraries
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Components
import * as ReadyToLaunch from 'features/ready-to-launch/ui';
import { WaitListModal } from './wait-list-modal.component';
// Utils
import { ScrollToTop } from 'features/ready-to-launch';
import { QuizResult } from './types';
import { useAnalytics } from 'hooks';
// Assets
import arrow from './images/smallBlueArrow.svg';
// Constants
import { QUIZ_RESULTS_PAGE } from './constants';
import * as AnalyticsConstants from 'features/analytics/analytics.constants';
interface IQuizResultsPage {
  isMobile: boolean;
  result: QuizResult;
}

const LEARN_MORE = 'LEARN MORE';
const LEARN_MORE_LABEL = 'Learn More';
const CREAMS = 'creams';

export const QuizResultsPage: React.FC<IQuizResultsPage> = ({
  isMobile,
  result,
  result: {
    analyticsEventLabel,
    bodyOne,
    bodyTwo,
    buttonText,
    buttonUrl,
    headerText,
    image,
    learnMoreUrl,
    mobileImg,
    pageTitle,
  },
}) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const [isWaitListModalOpen, setIsWaitListModalOpen] = useState<boolean>(
    false
  );

  useEffect(() => {
    analytics?.pageView(pageTitle);

    window.scrollTo(0, 0);
  }, [analytics, pageTitle]);

  const handleButtonClick = (
    _event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    isLearnMoreLink = false
  ) => {
    analytics?.trackEvent({
      eventCategory: AnalyticsConstants.EVENT_CATEGORIES.QuizRecommendation,
      eventAction: AnalyticsConstants.EVENT_ACTIONS.click,
      eventLabel: isLearnMoreLink ? LEARN_MORE_LABEL : analyticsEventLabel,
    });

    if (isLearnMoreLink) return null;

    if (result === QUIZ_RESULTS_PAGE.RTL_WAITLIST) {
      setIsWaitListModalOpen(true);
    } else {
      navigate(buttonUrl);
    }
  };

  const imageHeight =
    result.image.altTag === CREAMS ? { height: '694px' } : { height: '790px' };

  return (
    <ReadyToLaunch.Page>
      <ScrollToTop />
      <WaitListModal
        isOpen={isWaitListModalOpen}
        handleClose={() => setIsWaitListModalOpen(false)}
      />
      <div className="flex flex-col sm:flex-row mx-auto mt-16 sm:mt-10">
        {isMobile && (
          <img className="ml-5" src={mobileImg.src} alt={mobileImg.altTag} />
        )}
        <div className="flex flex-col mt-8 sm:mt-14 px-5 sm:pr-16 sm:pl-6 text-left">
          <div className="text-[2.25rem] sm:text-6xl text-black font-headline">
            {headerText}
          </div>
          <div className="mt-6 mb-10 font-display text-lg">
            <p>{bodyOne}</p>
            <br />
            <p>{bodyTwo}</p>
          </div>
          <div className="flex flex-col mb-24">
            <button
              onClick={handleButtonClick}
              className="max-w-min py-4 px-10 whitespace-nowrap bg-blue-60 text-white text-sm font-mono"
            >
              {buttonText}
            </button>
            {learnMoreUrl && (
              <Link
                onClick={(e) => handleButtonClick(e, true)}
                to={learnMoreUrl}
              >
                <div className="max-w-max flex pb-2 pt-6 border-b border-blue-60 whitespace-nowrap text-xs text-blue-60 font-mono">
                  {LEARN_MORE}
                  <img className="ml-5" src={arrow} alt="" />
                </div>
              </Link>
            )}
          </div>
        </div>
        {!isMobile && (
          <img
            style={imageHeight}
            className="w-full object-cover object-top"
            src={image.src}
            alt={image.altTag}
          />
        )}
      </div>
    </ReadyToLaunch.Page>
  );
};
