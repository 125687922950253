// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import { StepContext } from 'features/ready-to-launch/ui';
// Assets
import checkCircle from 'features/ready-to-launch/images/icons/checkCircle.svg';
// Utils
import { ProgressStepType } from 'features/ready-to-launch/types';
// Constants
import { KEY_PRESSES } from 'features/constants';

export const ProgressStep: React.FC<
  Omit<ProgressStepType, 'stepName'> & {
    skipStep: (stepToSkipTo: number) => void;
  }
> = ({
  stepNumber,
  stepText,
  isActive,
  isCompleted,
  isTouched,
  lastStep = false,
  skipStep,
}) => {
  const { currentStepName } = useContext(StepContext);
  const { errors } = useFormikContext();
  const currentStepHasErrors = !!(errors as any)[currentStepName];

  const styleMap = {
    incomplete: 'border-l border-t border-b border-gray text-gray',
    active: 'border-4 border-legacyGreen bg-lightestGreen',
    available: 'border cursor-pointer ',
    completed: 'border border-legacyGreen bg-lightestGreen',
  };

  const getStyleFromStatus = () => {
    if (isActive) {
      return styleMap.active;
    } else if (isCompleted) {
      return styleMap.completed;
    } else if (isTouched) {
      return styleMap.available;
    } else {
      return styleMap.incomplete;
    }
  };

  const canSkip =
    !currentStepHasErrors && (isCompleted || (isTouched && !isActive));

  return (
    <div
      tabIndex={canSkip ? 0 : undefined}
      className={
        `flex flex-col flex-1 pt-5 pb-5 pl-5 pr-3 ${getStyleFromStatus()} ` +
        (lastStep && !isActive ? 'border-r ' : '') +
        (canSkip ? 'hover:bg-legacyGreen cursor-pointer ' : '')
      }
      onClick={() => (canSkip ? skipStep(stepNumber) : null)}
      onKeyDown={(e) => {
        if (e.key === KEY_PRESSES.ENTER && canSkip) {
          e.preventDefault();
          skipStep(stepNumber);
        }
      }}
    >
      <div className="flex justify-between">
        <div className="font-mono text-xs mb-1">STEP {stepNumber}</div>
        {isCompleted && (
          <img className="-mt-2" src={checkCircle} alt="check circle" />
        )}
      </div>
      <div className="font-display text-lg">{stepText}</div>
    </div>
  );
};
