// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Utils
import { ProductFormat } from './types';
// Constants
import { BriefFormValues } from '../../types';

interface ICategoryCard {
  available: boolean;
  formats: ProductFormat[];
  image: JSX.Element;
  isMobile?: boolean;
  mobileImage?: JSX.Element;
  name: string;
}

export const CategoryCard: React.FC<ICategoryCard> = ({
  available,
  formats,
  image,
  isMobile,
  mobileImage,
  name,
}) => {
  const { values } = useFormikContext<BriefFormValues>();
  const isSelected = values['productCategory'].includes(name);

  const renderFormats = () => {
    return formats.map((format: ProductFormat, idx: number) => {
      return (
        <div
          key={`${format.name}-${idx}`}
          className="col-span-1 font-display text-base"
        >
          <div
            className={`${
              available ? 'text-darkGray' : 'text-gray'
            } whitespace-nowrap`}
          >
            {format.name}
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className={`flex border ${
        isMobile ? 'flex-col h-[225px] mb-5' : 'h-[11.875rem]'
      }     ${
        available
          ? 'hover:bg-lightPeach shadow-choiceCard cursor-pointer'
          : 'border-lightGray'
      } ${isSelected ? 'bg-lightPeach ' : 'bg-white'}`}
    >
      <div className={`${isMobile ? 'w-full' : 'w-[8.75rem]'}`}>
        {isMobile ? mobileImage : image}
      </div>
      <div className="flex flex-col px-7 py-6 w-full">
        <div
          className={`font-headline text-[1.875rem] mb-3 col-span-2 ${
            available ? 'text-darkGray' : 'text-gray'
          }`}
        >
          {name}
        </div>
        <div className="grid grid-cols-2 gap-x-2 gap-y-1">
          {renderFormats()}
        </div>
      </div>
    </div>
  );
};
