// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { FormLayout } from '../layout';
import { BriefRadioButton } from '../layout';
// Utils
import { BriefFormValues } from '../../types';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';

const ADDITIONAL_NOTES_LABEL = (
  <label className="font-mono text-xs font-semibold mb-3">
    ADDITIONAL NOTES <span className="text-gray">(OPTIONAL)</span>
  </label>
);

const TITLE = 'Does your product qualify as over-the-counter (OTC)?';

const openNewTab = () => {
  const url =
    'https://www.primematterlabs.com/resources/difference-between-cosmetic-and-OTC/';
  window.open(url, '_blank');
};

const OTC = (
  <span>
    <button style={{ color: 'rgb(73, 129, 192)' }} onClick={openNewTab}>
      OTC
    </button>
  </span>
);
const SUB_TITLE = (
  <div>
    Examples of over-the-counter (OTC) claims include SPF / sunscreen, acne
    treatment, and antiperspirant. Read more on {OTC} designations.
  </div>
);

export const OtcInfo: React.FC = () => {
  const { errors, getFieldProps } = useFormikContext<BriefFormValues>();
  return (
    <FormLayout
      isNextButtonDisabled={BRIEF_FORM_INPUTS.isOtc in errors}
      subTitle={SUB_TITLE}
      title={TITLE}
    >
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6 sm:gap-y-6 font-display text-lg">
        <div className="sm:col-start-2 col-span-2">
          <BriefRadioButton inputName={BRIEF_FORM_INPUTS.isOtc} title={'Yes'} />
        </div>
        <div className="col-span-2 my-6 sm:my-0">
          <BriefRadioButton inputName={BRIEF_FORM_INPUTS.isOtc} title={'No'} />
        </div>
        <div className="col-span-2">
          <BriefRadioButton
            inputName={BRIEF_FORM_INPUTS.isOtc}
            title={'Unsure'}
          />
        </div>
      </div>
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6">
        <div className="sm:col-start-2 col-span-2 sm:col-span-8 flex flex-col mt-6">
          {ADDITIONAL_NOTES_LABEL}
          <textarea
            rows={6}
            className="sm:col-start-2 col-span-2 sm:col-span-8 border border-black font-display text-lg p-6"
            {...getFieldProps(BRIEF_FORM_INPUTS.isOtcNote)}
          />
        </div>
      </div>
    </FormLayout>
  );
};
