// Libraries
import React, { useContext } from 'react';
// Components
import StepListItem from './step-list-item.component';
import { WizardContext } from '../../wizard/wizard.context';
// Utils
import { Step } from '../../../types';
import { useAnalytics, useMobile } from 'hooks';
// Assets
import interstitialIconOne from '../../../assets/interstitialIconOne.svg';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

type InterstitialImage = {
  src: string;
  alt: string;
};
interface IInterstitialLayout {
  buttonText?: string;
  eventCategory: string;
  image: InterstitialImage;
  mobileImage: InterstitialImage;
  title: string;
  steps: Step[];
}

export const InterstitialLayout: React.FC<IInterstitialLayout> = ({
  buttonText = 'Continue',
  eventCategory,
  image,
  mobileImage,
  steps,
  title,
}) => {
  const analytics = useAnalytics()!;
  const { pageNumber, incrementPage } = useContext(WizardContext);
  const { isMobile } = useMobile();

  const handleClick = () => {
    analytics.trackEvent({
      eventCategory: eventCategory,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbNext(
        `${String(pageNumber).padStart(2, '0')}. ${title}`
      ),
    });
    incrementPage();
  };

  const renderSteps = () => {
    return steps.map((step, idx) => {
      return (
        <StepListItem
          key={`${step.text}-${idx}`}
          isComplete={step.isComplete}
          stepNumber={idx + 1}
          text={step.text}
        />
      );
    });
  };

  return (
    <div className="flex bg-lightPeach sm:h-screen">
      {isMobile ? (
        <div className="flex bg-lightPeach pb-9 flex-col">
          <img
            className=" h-[140px] object-cover"
            src={mobileImage.src}
            alt={mobileImage.alt}
          />
          <div className="pl-5 pr-8">
            <div className="font-headline text-[32px] mt-14 leading-9">
              {title}
            </div>
            <div className="mt-6">{renderSteps()}</div>
            <button
              onClick={handleClick}
              className="mt-1 px-5 py-4 bg-blue-60 text-white text-xl font-display"
            >
              {buttonText}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex max-w-1440 sm:mx-auto">
          <div className="bg-lightPeach w-2/3">
            <div className="pt-[5rem] ml-20 h-full">
              <img src={interstitialIconOne} alt="container icon" />
              <div className="font-headline text-[3rem] mt-10">{title}</div>
              <div className="ml-12 mt-8 mb-10">{renderSteps()}</div>
              <button
                onClick={handleClick}
                className="px-5 py-4 bg-blue-60 text-white text-lg font-display"
              >
                {buttonText}
              </button>
            </div>
          </div>
          <img
            src={image.src}
            alt={image.alt}
            className="w-1/3 h-full object-cover"
          />
        </div>
      )}
    </div>
  );
};
