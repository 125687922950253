// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { FormLayout } from '../layout';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';

const BUTTON_TEXT = 'Finish brief';
const TITLE = 'Anything else you want us to know about your product idea?';
const SUB_TITLE =
  'Tell us any additional details we should know to bring your product vision to life.';

export const AdditionalInfo: React.FC = () => {
  const { getFieldProps, submitForm, isSubmitting } = useFormikContext();

  return (
    <FormLayout
      buttonText={BUTTON_TEXT}
      subTitle={SUB_TITLE}
      title={TITLE}
      isNextButtonDisabled={isSubmitting}
      isBackButtonDisabled={isSubmitting}
      onClick={submitForm}
    >
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6 sm:gap-y-6">
        <div className="col-span-2 mb-6 sm:col-start-2 sm:col-span-8 sm:mt-6">
          <textarea
            rows={6}
            className="w-full border border-black font-display text-lg p-6"
            {...getFieldProps(BRIEF_FORM_INPUTS.additionalInfo)}
          />
        </div>
      </div>
    </FormLayout>
  );
};
