// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { BriefSelect } from '../layout';
import { FormLayout } from '../layout';
import { FormSelectList } from 'features/ui';
// Utils
import { BriefFormValues } from '../../types';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';

const LABELS = [
  { title: 'Front and rear', value: 'Front and Rear' },
  { title: 'Wrap', value: 'Wrap' },
  { title: 'Screen print', value: 'Screen Print' },
  { title: 'Top', value: 'Top' },
  { title: 'Bottom', value: 'Bottom' },
];

const OPTIONS = [
  { name: 'Bottle', value: 'Bottle' },
  { name: 'Tottle', value: 'Tottle' },
  { name: 'Jar', value: 'Jar' },
  { name: 'Tube', value: 'Tube' },
  { name: 'Lip Balm/Deodorant', value: 'Lip Balm/Deodorant' },
  { name: 'Foil Sachet', value: 'Foil Sachet' },
  { name: 'Aerosol', value: 'Aerosol' },
  { name: 'Bag-on-Valve', value: 'Bag-on-Valve' },
];

const PACKAGING_LABEL = 'PRIMARY PACKAGING *';
const LABELING_LABEL = (
  <div className="text-xs font-mono font-semibold">
    LABELS <span className="text-gray">(OPTIONAL)</span>
  </div>
);

const SUB_TITLE =
  'Prime Matter Labs does not supply packaging, but packaging and labeling can have an impact on the cost of labor.';
const TITLE = 'Tell us about the packaging for your product.';

export const Packaging: React.FC = () => {
  const { errors, values } = useFormikContext<BriefFormValues>();
  return (
    <FormLayout
      isNextButtonDisabled={BRIEF_FORM_INPUTS.primaryPackaging in errors}
      subTitle={SUB_TITLE}
      title={TITLE}
    >
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6">
        <div className="col-span-2 sm:col-start-2 sm:col-span-8 mb-6">
          <BriefSelect
            formikKey={BRIEF_FORM_INPUTS.primaryPackaging}
            label={PACKAGING_LABEL}
            options={OPTIONS}
            value={values.primaryPackaging}
          />
        </div>
        <div className="col-span-2 sm:col-start-2 sm:col-span-8 flex flex-col">
          {LABELING_LABEL}
          <div className="bg-white mt-3">
            <FormSelectList
              type="checkbox"
              inputName={BRIEF_FORM_INPUTS.labels}
              items={LABELS}
            />
          </div>
        </div>
      </div>
    </FormLayout>
  );
};
