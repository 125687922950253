// Libraries
import React from 'react';
// Assets
import closeIcon from '../images/icons/close.svg';

interface ModalProps {
  children: React.ReactNode[] | React.ReactNode;
  isIngredientModal?: boolean;
  isSpeedbump?: boolean;
  hasShadow?: boolean;
  subTitle?: string;
  title?: string | React.ReactNode[] | React.ReactNode;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isIngredientModal = false,
  isSpeedbump = false,
  hasShadow = true,
  onClose,
  subTitle,
  title,
}) => {
  const handleOutsideModalClick = (event: any) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  return (
    <div
      onClick={handleOutsideModalClick}
      className="overscroll-none flex items-center fixed z-40 bg-blue-60/60 w-full h-full top-0 left-0"
    >
      <div
        className={
          'flex flex-col border sm:h-2/3 sm:w-2/4 p-6 overflow-scroll mx-auto font-display text-black z-50 ' +
          (isSpeedbump ? 'bg-lightPeach ' : 'bg-white ') +
          (isIngredientModal ? 'h-full ' : 'h-min ') +
          (hasShadow ? 'shadow-overlay ' : '')
        }
      >
        {title && (
          <div className="flex mb-4 items-start justify-between">
            <div className="flex flex-col">
              <h3 className={'text-3xl ' + (isSpeedbump ? 'text-red-40' : '')}>
                {title}
              </h3>
              {subTitle && (
                <h6 className="mt-2.5 text-xs font-medium font-mono text-gray">
                  {subTitle.toUpperCase()}
                </h6>
              )}
            </div>
            <button tabIndex={0} onClick={() => onClose()}>
              <img src={closeIcon} alt="close" />
            </button>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

// Example usage:
// const MODAL_TITLE = "You'll lose your sample if you leave now";
// const MODAL_BODY_TEXT =
//   'Before starting a new sample or going somewhere else make sure to add your sample to your cart. Otherwise you’ll lose all the progress you’ve made.';

// const Component = () => {
//   const [open, setOpen] = useState(true);
//
//   const handleClose = () => {
//     setOpen(false)
//   }
//
//   return (
//     <Page>
//       {
//         open && <Modal title={MODAL_TITLE} isSpeedbump onClose={handleClose}>
//           <h4 className="text-xl font-display mb-2">{MODAL_BODY_TEXT}</h4>
//           <div className="flex justify-end space-x-3">
//             <button onClick={handleClose}>No</button>
//             <button>Yes</button>
//           </div>
//         </Modal>
//       }
//     </Page>
//   );
// };
