// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { BriefSelect, BriefTextField, FormLayout } from '../layout';
import { TipBox } from '../layout';
// Utils
import { BriefFormValues } from '../../types';
// Constants
import {
  COST_PER_UNIT_SPECIFIC_LABEL,
  COST_PER_UNIT_RANGE_LABEL,
  COST_PER_UNIT_RANGES_OPTIONS,
  BRIEF_FORM_INPUTS,
} from '../../constants';

const TIP_TEXT = 'Cost will vary depending on fill and ingredients.';

const tipBox = <TipBox tipText={TIP_TEXT} />;

const TITLE = 'What is your target cost of goods sold (COGS)?';
const SUB_TITLE =
  'This is the desired cost of purchasing the raw materials and any cost associated with the production of the product.';

export const TotalCostOfGoods: React.FC = () => {
  const { errors, values } = useFormikContext<BriefFormValues>();
  const { cogsSpecific, cogsRange } = BRIEF_FORM_INPUTS;
  const hasErrors = cogsSpecific in errors || cogsRange in errors;
  return (
    <FormLayout
      isNextButtonDisabled={hasErrors}
      subTitle={SUB_TITLE}
      tip={tipBox}
      title={TITLE}
    >
      <div className="mx-5 sm:ml-0 sm:mr-5 grid grid-cols-2 sm:grid-cols-9">
        <div className="sm:col-start-2 col-span-2 sm:col-span-9 sm:flex">
          <div className="sm:w-1/2">
            <BriefTextField
              label={COST_PER_UNIT_SPECIFIC_LABEL}
              formikKey={BRIEF_FORM_INPUTS.cogsSpecific}
              formikKeyToNullify={BRIEF_FORM_INPUTS.cogsRange}
              prefix={'$'}
            />
          </div>
          <div className="sm:relative sm:top-[3.2rem] font-mono text-xs font-semibold sm:self-start my-5 sm:my-0 sm:mx-5">
            OR
          </div>
          <div className="sm:w-1/2">
            <BriefSelect
              label={COST_PER_UNIT_RANGE_LABEL}
              options={COST_PER_UNIT_RANGES_OPTIONS}
              formikKey={BRIEF_FORM_INPUTS.cogsRange}
              formikKeyToNullify={BRIEF_FORM_INPUTS.cogsSpecific}
              value={values.cogsRange}
            />
          </div>
        </div>
      </div>
    </FormLayout>
  );
};
