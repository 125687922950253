// Libraries
import React from 'react';
// Assets
import science1 from 'features/ready-to-launch/images/icons/science1.svg';
import jars from 'features/ready-to-launch/images/icons/jars.svg';
import products1 from 'features/ready-to-launch/images/icons/products1.svg';
// Utils
import { useMobile } from 'hooks';
// Constants
import { SAMPLE_PRICE } from 'features/ready-to-launch/constants';

const STEPS = [
  {
    stepNumber: '1',
    content:
      'Start by choosing a product base. Customize your formulation to address product goals. Then boost with extracts.',
    imgAltText: 'science',
    imgSrc: science1,
    subText: 'No large order minimums',
    text: 'Customize your product',
  },
  {
    stepNumber: '2',
    content: (
      <>
        Bring your idea to life by ordering a{' '}
        <span className="font-bold">${SAMPLE_PRICE}</span> sample of your
        product to try out. Need to order more samples? No problem.
      </>
    ),
    imgAltText: 'jars',
    imgSrc: jars,
    subText: 'Trusted and tested formulas',
    text: 'Order samples to test',
  },

  {
    stepNumber: '3',
    content:
      'Once your sample is perfected, email us to start the order of full-sized versions of your product. You’ll determine the order quantity to sell to your customers.',
    imgAltText: 'products1',
    imgSrc: products1,
    subText: 'No upfront final order deposit',
    text: 'Bring it to market',
  },
];

const FEATURES = [
  'Order Minimums as Low as 1000',
  'Trusted & Tested Formulas',
  'Sephora Clean',
  'No Parabens, Paraffins, Sulfates or Silicones ',
  'Vegan & Cruelty-free',
];

const SECTION_HEADER = 'How does it work?';
const RENDER_SECTION = {
  header: 'header',
  body: 'body',
};

export const HowDoesItWork: React.FC = () => {
  const { isMobile } = useMobile();

  const renderMobileSection = () => {
    return STEPS.map((step, idx) => {
      return (
        <div
          key={`section-${idx}`}
          className="flex flex-col col-span-3 bg-lightPeach"
        >
          <div className="flex border">
            <div className="bg-lightBlue px-5 pb-7 pt-6 font-mono font-medium text-3xl">
              {step.stepNumber}
            </div>
            <div className="w-full px-5 py-7 font-display text-xl">
              {step.text}
            </div>
          </div>
          <div className="flex flex-col border-l border-r">
            <div className="text-center font-display pt-7 px-8">
              {step.content}
            </div>
            <div className="m-auto mb-14 mt-8">
              <img className="w-48" src={step.imgSrc} alt={step.imgAltText} />
            </div>
          </div>
        </div>
      );
    });
  };

  const renderSection = (section: string) => {
    return (
      <>
        <div className="col-span-1 border-b border-r" />
        {STEPS.map((step, idx) => {
          return (
            <div
              key={`section-${idx}`}
              className="flex col-span-3 bg-lightPeach border-b border-r"
            >
              {section === RENDER_SECTION.header && (
                <>
                  <div className="bg-lightBlue px-5 pb-7 pt-6 font-mono font-medium text-[1.875]">
                    {step.stepNumber}
                  </div>
                  <div className="w-full px-5 py-7 font-display text-lg">
                    {step.text}
                  </div>
                </>
              )}
              {section === RENDER_SECTION.body && (
                <div className="flex justify-between flex-col">
                  <div className="text-center font-display pt-7 px-8">
                    {step.content}
                  </div>
                  <div className="mx-auto pb-14 mt-8">
                    <img
                      className="w-48"
                      src={step.imgSrc}
                      alt={step.imgAltText}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div className="col-span-1 border-b" />
      </>
    );
  };

  const renderFeaturesSection = () => {
    return FEATURES.map((feature, idx) => {
      return (
        <div
          key={`step-subtext-${idx}`}
          className="flex font-display px-8 pb-4"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 10L7.75 15.5L18 4"
              stroke="#4981c0"
              strokeWidth="2"
              strokeLinecap="square"
            />
          </svg>
          <div className="pl-3 text-center">{feature}</div>
        </div>
      );
    });
  };

  const renderHowDoesItWork = () => {
    if (isMobile) {
      return <div className="border-b mb-20">{renderMobileSection()}</div>;
    } else {
      return (
        <>
          {renderSection(RENDER_SECTION.header)}
          {renderSection(RENDER_SECTION.body)}
        </>
      );
    }
  };

  return (
    <>
      <div className="bg-lightPeach">
        <div className="text-center font-display text-[1.875rem] pb-8 pt-20">
          {SECTION_HEADER}
        </div>
        <div className="flex flex-col px-5 sm:px-0 sm:grid grid-cols-11 sm:border-t">
          {renderHowDoesItWork()}
        </div>
      </div>
      <div className="grid grid-cols-3 bg-darkPeach">
        <div className="flex flex-wrap justify-center col-span-3 sm:mx-36 mt-14 mb-10">
          {renderFeaturesSection()}
        </div>
      </div>
    </>
  );
};
