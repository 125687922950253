// Libraries
import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/16/solid';
// Components
import { Button, Typography } from 'design-system';
// Assetes
import ChemistryEquipment from 'img/chemistry-equipment.svg';

export const BriefSubmissionConfirmation = () => {
  const project_id = '12345'; // TODO: Replace with actual project id

  return (
    <div className="w-full flex flex-col mt-[60px]">
      <div>
        <div className="relative flex mb-[100px] ml-[140px]">
          <div className="flex flex-col w-[70%] gap-6 bg-white">
            <Typography variant="h2">
              {/* TODO: Connect product type result to actual formik value  */}
              Congrats, your brief is submitted!
            </Typography>
            <Typography variant="h4" font="inter">
              Someone from Prime Matter Labs will be in touch soon to get your
              project started.
            </Typography>
            <Button
              text="Go to my Project Dashboard"
              trailingIcon={<ArrowRightIcon />}
              type="primary"
              action={() =>
                (window.location.href = `${window.location.origin}/your-projects/${project_id}`)
              }
            />
          </div>
        </div>
      </div>
      <div className="relative flex flex-col bg-blue-95 px-[140px] pb-[250px] min-h-[800px] h-full">
        <div className="absolute right-0 -top-[50px]">
          <img src={ChemistryEquipment} alt="chemistry equipment" />
        </div>
      </div>
    </div>
  );
};
