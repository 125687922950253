// Libraries
import React from 'react';
// Components
import { InterstitialLayout } from '../layout';
// Assets
import interstitialImageTwo from '../../assets/interstitialImageTwo.png';
import interstitialImageTwoMobile from '../../assets/interstitialImageTwoMobile.png';
// Constants
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const STEPS = [
  {
    text: 'Project basics',
    isComplete: true,
  },
  {
    text: 'Market strategy',
    isComplete: false,
  },
  {
    text: 'Project requirements',
    isComplete: false,
  },
];

const IMAGE = {
  src: interstitialImageTwo,
  alt: 'cream',
};

const MOBILE_IMAGE = {
  src: interstitialImageTwoMobile,
  alt: 'cream',
};

const TITLE = "You're ready to start building out your project";

export const MarketStrategyInterstitial: React.FC = () => {
  return (
    <div>
      <InterstitialLayout
        eventCategory={
          AnalyticConstants.EVENT_CATEGORIES.CBDividerMarketStrategy
        }
        image={IMAGE}
        mobileImage={MOBILE_IMAGE}
        steps={STEPS}
        title={TITLE}
      />
    </div>
  );
};
