// Libraries
import React, { useState } from 'react';
import { Formik } from 'formik';
// Components
import { FormSelectList } from 'features/ui';
import * as ReadyToLaunch from 'features/ready-to-launch/ui';
import { QuizResultsPage } from './quiz-results-page.component';
// Utils
import { useMobile } from 'hooks';
import {
  FormikQuizValues,
  ProductCategoryQuizQuestion,
  ProductTypeQuizQuestion,
  QuizResult,
} from './types';
// Assets
import beakerImg from './images/beaker.jpg';
import mobileBeakerImg from './images/mobile_beaker.png';
// Constants
import { QUIZ, QUIZ_RESULTS_LOGIC, QUIZ_RESULTS_PAGE } from './constants';

const {
  HEADER,
  SUB_HEADER,
  INITIAL_FORM_VALUES,
  FORM_VALUES,
  VALIDATION_SCHEMA,
} = QUIZ;

const PRODUCT_CATEGORY = 'productCategory';
const PRODUCT_TYPE = 'productType';
const OTHER = 'Other';

const isProductCategorySelected = (formikProps: any) => {
  return formikProps.values.productCategory.length > 0;
};

const renderProductCategoryQuestion = () => {
  const { title, subtitle } = FORM_VALUES.productCategory.label;
  return (
    <div className="mb-16">
      <div className="flex flex-col mb-8">
        <span className="text-blue-60 font-display text-[1.5rem]">{title}</span>
        <span className="text-gray font-display text-lg">{subtitle}</span>
      </div>
      <FormSelectList
        inputName={PRODUCT_CATEGORY}
        items={FORM_VALUES.productCategory.items}
        type="radio"
      />
    </div>
  );
};

const renderAdditionalQuizQuestions = (formikProps: FormikQuizValues) => {
  const { productCategory } = formikProps;

  return Object.keys(FORM_VALUES).map((key) => {
    const isProductType = key === PRODUCT_TYPE;
    let listItems;
    let label;

    if (
      key === PRODUCT_CATEGORY ||
      (isProductType && productCategory === OTHER)
    )
      return null;
    // Product type is dynamic, based on product category and has an additional layer of nesting
    else if (isProductType) {
      listItems =
        FORM_VALUES.productType[
          formikProps.productCategory as ProductTypeQuizQuestion
        ].items;
      label = {
        title: (
          <>
            <span>Specify which type of </span>
            <span className="text-black">"{productCategory}" </span>
            <span>products</span>
          </>
        ),
        subtitle: 'What kind of product are you building?',
      };
    } else {
      listItems = FORM_VALUES[key as ProductCategoryQuizQuestion].items;
      label = FORM_VALUES[key as ProductCategoryQuizQuestion].label;
    }

    return (
      <div className="mb-16">
        <div className="flex flex-col mb-8">
          <span className="text-blue-60 font-display text-[1.5rem]">
            {label.title}
          </span>
          <span className="text-gray font-display text-lg">
            {label.subtitle}
          </span>
        </div>
        <FormSelectList
          inputName={key}
          items={listItems}
          type={isProductType ? 'checkbox' : 'radio'}
        />
      </div>
    );
  });
};

export const Quiz: React.FC = () => {
  const [quizResults, setQuizResults] = useState<Maybe<QuizResult>>(undefined);
  const { isMobile } = useMobile();
  const determineQuizResults = (formValues: FormikQuizValues) => {
    const { productCategory, productType, rdInvestment } = formValues;
    const {
      availableRtlProductTypes,
      rdInvestmentShortCircuit,
      unavailableRtlProductCategories,
    } = QUIZ_RESULTS_LOGIC;
    if (rdInvestment === rdInvestmentShortCircuit)
      setQuizResults(QUIZ_RESULTS_PAGE.BUILD_YOUR_PROJECT);
    else if (
      unavailableRtlProductCategories.includes(productCategory) ||
      !availableRtlProductTypes.some((type: string) =>
        productType.includes(type)
      )
    )
      setQuizResults(QUIZ_RESULTS_PAGE.RTL_WAITLIST);
    else {
      setQuizResults(QUIZ_RESULTS_PAGE.READY_TO_LAUNCH);
    }
  };

  return (
    <Formik
      initialValues={INITIAL_FORM_VALUES}
      validateOnMount={true}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(formValues) => determineQuizResults(formValues)}
    >
      {(props) => (
        <>
          {!quizResults ? (
            <ReadyToLaunch.Page>
              <ReadyToLaunch.PageHeader
                header={HEADER}
                subheader={SUB_HEADER}
              />
              {isMobile && (
                <img className="ml-5" src={mobileBeakerImg} alt="beaker" />
              )}
              <div className="flex justify-between mx-5 mt-2 sm:ml-24 ">
                <div className="flex flex-col w-full sm:mr-10">
                  <form onSubmit={props.handleSubmit}>
                    {renderProductCategoryQuestion()}
                    {/* Render additional questions only after the first question is selected*/}
                    {isProductCategorySelected(props) &&
                      renderAdditionalQuizQuestions(props.values)}
                    <button
                      disabled={!props.isValid}
                      type="submit"
                      className={`w-48 mb-18 px-10 py-4 ${
                        !props.isValid ? 'bg-lightGray' : 'bg-blue-60'
                      } text-xs text-white font-mono font-semibold`}
                    >
                      SUBMIT ANSWERS
                    </button>
                  </form>
                </div>
                {!isMobile && (
                  <img
                    className="max-h-1000 min-h-full sticky top-0"
                    src={beakerImg}
                    alt="beaker"
                  />
                )}
              </div>
            </ReadyToLaunch.Page>
          ) : (
            <QuizResultsPage isMobile={isMobile} result={quizResults} />
          )}
        </>
      )}
    </Formik>
  );
};
