// Libraries
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
// Components
import { BriefTextField, FormLayout } from '../layout';
import { TypeCard } from './type-card.component';
import { WizardContext } from '../wizard/wizard.context';
// Utilities
import { BriefFormValues } from '../../types';
import { useAnalytics, useMobile } from 'hooks';
// Constants
import { PRODUCT_CATEGORIES_AND_TYPES } from '../product-category';
import { BRIEF_FORM_INPUTS } from '../../constants';
import * as AnalyticConstants from 'features/analytics/analytics.constants';

const LABEL = 'Looking for something else? Please describe here.';
const SUB_TITLE = 'Select the product type within your category.';
const TITLE = 'Choose the product type';

export const ProductType: React.FC = () => {
  const { errors, setFieldValue, values } = useFormikContext<BriefFormValues>();
  const { pageNumber, incrementPage } = useContext(WizardContext);
  const { isMobile } = useMobile();
  const analytics = useAnalytics()!;
  const selectedProduct = PRODUCT_CATEGORIES_AND_TYPES.available.find(
    (product) => values.productCategory === product.name
  );

  const setTypeAndContinue = (format: string) => {
    setFieldValue(BRIEF_FORM_INPUTS.productType, format);
    setFieldValue(BRIEF_FORM_INPUTS.productTypeNote, '');

    analytics.trackEvent({
      eventCategory: AnalyticConstants.EVENT_CATEGORIES.CBQuestion,
      eventAction: AnalyticConstants.EVENT_ACTIONS.click,
      eventLabel: AnalyticConstants.EVENT_LABEL.cbNext(
        `${String(pageNumber).padStart(2, '0')}. ${TITLE}`
      ),
    });

    incrementPage();
  };

  const renderTypeCards = () => {
    return selectedProduct?.formats.map((format, idx) => {
      if (idx % 3 === 0) {
        return (
          <div
            key={`row-${idx}`}
            className="col-start-2 col-span-10 grid grid-cols-12 gap-x-5"
          >
            <TypeCard
              name={format.name}
              image={format.image}
              handleClick={() => setTypeAndContinue(format.name)}
            />
            {selectedProduct.formats[idx + 1] ? (
              <TypeCard
                name={selectedProduct.formats[idx + 1].name}
                image={selectedProduct.formats[idx + 1].image}
                handleClick={() =>
                  setTypeAndContinue(selectedProduct.formats[idx + 1].name)
                }
              />
            ) : null}
            {selectedProduct.formats[idx + 2] ? (
              <TypeCard
                name={selectedProduct.formats[idx + 2].name}
                image={selectedProduct.formats[idx + 2].image}
                handleClick={() =>
                  setTypeAndContinue(selectedProduct.formats[idx + 2].name)
                }
              />
            ) : null}
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderMobileTypeCards = () => {
    return selectedProduct?.formats.map((format) => {
      return (
        <div className="col-span-2">
          <TypeCard
            name={format.name}
            image={format.image}
            handleClick={() => setTypeAndContinue(format.name)}
          />
        </div>
      );
    });
  };

  return (
    <FormLayout
      isNextButtonDisabled={BRIEF_FORM_INPUTS.productType in errors}
      title={TITLE}
      subTitle={SUB_TITLE}
    >
      <div className="mx-5 sm:mx-0 grid grid-cols-2 sm:grid-cols-12 gap-x-6 sm:gap-y-6 mb-10">
        {isMobile ? renderMobileTypeCards() : renderTypeCards()}
      </div>
      <div className="mx-5 sm:mx-0 grid sm:grid-cols-12 gap-x-6 sm:gap-y-6">
        <div className="sm:col-start-2 sm:col-span-6 sm:pb-0 pb-20">
          <BriefTextField
            id="additonalFormat"
            formikKey={BRIEF_FORM_INPUTS.productTypeNote}
            label={LABEL}
          />
        </div>
      </div>
    </FormLayout>
  );
};
