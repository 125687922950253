// Libaries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { RadioButton, Typography } from 'design-system';
import { ScrollIntoView } from './scroll-into-view.component';
// Utils
import { CustomerBriefFormValues } from '../internal-brief/form.config';
import { useRtlIconMap } from '../rtl-logic';
import { useConfirmation } from 'hooks';
// Constants
import {
  BRIEF_FORM_KEYS,
  RESTART_CONFIRM_STATE,
  RTL_CONFIRM_STATE,
  SPF_VALUES,
} from '../brief.constants';
// Assets
import { ReactComponent as ReadyToLaunchIcon } from '../../../img/ready-to-launch.svg';

const SPF_OPTIONS = [
  { label: 'SPF 15', value: SPF_VALUES.SPF_15 },
  { label: 'SPF 30', value: SPF_VALUES.SPF_30 },
  { label: 'SPF 45', value: SPF_VALUES.SPF_45 },
  { label: 'SPF 50', value: SPF_VALUES.SPF_50 },
  { label: 'SPF 60', value: SPF_VALUES.SPF_60 },
  { label: 'SPF 85', value: SPF_VALUES.SPF_85 },
];

interface SpfProps {
  projectIsRtl?: boolean;
}

export const Spf: React.FC<SpfProps> = ({ projectIsRtl }) => {
  const { getFieldProps, setFieldValue, values } = useFormikContext<
    CustomerBriefFormValues
  >();
  const rtlIconMap = useRtlIconMap({
    values,
    moduleKey: BRIEF_FORM_KEYS.SPF,
  });
  const { confirm } = useConfirmation();

  const handleNonRtlClick = async (option: Option) => {
    const shouldProceed = await confirm(RTL_CONFIRM_STATE);

    if (shouldProceed) {
      setFieldValue(BRIEF_FORM_KEYS.SPF, option.value);
    } else {
      return;
    }
  };

  const renderOptions = () => {
    return SPF_OPTIONS.map((option) => {
      const isRtlOption = rtlIconMap[BRIEF_FORM_KEYS.SPF]
        ? rtlIconMap[BRIEF_FORM_KEYS.SPF][option.value]
        : false;
      return (
        <div className="flex flex-row items-center" key={option.value}>
          <RadioButton
            data-testid={`spf-${option.value}`}
            key={option.value}
            primaryLabel={option.label}
            {...getFieldProps(BRIEF_FORM_KEYS.SPF)}
            onChange={
              isRtlOption || !projectIsRtl
                ? async () => {
                    // If we already selected spf and the tint field is filled out
                    if (values.spf && values.tint) {
                      const shouldProceed = await confirm(
                        RESTART_CONFIRM_STATE
                      );

                      if (!shouldProceed) return;
                    }

                    setFieldValue(BRIEF_FORM_KEYS.SPF, option.value);
                  }
                : () => handleNonRtlClick(option)
            }
            checked={values[BRIEF_FORM_KEYS.SPF] === option.value}
            leadingIcon={
              isRtlOption ? (
                <ReadyToLaunchIcon className="bg-peach-50" />
              ) : undefined
            }
          />
        </div>
      );
    });
  };

  return (
    <ScrollIntoView>
      {({ ref }) => (
        <div ref={ref} className="col-span-9 flex flex-col gap-3">
          <Typography
            variant="h3"
            font="inter"
            size="lg"
            weight="normal"
            color="primary"
          >
            SPF
          </Typography>
          <Typography
            font="inter"
            variant="p"
            weight="normal"
            color="secondary"
          >
            What is the SPF of your product?
          </Typography>
          {renderOptions()}
        </div>
      )}
    </ScrollIntoView>
  );
};
