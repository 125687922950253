// Libraries
import React, { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
// Utils
import {
  SKIN_CARE_MODULE_LIST,
  SUN_CARE_MODULE_LIST,
  SUN_CARE_FORMULAS,
  SKIN_CARE_FORMULAS,
  HAIR_CARE_FORMULAS,
  HAIR_CARE_MODULE_LIST,
  BODY_CARE_FORMULAS,
  BODY_CARE_MODULE_LIST,
} from '.';
import { CustomerBriefFormValues } from '../internal-brief/form.config';
// Constants
import { BRIEF_FORM_KEYS, PRODUCT_CATEGORIES } from '../brief.constants';

interface RtlLogicEngineProps {
  rtlFormulas: any[];
  setAllRtlModulesHaveValues: React.Dispatch<React.SetStateAction<boolean>>;
  setFilteredRtlFormulas: React.Dispatch<React.SetStateAction<any[]>>;
  setRtlFormulas: React.Dispatch<React.SetStateAction<any[]>>;
  setRtlFormulaUuid: React.Dispatch<React.SetStateAction<string | null>>;
}

export const getRtlKeysForProductCategory = (productCategory: string) => {
  switch (productCategory) {
    case PRODUCT_CATEGORIES.SUN_CARE:
      return SUN_CARE_MODULE_LIST;
    case PRODUCT_CATEGORIES.SKIN_CARE:
      return SKIN_CARE_MODULE_LIST;
    case PRODUCT_CATEGORIES.BODY_CARE:
      return BODY_CARE_MODULE_LIST;
    case PRODUCT_CATEGORIES.HAIR_CARE:
      return HAIR_CARE_MODULE_LIST;
    default:
      return [];
  }
};

const filterFormulasBySubsetOfProperties = (subset: any, formulas: any) => {
  return formulas.filter(({ productProperties }: any) =>
    _.isMatch(productProperties, subset)
  );
};

const removeEmptyValues = (obj: { [x: string]: any }) => {
  // Iterate through each property in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the property is an object
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        // Recursively remove empty values from nested objects
        removeEmptyValues(obj[key]);

        // Remove the property if it's an empty object
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      // Check if the property is a string and is empty
      else if (obj[key] === '' || obj[key] === null) {
        delete obj[key];
      }
    }
  }
};

export const determineRtlFormulasAndModulesFromProductCategory = (
  values: any
) => {
  switch (values.productCategory.value) {
    case PRODUCT_CATEGORIES.SUN_CARE:
      return {
        rtlFormulas: SUN_CARE_FORMULAS,
        rtlModules: SUN_CARE_MODULE_LIST,
      };
    case PRODUCT_CATEGORIES.SKIN_CARE:
      return {
        rtlFormulas: SKIN_CARE_FORMULAS,
        rtlModules: SKIN_CARE_MODULE_LIST,
      };
    case PRODUCT_CATEGORIES.HAIR_CARE:
      return {
        rtlFormulas: HAIR_CARE_FORMULAS,
        rtlModules: HAIR_CARE_MODULE_LIST,
      };
    case PRODUCT_CATEGORIES.BODY_CARE:
      return {
        rtlFormulas: BODY_CARE_FORMULAS,
        rtlModules: BODY_CARE_MODULE_LIST,
      };
    default:
      return { rtlFormulas: [], rtlModules: [] };
  }
};

export const RtlLogicEngine: React.FC<RtlLogicEngineProps> = ({
  rtlFormulas,
  setAllRtlModulesHaveValues,
  setFilteredRtlFormulas,
  setRtlFormulas,
  setRtlFormulaUuid,
}) => {
  const { values, initialValues, setFieldValue } = useFormikContext<
    CustomerBriefFormValues
  >();
  const prevValues = useRef<CustomerBriefFormValues>(initialValues);

  useEffect(() => {
    // Handler for if the the product category has been changed
    if (
      prevValues.current.productCategory.value !== values.productCategory.value
    ) {
      setRtlFormulas(
        determineRtlFormulasAndModulesFromProductCategory(values).rtlFormulas
      );

      // The product category has changed, so we should reset all related formik values to their initial state
      getRtlKeysForProductCategory(
        prevValues.current.productCategory.value
      ).forEach((key) => {
        setFieldValue(key, initialValues[key as keyof CustomerBriefFormValues]);
      });

      // Set prevValues to the current values
      prevValues.current = values;

      return;
    }

    // Get the keys for the current product category
    const rtlKeys = getRtlKeysForProductCategory(values.productCategory.value);

    // Get a subset of the previous formik values that only includes the keys for the current product category
    const prevValuesSubset = _.pick(prevValues.current, rtlKeys);
    // Get a subset of the current formik values that only includes the keys for the current product category
    const valuesSubset = _.pick(values, rtlKeys);
    // Get the delta between the two sets of values
    const delta = _.omitBy(valuesSubset, (value, key) =>
      _.isEqual(value, prevValuesSubset[key as typeof rtlKeys[number]])
    );

    // Guard clause: If there are no changes, return early
    if (Object.keys(delta).length === 0) return;

    // Find the index position of the changed key/value in the corresponding key in the module list
    const index = rtlKeys.indexOf(
      Object.keys(delta)[0] as typeof rtlKeys[number]
    );
    // For every key in the module list after that index position, set the corresponeing formik value to its initial state
    rtlKeys.slice(index + 1).forEach((key) => {
      setFieldValue(key, initialValues[key as keyof CustomerBriefFormValues]);
    });

    // Independently reset the countries key. It is not currently part of any module lists
    setFieldValue(BRIEF_FORM_KEYS.COUNTRIES, initialValues.countries);

    // Set prevValues to the current values
    prevValues.current = values;
  }, [values, initialValues, setFieldValue, setRtlFormulas]);

  useEffect(() => {
    // Get the keys for the current product category
    const rtlKeys = getRtlKeysForProductCategory(values.productCategory.value);

    // Get a subset of the formik values that only includes the keys for the current product category
    const formikRtlValuesSubset = _.pick(values, rtlKeys);

    // Remove empty values from the subset of formik values
    removeEmptyValues(formikRtlValuesSubset);

    // Set the state of allRtlModulesHaveValues to true if all the keys in the subset of formik values have values
    setAllRtlModulesHaveValues(
      Object.values(formikRtlValuesSubset).length !== 0 &&
        Object.values(formikRtlValuesSubset).length === rtlKeys.length
    );

    // Format the product type value
    // Many values are of type Option, which is an object with a label and value property. We only need the value property.
    const formattedRtlObject = {
      ..._.omit(formikRtlValuesSubset, BRIEF_FORM_KEYS.PRODUCT_TYPE),
      productType: formikRtlValuesSubset.productType?.value,
    };

    // Filters any formulas that do not match the formattedRtlObject
    const filteredRtlFormulas = filterFormulasBySubsetOfProperties(
      formattedRtlObject,
      rtlFormulas
    );

    // Console logging for testing purposes. Remove for production
    if (filteredRtlFormulas.length === 1) {
      setRtlFormulaUuid(filteredRtlFormulas[0].formulaUuid);
      console.log(filteredRtlFormulas[0].formulaUuid);
    }

    // If we haven't selected a productType yet, use the rtlFormulas from the productCategory. This is done
    // so that we can show the user the correct icons for the productType options
    setFilteredRtlFormulas(
      formattedRtlObject.productType ? filteredRtlFormulas : rtlFormulas
    );
  }, [
    values,
    initialValues,
    rtlFormulas,
    setAllRtlModulesHaveValues,
    setRtlFormulas,
    setRtlFormulaUuid,
    setFilteredRtlFormulas,
  ]);

  return null;
};
