// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { BriefTextField } from '../layout';
import { FormLayout } from '../layout';
import { TipBox } from '../layout';
// Utils
import { BriefFormValues } from '../../types';
// Constants
import { BRIEF_FORM_INPUTS } from '../../constants';

const ORDER_QUANTITY_LABEL = 'ORDER QUANTITY *';
const DELIVERY_DATE_LABEL = 'DELIVERY DATE *';
const TIP_TEXT =
  'Our minimum order quantities (MOQs) are based on a combination of product specifications including unit size and product form.';

const tipBox = <TipBox tipText={TIP_TEXT} />;

const DATE_HELPER_TEXT = 'mm/dd/yyyy';
const TITLE = 'What’s your estimated order quantity?';
const SUB_TITLE =
  'The quantity is how many units of the product you expect to produce in the next 12 months.';

export const EstimatedOrderQuantity: React.FC = () => {
  const { errors } = useFormikContext<BriefFormValues>();

  const { minimumOrderQuantity, targetDate } = BRIEF_FORM_INPUTS;
  const hasErrors = minimumOrderQuantity in errors || targetDate in errors;

  return (
    <FormLayout
      isNextButtonDisabled={hasErrors}
      subTitle={SUB_TITLE}
      title={TITLE}
      tip={tipBox}
    >
      <div className="mx-5 sm:ml-0 sm:mr-5 grid grid-cols-2 sm:grid-cols-9 gap-x-6 sm:gap-y-6">
        <div className="sm:col-start-2 col-span-2 sm:col-span-4 mb-6 sm:mb-0">
          <BriefTextField
            label={ORDER_QUANTITY_LABEL}
            formikKey={BRIEF_FORM_INPUTS.minimumOrderQuantity}
          />
        </div>
        <div className="col-span-2 sm:col-span-4">
          <BriefTextField
            helperText={DATE_HELPER_TEXT}
            label={DELIVERY_DATE_LABEL}
            formikKey={BRIEF_FORM_INPUTS.targetDate}
          />
        </div>
      </div>
    </FormLayout>
  );
};
